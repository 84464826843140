import React from "react";
import PropTypes from "prop-types";

Logo.propTypes = {
    color: PropTypes.string.isRequired,
};

function Logo() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            zoomAndPan="magnify"
            viewBox="0 0 75 74.999997"
            preserveAspectRatio="xMidYMid meet"
            version="1.0"
        >
            <defs>
                <clipPath id="b708aa5c01">
                    <path
                        d="M 33.175781 18.949219 L 71.425781 18.949219 L 71.425781 57.199219 L 33.175781 57.199219 Z M 33.175781 18.949219 "
                        clipRule="nonzero"
                    />
                </clipPath>
                <clipPath id="48166457ca">
                    <path
                        d="M 3.296875 19.054688 L 34.046875 19.054688 L 34.046875 58.054688 L 3.296875 58.054688 Z M 3.296875 19.054688 "
                        clipRule="nonzero"
                    />
                </clipPath>
            </defs>
            <path
                fill="#ffffff"
                d="M 52.300781 36.199219 C 51.269531 36.199219 50.433594 37.039062 50.433594 38.070312 C 50.433594 39.101562 51.269531 39.9375 52.300781 39.9375 C 53.332031 39.9375 54.164062 39.101562 54.164062 38.070312 C 54.164062 37.039062 53.332031 36.199219 52.300781 36.199219 "
                fillOpacity="1"
                fillRule="nonzero"
            />
            <g clipPath="url(#b708aa5c01)">
                <path
                    fill="#ffffff"
                    d="M 36.597656 38.070312 C 36.597656 29.402344 43.644531 22.347656 52.300781 22.347656 C 60.957031 22.347656 68 29.402344 68 38.070312 C 68 46.738281 60.957031 53.789062 52.300781 53.789062 C 43.644531 53.789062 36.597656 46.738281 36.597656 38.070312 Z M 71.398438 38.070312 C 71.398438 27.523438 62.832031 18.945312 52.300781 18.945312 C 41.769531 18.945312 33.203125 27.523438 33.203125 38.070312 C 33.203125 48.613281 41.769531 57.191406 52.300781 57.191406 C 56.722656 57.191406 60.792969 55.679688 64.035156 53.144531 L 67.113281 57.191406 L 71.398438 57.191406 L 66.53125 50.808594 C 69.554688 47.421875 71.398438 42.957031 71.398438 38.070312 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
            </g>
            <path
                fill="#ffffff"
                d="M 20.648438 38.550781 C 20.648438 39.625 19.765625 40.5 18.675781 40.5 C 17.582031 40.5 16.699219 39.625 16.699219 38.550781 C 16.699219 37.472656 17.582031 36.597656 18.675781 36.597656 C 19.765625 36.597656 20.648438 37.472656 20.648438 38.550781 "
                fillOpacity="1"
                fillRule="nonzero"
            />
            <g clipPath="url(#48166457ca)">
                <path
                    fill="#ffffff"
                    d="M 16.054688 19.046875 L 3.332031 58.050781 L 7.109375 58.050781 L 18.675781 22.609375 L 30.238281 58.050781 L 34.015625 58.050781 L 21.292969 19.046875 L 16.054688 19.046875 "
                    fillOpacity="1"
                    fillRule="nonzero"
                />
            </g>
        </svg>
    );
}

export default Logo;
