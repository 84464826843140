import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { PAGE_SIZE } from "constants/pagination";
import { ROUTES } from "constants/routes";

import * as adminsActions from "store/admins/actions";

import HistoryService from "services/history/HistoryService";

import ListTable from "componentsShared/ListTable/ListTable";
import { getSurname } from "сomponents/ListTablePatientsDoctorCabinet/ListTablePatientsDoctorCabinet";

function ListTableAdmins() {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const data = useSelector((state) => state?.admins?.adminsList?.data) ?? {};
    const dataSequence = useSelector((state) => state?.admins?.adminsList?.dataSequence) ?? [];
    const totalElements =
        useSelector((state) => state?.admins?.adminsList?.meta?.totalElements) ?? 0;

    const [adminsList, setAdminsList] = useState([]);

    useEffect(() => {
        dispatch(adminsActions.getAdminsList.start({ pageSize: PAGE_SIZE, pageNumber: 0 }));
    }, []);

    useEffect(() => {
        const clinicsListProcessed = dataSequence.map((key) => {
            const item = data[key];
            return {
                email: item.email || "",
                fullName: `${item.firstName} ${item.lastName}`,
                clinicName: item?.clinic?.name || "",
                clinicId: item?.clinic?.id || null,
            };
        });

        setAdminsList(clinicsListProcessed);
    }, [data, dataSequence]);

    const columns = [
        {
            label: t("Admins:ListTableAdmins.table.column.label.name"),
            prop: "fullName",
            sortable: true,
            sortMethod: (a, b) =>
                getSurname(a.fullName).localeCompare(getSurname(b.fullName)) > 0,
        },
        {
            label: t("Admins:ListTableAdmins.table.column.label.email"),
            prop: "email",
            sortable: true,
            sortMethod: (a, b) => a.email.localeCompare(b.email) > 0,
        },
        {
            label: t("Admins:ListTableAdmins.table.column.label.clinicName"),
            prop: "clinicName",
            sortable: true,
            sortMethod: (a, b) => a.clinicName.localeCompare(b.clinicName) > 0,
        },
    ];

    function onTableRowClick(rowData) {
        HistoryService.push(`/${ROUTES.SUPERADMIN_ADMIN_PROFILE.path}/${rowData.email}`);
    }

    const onPaginationChange = (page) => {
        dispatch(
            adminsActions.getAdminsList.start({ pageSize: PAGE_SIZE, pageNumber: page - 1 })
        );
    };

    return (
        <ListTable
            columns={columns}
            onTableRowClick={onTableRowClick}
            tableData={adminsList}
            withPagination={true}
            totalElements={totalElements}
            onPaginationChange={onPaginationChange}
            isWithoutBorders
        />
    );
}

export default ListTableAdmins;
