import { handleActions } from 'redux-actions';

import * as actions from './actions';

export const initialState = {
  isLoadingCreateDoctor: false,
  isLoadingGetDoctor: false,
  isLoadingGetDoctorsList: false,
  data: {}, // TODO: move to separate wrapper object
  dataSequence: [], // TODO: move to separate wrapper object
  selectedDoctorProfile: {},
};

export const doctorsReducer = handleActions(
  {
    [actions.createDoctor.start]: (state) => ({
      ...state,
      isLoadingCreateDoctor: true,
    }),
    [actions.createDoctor.success]: (state) => ({
      ...state,
      isLoadingCreateDoctor: false,
    }),
    [actions.createDoctor.failure]: (state) => ({
      ...state,
      isLoadingCreateDoctor: false,
    }),

    [actions.getDoctor.start]: (state) => ({
      ...state,
      isLoadingGetDoctor: true,
    }),
    [actions.getDoctor.success]: (state, { payload }) => ({
      ...state,
      isLoadingGetDoctor: false,
      selectedDoctorProfile: {
        ...state.selectedDoctorProfile,
        email: payload.email,
        firstName: payload.firstName,
        lastName: payload.lastName,
        status: payload.status,
        demographics: payload.demographics,
      }
    }),
    [actions.getDoctor.failure]: (state) => ({
      ...state,
      isLoadingGetDoctor: false,
    }),

    [actions.getDoctorsList.start]: (state) => ({
      ...state,
      isLoadingGetDoctorsList: true,
    }),
    [actions.getDoctorsList.success]: (state, { payload }) => ({
      ...state,
      isLoadingGetDoctorsList: false,
      data: payload.data,
      dataSequence: payload.dataSequence,
      pageable: payload.pageable,
      sort: payload.sort,
      meta: payload.meta,
    }),
    [actions.getDoctorsList.failure]: (state) => ({
      ...state,
      isLoadingGetDoctorsList: false,
    }),
  },
  initialState
);
