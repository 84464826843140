import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as authActions from 'store/auth/actions';

import SignIn from 'сomponents/SignIn/SignIn';

import styles from './RoleSelectCard.module.scss';

RoleSelectCard.propTypes = {
  title: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
};

function RoleSelectCard({
  title,
  userRole,
}) {
  const dispatch = useDispatch();

  const storedUserRole = useSelector(state => state.auth.accountType);

  const onCardFocus = () => {
    if (userRole !== storedUserRole) {
      dispatch(authActions.selectUserRole({userRole: userRole}));
    }
  };

  return (
    <div className={styles.rootWrapper} onFocus={onCardFocus}>
      <div className={styles.title}>
        {title}
      </div>

      <div className={styles.formHolder}>
        <SignIn/>
      </div>
    </div>
  );
}

export default RoleSelectCard;
