import React from "react";
import PropTypes from "prop-types";

import SidebarNav from "сomponents/SidebarNav/SidebarNav";
import SimpleLogo from "сomponents/SimpleLogo/SimpleLogo";

import styles from "./SidebarLeftLayout.module.scss";

SidebarLeftLayout.propTypes = {
    children: PropTypes.element.isRequired,
};

function SidebarLeftLayout({ children }) {
    return (
        <div className={styles.rootWrapper}>
            <div span={4} className={styles.sidebarHolder}>
                <div className={styles.logoHolder}>
                    <SimpleLogo color="white" />
                </div>
                <div className={styles.sidebarContentHolder}>
                    <SidebarNav />
                </div>
            </div>
            <div span={20} className={styles.pageHolder}>
                {children}
            </div>
        </div>
    );
}

export default SidebarLeftLayout;
