import { handleActions } from 'redux-actions';

import * as actions from './actions';

export const initialState = {
  isLoadingCreateAdmin: false,
  isLoadingGetAdmin: false,
  isLoadingGetAdminsList: false,
  adminsList: {
    data: {},
    dataSequence: [],
  },
  selectedAdminProfile: {},
};

export const adminsReducer = handleActions(
  {
    [actions.createAdmin.start]: (state) => ({
      ...state,
      isLoadingCreateAdmin: true,
    }),
    [actions.createAdmin.success]: (state) => ({
      ...state,
      isLoadingCreateAdmin: false,
    }),
    [actions.createAdmin.failure]: (state) => ({
      ...state,
      isLoadingCreateAdmin: false,
    }),

    [actions.getAdmin.start]: (state) => ({
      ...state,
      isLoadingGetAdmin: true,
    }),
    [actions.getAdmin.success]: (state, { payload }) => ({
      ...state,
      isLoadingGetAdmin: false,
      selectedAdminProfile: {
        ...state.selectedAdminProfile,
        email: payload.email,
        firstName: payload.firstName,
        lastName: payload.lastName,
        clinic: payload.clinic,
      }
    }),
    [actions.getAdmin.failure]: (state) => ({
      ...state,
      isLoadingGetAdmin: false,
    }),

    [actions.getAdminsList.start]: (state) => ({
      ...state,
      isLoadingGetAdminsList: true,
    }),
    [actions.getAdminsList.success]: (state, { payload }) => ({
      ...state,
      isLoadingGetAdminsList: false,
      adminsList: {
        ...state.adminsList,
        data: payload.data,
        dataSequence: payload.dataSequence,
        pageable: payload.pageable,
        sort: payload.sort,
        meta: payload.meta,
      },
    }),
    [actions.getAdminsList.failure]: (state) => ({
      ...state,
      isLoadingGetAdminsList: false,
    }),
  },
  initialState
);
