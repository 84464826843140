import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LocalStorageService from 'services/localStorage/LocalStorageService';

import ClinicsEN from 'locales/ClinicsEN.json';
import ClinicsES from 'locales/ClinicsES.json';
import AuthEN from 'locales/AuthEN.json';
import AuthES from 'locales/AuthES.json';
import ResourcesEN from 'locales/ResourcesEN.json';
import ResourcesES from 'locales/ResourcesES.json';
import PatientsEN from 'locales/PatientsEN.json';
import PatientsES from 'locales/PatientsES.json';
import AccountEN from 'locales/AccountEN.json';
import AccountES from 'locales/AccountES.json';
import uiEN from 'locales/uiEN.json';
import uiES from 'locales/uiES.json';
import AdminsEN from 'locales/AdminsEN.json';
import AdminsES from 'locales/AdminsES.json';
import DoctorsEN from 'locales/DoctorsEN.json';
import DoctorsES from 'locales/DoctorsES.json';
import NotesEN from 'locales/NotesEN.json';
import NotesES from 'locales/NotesES.json';
import SurveysEN from 'locales/SurveysEN.json';
import SurveysES from 'locales/SurveysES.json';
import ImportExportEN from 'locales/ImportExportEN.json';
import ImportExportES from 'locales/ImportExportES.json';

i18n.use(initReactI18next).init({
  resources: {},
  lng: LocalStorageService.getItem('locale') || 'en',
  keySeparator: false,
  // eslint-disable-next-line no-undef
  debug: process.env.NODE_ENV === 'development',
  interpolation: {
    escapeValue: false,
  },
});

const i18ConfigMap = {
  Clinics: { en: ClinicsEN, es: ClinicsES},
  Auth: { en: AuthEN, es: AuthES},
  Resources: { en: ResourcesEN, es: ResourcesES},
  Patients: { en: PatientsEN, es: PatientsES},
  Account: { en: AccountEN, es: AccountES},
  UI: { en: uiEN, es: uiES},
  Admins: { en: AdminsEN, es: AdminsES},
  Doctors: { en: DoctorsEN, es: DoctorsES},
  Notes: { en: NotesEN, es: NotesES},
  Surveys: { en: SurveysEN, es: SurveysES},
  ImportExport: { en: ImportExportEN, es: ImportExportES},
};

Object.keys(i18ConfigMap).forEach(page => {
  const list = i18ConfigMap[page];

  Object.keys(list).forEach(lang => {
    i18n.addResourceBundle(lang, page, list[lang], true, true);
  });
});

export default i18n;
