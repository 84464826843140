import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { ROUTES } from 'constants/routes';

import historyService from 'services/history/HistoryService';

import * as authActions from 'store/auth/actions';

function SignOutView() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authActions.signOut.start());
    historyService.push(`/${ROUTES.ROLE_SELECT.path}`);
  });

  return null;
}

export default SignOutView;
