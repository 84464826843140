import React, { useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form, Input, Button } from "element-react";
import PropTypes from "prop-types";
import NavigationService from "services/navigation/NavigationService";

import customComponentsStyles from "styles/custom-components.module.scss";
import styles from "./ForgotPassword.module.scss";
import { forgotPassword } from "services/api/ApiService";

ForgotPassword.propTypes = {
    location: PropTypes.object.isRequired,
};

function ForgotPassword() {
    const { t } = useTranslation();

    const [isFormSent, setFormSent] = useState(false);
    const [login, setLogin] = useState("");

    const clearFormFields = () => {
        setLogin("");
        setFormSent(false);
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        try {
            await forgotPassword({ email: login });
            setFormSent(true);
        } catch (e) {
            console.log(e);
        }
    };

    const onBack = () => {
        clearFormFields();
        NavigationService.navigateToDefaultPath();
    };

    return (
        <Fragment>
            <div className={styles.card}>
                <div className={styles.title}>{t("Auth:ForgotPassword.form.title")}</div>
                {isFormSent ? (
                    <div>{t("Auth:ForgotPassword.form.response")}</div>
                ) : (
                    <>
                        <div className={styles.texts}>
                            {t("Auth:ForgotPassword.form.description")}
                        </div>

                        <Form onSubmit={onSubmit}>
                            <Input
                                type="text"
                                placeholder={t(
                                    "Auth:ForgotPassword.form.input.login.placeholder"
                                )}
                                value={login}
                                onChange={(value) => {
                                    setLogin(value);
                                }}
                                autoComplete="off"
                            />
                            <Button
                                onClick={onSubmit}
                                disabled={login.length === 0}
                                className={[
                                    customComponentsStyles.buttonCustom,
                                    styles.button,
                                ]}
                            >
                                {t("Auth:ForgotPassword.form.button.sendVerificationCode")}
                            </Button>
                        </Form>
                    </>
                )}
            </div>
            <div className={styles.backButton}>
                <Button onClick={onBack} className={customComponentsStyles.buttonCustom}>
                    {t("Auth:ForgotPassword.commonControls.button.back")}
                </Button>
            </div>
        </Fragment>
    );
}

export default ForgotPassword;
