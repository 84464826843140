import React from 'react';
import ContentWithHeaderLayout from 'layouts/pageContentLayouts/ContentWithHeaderLayout/ContentWithHeaderLayout';
// import PatientSurveyResultsList from 'сomponents/PatientSurveyResults/PatientSurveyResultsList/PatientSurveyResultsList';
// import PatientSurveyResultsDetails from 'сomponents/PatientSurveyResults/PatientSurveyResultsDetails/PatientSurveyResultsDetails';

import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import styles from './PatientResultsView.module.scss';
// import PatientSurvey from 'сomponents/PatientSurveys/PatientSurvey/PatientSurvey';
import SurveysList from 'сomponents/Surveys/SurveysList/SurveysList';
import SurveysResults from 'сomponents/Surveys/SurveysResults/SurveysResults';
import SurveyQuestionnaire from 'сomponents/Surveys/SurveyQuestionnaire/SurveyQuestionnaire';
import { SURVEY_INSTANCE_STATUSES } from 'constants/surveyInstance';
import { useSelector } from 'react-redux';
import SurveyResultsLoader from '../../сomponents/Surveys/SurveysResults/SurveyResultsLoader/SurveyResultsLoader';
import { Card } from 'element-react';

PatientResultsView.propTypes = {
  computedMatch: PropTypes.object.isRequired,
};

function PatientResultsView({computedMatch}) {
  const { t } = useTranslation();

  const surveyInstanceId = get(computedMatch, 'params.id', '');

  const surveyStatus = useSelector(
    (state) => get(state.surveys.surveyInstance, 'instanceStatus', null)
  );

  const surveyInstancesListDataSequence = useSelector(state => state.surveys.surveyInstancesList.dataSequence);

  // const isQuestionnaireMode = ;
  // const isResultsMode = ;

  const renderDetailsSection = () => {
    if(surveyInstancesListDataSequence.length === 0) {
      return ( // TODO: refactor
        <Card className={styles.detailsPlaceholderCard}>
          <SurveyResultsLoader />
        </Card>
      );
    }

    if (surveyStatus === SURVEY_INSTANCE_STATUSES.created || surveyStatus === SURVEY_INSTANCE_STATUSES.inProgress) {
      return <SurveyQuestionnaire surveyInstanceId={surveyInstanceId} />;
    }

    if(surveyStatus !== SURVEY_INSTANCE_STATUSES.created && surveyStatus !== SURVEY_INSTANCE_STATUSES.inProgress) {
      return <SurveysResults surveyInstanceId={surveyInstanceId} />;
    }

    return <div>{t('Surveys:PatientResultsView.placeholder.surveyError')}</div>;
  };

  return (
    <ContentWithHeaderLayout
      padding
      headerTitle={t('Surveys:PatientResultsView.header.heading')}
    >
      {/* <PatientResults/> */}
      <div className={styles.contentWrapper}>
        <div className={styles.surveyListHolder}>
          <SurveysList />
        </div>
        <div className={styles.surveyDetailsHolder}>
          {/* <PatientSurveyResultsDetails surveyInstanceId={surveyInstanceId} /> */}
          {/* <PatientSurvey surveyInstanceId={surveyInstanceId} /> */}
          { renderDetailsSection() }
        </div>
      </div>
    </ContentWithHeaderLayout>
  );
}

export default PatientResultsView;
