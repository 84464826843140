import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import styles from "./PatientAutoLogin.module.scss";

import { autoLogin } from "store/auth/actions";

PatientAutoLogin.propTypes = {
    match: PropTypes.object.isRequired,
};

function PatientAutoLogin({ match: { params } }) {
    const dispatch = useDispatch();

    useEffect(() => {
        if (params?.token) {
            const { token } = params;

            dispatch(autoLogin.start({ token }));
        }
    }, []);

    return <div className={styles["auto-login"]}>Please wait...</div>;
}

export default PatientAutoLogin;
