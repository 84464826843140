export const migrations = {
  0: state => {
    // migration clear out device state
    return {
      ...state,
      auth: {
        ...state.auth,
        isAuthorized: false,
        accountType: '',
        loading: false,
        signInError: false,
      },
    };
  },
};
