import React, { useEffect, useState } from "react";
import ContentWithHeaderLayout from "layouts/pageContentLayouts/ContentWithHeaderLayout/ContentWithHeaderLayout";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import styles from "./PatientHomeView.module.scss";
import { Card } from "element-react";
import Conclusion from "сomponents/Surveys/SurveysResults/Conclusion/Conclusion";
import * as surveysActions from "store/surveys/actions";
import ScoresTreeChart from "сomponents/ScoresTreeChart/ScoresTreeChart";
import ImportantIssue from "сomponents/Surveys/SurveysResults/ImportantIssue/ImportantIssue";
import { SURVEY_INSTANCE_STATUSES } from "constants/surveyInstance";
import UserService from "services/user/UserService";
import PatientResourcesList from "сomponents/PatientResourcesList/PatientResourcesList";

function PatientHomeView() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const surveyInstancesListData = useSelector(
        (state) => state.surveys.surveyInstancesList.data
    );
    const surveyInstancesListDataSequence = useSelector(
        (state) => state.surveys.surveyInstancesList.dataSequence
    );

    const [surveyInstanceId, setSurveyInstanceId] = useState(null);
    const [isPatientHasCompletedSurvey, setIsPatientHasCompletedSurvey] = useState(false);

    useEffect(() => {
        dispatch(
            surveysActions.getSurveyInstancesList.start({
                email: UserService.getCurrentUserEmail(),
            })
        );
    }, []);

    useEffect(() => {
        const surveyInstancesListProcessedDataSequence =
            surveyInstancesListDataSequence.filter((key) => {
                return (
                    surveyInstancesListData[key].instanceStatus ===
                    SURVEY_INSTANCE_STATUSES.completed
                );
            });

        surveyInstancesListProcessedDataSequence.sort((keyX, keyY) => {
            return (
                surveyInstancesListData[keyX].instanceLastUpdatedTimestamp -
                surveyInstancesListData[keyY].instanceLastUpdatedTimestamp
            );
        });

        if (surveyInstancesListProcessedDataSequence.length !== 0) {
            setSurveyInstanceId(surveyInstancesListProcessedDataSequence[0]);
            dispatch(
                surveysActions.getSurveyInstance.start({
                    id: surveyInstancesListProcessedDataSequence[0],
                })
            );
            setIsPatientHasCompletedSurvey(true);
        }
    }, [surveyInstancesListDataSequence]);

    const renderHomeScreen = () => {
        return (
            <div className={styles.homeWrapper}>
                <div className={`${styles.row} ${styles.rowMiddle}`}>
                    <div className={styles.col}>
                        <Card className={styles.card}>
                            <h2 className={styles.cardHeading}>
                                {t("Patients:PatientHomeView.section.latestResults")}
                            </h2>
                            <div className={styles.cardSection}>
                                <h2>
                                    {t(
                                        "Patients:PatientHomeView.section.importantIssue.heading"
                                    )}
                                </h2>
                                <ImportantIssue />
                            </div>
                            <div className={styles.cardSection}>
                                <ScoresTreeChart />
                            </div>
                        </Card>
                    </div>
                    <div className={styles.col}>
                        <Card className={styles.card}>
                            <h2 className={styles.cardHeading}>
                                {t("Patients:PatientHomeView.section.agreedCarePlan.heading")}
                            </h2>
                            <Conclusion />
                            <h2 className={styles.cardHeading}>
                                {t(
                                    "Patients:PatientHomeView.section.recommendedResources.heading"
                                )}
                            </h2>
                            <PatientResourcesList />
                        </Card>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <ContentWithHeaderLayout
            headerTitle={t("Patients:PatientHomeView.header.heading")}
            padding
        >
            {isPatientHasCompletedSurvey ? (
                renderHomeScreen()
            ) : (
                <Card>{t("Patients:PatientHomeView.firstSurveyInstruction")}</Card>
            )}
        </ContentWithHeaderLayout>
    );
}

export default PatientHomeView;
