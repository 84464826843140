import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { USER_ROLES } from "constants/userRoles";

import ContentWithHeaderLayout from "layouts/pageContentLayouts/ContentWithHeaderLayout/ContentWithHeaderLayout";
import ListTablePageLayout from "layouts/pageContentLayouts/ListTablePageLayout/ListTablePageLayout";

import AddUser from "сomponents/AddUser/AddUser";
import AddOneTimeUser from "сomponents/AddOneTimeUser/AddOneTimeUser";
import MassSignUp from "сomponents/MassSignUp/MassSignUp";
import ListTablePatientsDoctorCabinet from "сomponents/ListTablePatientsDoctorCabinet/ListTablePatientsDoctorCabinet";
import FiltersPatientsToggle from "сomponents/FiltersPatients/FIltersPatientsToggle/FiltersPatientsToggle";
import FiltersPatientsForm from "сomponents/FiltersPatients/FIltersPatientsForm/FiltersPatientsForm";
import SearchPatients from "сomponents/SearchPatients/SearchPatients";

import styles from "./DoctorPatientsListView.module.scss";

function DoctorPatientsListView() {
    const { t } = useTranslation();

    const [filtersIsOpened, setFiltersIsOpened] = useState(false);
    const [filtersSelected, setFiltersSelected] = useState(0);

    const [localSearch, setLocalSearch] = useState("");

    const onFiltersToggle = () => {
        setFiltersIsOpened(!filtersIsOpened);
    };

    return (
        <ContentWithHeaderLayout
            padding
            headerTitle={t("Patients:DoctorPatientsListView.header.heading")}
        >
            <ListTablePageLayout
                headerContent={
                    <div className={styles.headerContentHolder}>
                        <div className={styles.addUserHolder}>
                            <AddUser userTypeToAdd={USER_ROLES.patient} />
                            {/* <AddOneTimeUser /> */}
                            {/* <MassSignUp /> */}
                        </div>
                        <div className={styles.filtersSearchHolder}>
                            <SearchPatients
                                localSearch={localSearch}
                                setLocalSearch={setLocalSearch}
                            />
                            <FiltersPatientsToggle
                                onToggle={onFiltersToggle}
                                filtersSelected={filtersSelected}
                            />
                        </div>
                    </div>
                }
                mainContent={
                    <div
                        className={classNames(styles.mainContentHolder, {
                            [styles.filtersIsOpened]: filtersIsOpened,
                        })}
                    >
                        <div className={styles.listTableHolder}>
                            <ListTablePatientsDoctorCabinet localSearch={localSearch} />
                        </div>
                        <div className={styles.filtersFormHolder}>
                            <FiltersPatientsForm
                                onToggle={onFiltersToggle}
                                onNumberOfSelectedFiltersChange={setFiltersSelected}
                            />
                        </div>
                    </div>
                }
            />
        </ContentWithHeaderLayout>
    );
}

export default DoctorPatientsListView;
