import { store } from "store/store";

import { ROUTES } from "constants/routes";
import { DEFAULT_PATHS_MAP } from "constants/defaultPathsMap";

import HistoryService from "services/history/HistoryService";
import UserService from "services/user/UserService";

const NavigationService = {
    getLocationPathName() {
        return window.location.pathname;
    },
    isSuperadminResultsPage() {
        const locationPathname = this.getLocationPathName();

        return locationPathname === `/${ROUTES.SUPERADMIN_RESULTS.path}`;
    },
    isSuperadminClinicsListPage() {
        const locationPathname = this.getLocationPathName();

        return locationPathname === `/${ROUTES.SUPERADMIN_CLINICS_LIST.path}`;
    },
    isAdminSignInPage() {
        const locationPathname = this.getLocationPathName();

        return locationPathname === `/${ROUTES.SIGN_IN_ADMIN.path}`;
    },
    isRoleSelectPage() {
        const locationPathname = this.getLocationPathName();

        return locationPathname === `/${ROUTES.ROLE_SELECT.path}`;
    },
    isForgotPasswordPage() {
        const locationPathname = this.getLocationPathName();

        return locationPathname === `/${ROUTES.FORGOT_PASSWORD.path}`;
    },

    navigateToPath(path, state = {}) {
        HistoryService.push(`/${path}`, state);
    },
    navigateToDefaultPath() {
        const currentState = store.getState();
        const accountType = currentState.auth.accountType;

        let redirectPath =
            DEFAULT_PATHS_MAP[accountType]?.redirectPath || ROUTES.REDIRECT_URL.path;

        if (UserService.isOneTimePatient()) {
            // TODO: add oneTimeUser as a separate role and remove userService dependency
            redirectPath = ROUTES.ONE_TIME_SURVEY.path;
        }

        this.navigateToPath(redirectPath);
    },
};

export default NavigationService;
