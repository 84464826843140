import React from "react";

import styles from "./ResearchListDoctor.module.scss";

// import pdf1 from 'pdf/Kaleidoscope Model of Diabetes Care Time for a Rethink.pdf';
// import pdf2 from 'pdf/Spotlight AQ Feasibility Study publication Feb 2021.pdf';
// import pdf3 from 'pdf/Utilizing_eHealth_and_Telemedicine_Technologies_to_Enhance_Access.pdf';

function ResearchListDoctor() {
  return (
    <div className={styles.researchLict}>
      {/* <a href={pdf1} download >Utilizing eHealth and Telemedicine Technologies to Enhance Access</a>
      <a href={pdf2} download >Spotlight AQ Feasibility Study publication Feb 2021</a>
      <a href={pdf3} download >Utilizing eHealth and Telemedicine Technologies to Enhance Access</a> */}
    </div>
  );
}

export default ResearchListDoctor;
