import React from 'react';
import { useTranslation } from 'react-i18next';

import ContentWithHeaderLayout from 'layouts/pageContentLayouts/ContentWithHeaderLayout/ContentWithHeaderLayout';
import SurveysExport from 'сomponents/SurveysExport/SurveysExport';

function SuperadminResultsView() {
  const { t } = useTranslation();

  return (
    <ContentWithHeaderLayout
      padding
      headerTitle={t('ImportExport:SuperadminResultsView.header.heading')}
    >
      <SurveysExport />
    </ContentWithHeaderLayout>
  );
}

export default SuperadminResultsView;
