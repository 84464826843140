import React from "react";
import { useTranslation } from "react-i18next";

import ContentWithHeaderLayout from "layouts/pageContentLayouts/ContentWithHeaderLayout/ContentWithHeaderLayout";
import PatientResourcesList from "сomponents/PatientResourcesList/PatientResourcesList";

function PatientResourcesView() {
    const { t } = useTranslation();

    return (
        <ContentWithHeaderLayout
            padding
            bg
            headerTitle={t("Resources:PatientResourcesView.header.heading")}
        >
            <PatientResourcesList />
        </ContentWithHeaderLayout>
    );
}

export default PatientResourcesView;
