import { createActions } from 'redux-actions';

export const { createDoctor, getDoctor, getDoctorsList } = createActions(
  {
    CREATE_DOCTOR: {
      START: ({ clinicId, email, firstName, lastName }) => ({
        clinicId,
        email,
        firstName,
        lastName,
      }),
      SUCCESS: undefined,
      FAILURE: undefined,
    },
    GET_DOCTOR: {
      START: ({ email }) => ({ email }),
      SUCCESS: ({
        email,
        firstName,
        lastName,
        status,
        demographics,
      }) => ({
        email,
        firstName,
        lastName,
        status,
        demographics,
      }),
      FAILURE: undefined,
    },
    GET_DOCTORS_LIST: {
      START: ({
        pageSize,
        pageNumber,
      }) => ({
        pageSize,
        pageNumber,
      }),
      SUCCESS: ({
        data,
        dataSequence,
        pageable,
        sort,
        meta,
      }) => ({
        data,
        dataSequence,
        pageable,
        sort,
        meta,
      }),
      FAILURE: undefined,
    },
  },
  { prefix: 'DOCTORS' }
);
