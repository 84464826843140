import { all, put, takeEvery, call } from 'redux-saga/effects';

import { handleRequestError } from 'services/api/apiTools';

import * as actions from './actions';
import * as ApiService from 'services/api/ApiService';

export function* createNoteSaga(action) {
  const {
    content,
  } = action.payload;

  const reqData = {
    text: content,
  };

  try {
    yield call(ApiService.noteCreate, reqData);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.createNote.failure());
    return;
  }

  yield put(actions.createNote.success());
  yield put(actions.getNotesList.start());
}

export function* getNotesListSaga() {
  let data;

  try {
    data = yield call(ApiService.notesListGet);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getNotesList.failure());
    return;
  }

  const processedData = {};
  const processedDataSequence = [];

  data.content.forEach((item) => {
    const {
      createTimestamp,
      id,
      lastUpdatedTimestamp,
      text,
    } = item;

    processedDataSequence.push(id);

    processedData[id] = {
      createTimestamp,
      id,
      lastUpdatedTimestamp,
      text,
    };
  });

  const meta = {
    empty: data.empty,
    first: data.first,
    last: data.last,
    number: data.number,
    numberOfElements: data.numberOfElements,
    size: data.size,
    totalElements: data.totalElements,
    totalPages: data.totalPages,
  };

  yield put(
    actions.getNotesList.success({
      data: processedData,
      dataSequence: processedDataSequence,
      pageable: data.pageable,
      sort: data.sort,
      meta,
    })
  );
}

export function* notesSagas() {
  yield all([
    yield takeEvery(actions.createNote.start, createNoteSaga),

    yield takeEvery(actions.getNotesList.start, getNotesListSaga),
    // yield takeEvery(actions.getNote.start, getNoteSaga),

    // yield takeEvery(actions.updateNote.start, updateNoteSaga),
    // yield takeEvery(actions.removeNote.start, removeNoteSaga),
  ]);
}
