import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';

import CookiesService from 'services/cookies/CookiesService';

import { ROUTES } from 'constants/routes';

function CookieConsentUnit () {
  const { t } = useTranslation();

  const onAccept = () => {
    CookiesService.setConsentCookie(true);
    CookiesService.setInitialValues();
  };

  const onDecline = () => {
    CookiesService.removeAllCookies();
    CookiesService.setConsentCookie(false); // TODO: !clarify: can we keep user consent value in cookies in case if user declined cookies
  };

  return (
    <CookieConsent
      enableDeclineButton
      style={{ 
        backgroundColor: '#E9E9EB',
        color: '#303133',
        padding: '8px 38px',
        textAlign: 'left',
        alignItems: 'center'
      }}
      onAccept={onAccept}
      onDecline={onDecline}
      buttonText={t('UI:CookieConsentUnit.button.accept')}
      declineButtonText={t('UI:CookieConsentUnit.button.decline')}
    >
      {t('UI:CookieConsentUnit.description')} <span> </span>
      <Link to={{ pathname: `/${ROUTES.DOCS_COOKIES_POLICY.path}` }} target="_blank">
        {t('UI:CookieConsentUnit.link.cookiePolicy')}
      </Link>
    </CookieConsent>
  );
}

export default CookieConsentUnit;
