import { all, call, put, takeEvery } from "redux-saga/effects";
import i18n from "i18next";

import * as ApiService from "services/api/ApiService";
import NotificationsService from "services/notifications/NotificationsService";
import { handleRequestError } from "services/api/apiTools";
import { PAGE_SIZE } from "constants/pagination";

import * as actions from "./actions";
import LanguageService from "services/language/LanguageService";

export function* createClinicSaga(action) {
    const { name } = action.payload;

    try {
        yield call(ApiService.clinicCreate, { name });
    } catch (errors) {
        NotificationsService.throwNotification(
            "error",
            LanguageService.t("UI:Error"),
            LanguageService.t("UI:ClinicRegistrationError")
        );
        handleRequestError(errors);
        yield put(actions.createClinic.failure());
        return;
    }

    NotificationsService.throwNotification(
        "success",
        LanguageService.t("UI:Success"),
        LanguageService.t("UI:ClinicCreated")
    );

    yield put(actions.createClinic.success());

    yield put(actions.getClinicsList.start({ pageSize: PAGE_SIZE, pageNumber: 0 }));
}

export function* getClinicsListSaga(action) {
    const { pageSize, pageNumber } = action.payload;

    let data;

    try {
        data = yield call(ApiService.clinicsListGet, pageSize, pageNumber);
    } catch (errors) {
        handleRequestError(errors);
        yield put(actions.getClinicsList.failure());
        return;
    }

    const processedData = {};
    const processedDataSequence = [];

    data.content.forEach((item) => {
        const { id, locale, name } = item;

        processedDataSequence.push(id);

        processedData[id] = {
            id,
            locale,
            name,
        };
    });

    const meta = {
        empty: data.empty,
        first: data.first,
        last: data.last,
        number: data.number,
        numberOfElements: data.numberOfElements,
        size: data.size,
        totalElements: data.totalElements,
        totalPages: data.totalPages,
    };

    yield put(
        actions.getClinicsList.success({
            data: processedData,
            dataSequence: processedDataSequence,
            pageable: data.pageable,
            sort: data.sort,
            meta,
        })
    );
}

export function* getClinicSaga(action) {
    const { clinicId } = action.payload;
    let data;

    try {
        data = yield call(ApiService.clinicGet, clinicId);
    } catch (errors) {
        handleRequestError(errors);
        yield put(actions.getClinic.failure());
        return;
    }

    yield put(
        actions.getClinic.success({
            id: data.id,
            name: data.name,
            locale: data.locale,
        })
    );
}

export function* clinicsSagas() {
    yield all([
        yield takeEvery(actions.createClinic.start, createClinicSaga),
        yield takeEvery(actions.getClinicsList.start, getClinicsListSaga),
        yield takeEvery(actions.getClinic.start, getClinicSaga),
    ]);
}
