import { USER_ROLES } from "constants/userRoles";

export const ROUTES = {
    REDIRECT_URL: {
        path: "login",
        roles: [USER_ROLES.admin, USER_ROLES.doctor, USER_ROLES.patient],
    },

    DOCS_ROOT: {
        path: "docs",
        roles: [
            USER_ROLES.superadmin,
            USER_ROLES.admin,
            USER_ROLES.doctor,
            USER_ROLES.patient,
        ],
    },
    DOCS_PRIVACY_POLICY: {
        path: "docs/privacy-policy",
        roles: [
            USER_ROLES.superadmin,
            USER_ROLES.admin,
            USER_ROLES.doctor,
            USER_ROLES.patient,
        ],
    },
    DOCS_TERMS: {
        path: "docs/terms",
        roles: [
            USER_ROLES.superadmin,
            USER_ROLES.admin,
            USER_ROLES.doctor,
            USER_ROLES.patient,
        ],
    },
    DOCS_COOKIES_POLICY: {
        path: "docs/cookies-policy",
        roles: [
            USER_ROLES.superadmin,
            USER_ROLES.admin,
            USER_ROLES.doctor,
            USER_ROLES.patient,
        ],
    },

    ROLE_SELECT: {
        path: "login",
        roles: [USER_ROLES.admin, USER_ROLES.doctor, USER_ROLES.patient],
    },

    SIGN_IN_SUPERADMIN: {
        path: "sign-in-superadmin",
        roles: [
            USER_ROLES.superadmin,
            USER_ROLES.admin,
            USER_ROLES.doctor,
            USER_ROLES.patient,
        ],
    },
    SIGN_IN_ADMIN: {
        path: "sign-in-admin",
        roles: [USER_ROLES.admin, USER_ROLES.doctor, USER_ROLES.patient],
    },

    SIGN_OUT: {
        path: "sign-out",
        roles: [
            USER_ROLES.superadmin,
            USER_ROLES.admin,
            USER_ROLES.doctor,
            USER_ROLES.patient,
        ],
    },

    FORGOT_PASSWORD: {
        path: "forgot-password",
        roles: [
            USER_ROLES.superadmin,
            USER_ROLES.admin,
            USER_ROLES.doctor,
            USER_ROLES.patient,
        ],
    },
    RESET_PASSWORD: {
        path: "reset-password/:token",
        roles: [
            USER_ROLES.superadmin,
            USER_ROLES.admin,
            USER_ROLES.doctor,
            USER_ROLES.patient,
        ],
    },
    CHANGE_PASSWORD: {
        path: "change-password",
        roles: [USER_ROLES.admin, USER_ROLES.doctor, USER_ROLES.patient],
    },

    REGISTRATION_ADMIN: {
        path: "registration-admin",
        roles: [USER_ROLES.admin, USER_ROLES.doctor, USER_ROLES.patient],
    },
    REGISTRATION_DOCTOR: {
        path: "registration-doctor",
        roles: [USER_ROLES.admin, USER_ROLES.doctor, USER_ROLES.patient],
    },
    REGISTRATION_PATIENT: {
        path: "registration-patient",
        roles: [USER_ROLES.admin, USER_ROLES.doctor, USER_ROLES.patient],
    },

    TERMS_AND_CONDITIONS: {
        path: "terms-and-conditions",
        roles: [USER_ROLES.doctor, USER_ROLES.patient],
    },

    AUX_SURVEY: {
        path: "initial-survey",
        roles: [USER_ROLES.doctor, USER_ROLES.patient],
    },

    SUPERADMIN_ROOT: {
        path: "superadmin",
        roles: [USER_ROLES.superadmin],
    },
    SUPERADMIN_RESULTS: {
        path: "superadmin/results",
        roles: [USER_ROLES.superadmin],
    },
    SUPERADMIN_CLINICS_LIST: {
        path: "superadmin/clinics-list",
        roles: [USER_ROLES.superadmin],
    },
    SUPERADMIN_CLINIC_PROFILE: {
        path: "superadmin/clinic-profile",
        roles: [USER_ROLES.superadmin],
    },
    SUPERADMIN_CLINIC_PROFILE_ITEM: {
        path: "superadmin/clinic-profile/:id",
        roles: [USER_ROLES.superadmin],
    },
    SUPERADMIN_ADMINS_LIST: {
        path: "superadmin/admins-list",
        roles: [USER_ROLES.superadmin],
    },
    SUPERADMIN_ADMIN_PROFILE: {
        path: "superadmin/admin-profile",
        roles: [USER_ROLES.superadmin],
    },
    SUPERADMIN_ADMIN_PROFILE_ITEM: {
        path: "superadmin/admin-profile/:id",
        roles: [USER_ROLES.superadmin],
    },
    SUPERADMIN_DOCTORS_LIST: {
        path: "superadmin/doctors-list",
        roles: [USER_ROLES.superadmin],
    },
    SUPERADMIN_DOCTOR_PROFILE: {
        path: "superadmin/doctor-profile",
        roles: [USER_ROLES.superadmin],
    },
    SUPERADMIN_DOCTOR_PROFILE_ITEM: {
        path: "superadmin/doctor-profile/:id",
        roles: [USER_ROLES.superadmin],
    },
    SUPERADMIN_PATIENTS_LIST: {
        path: "superadmin/patients-list",
        roles: [USER_ROLES.superadmin],
    },
    SUPERADMIN_PATIENT_PROFILE: {
        path: "superadmin/patient-profile",
        roles: [USER_ROLES.superadmin],
    },
    SUPERADMIN_PATIENT_PROFILE_ITEM: {
        path: "superadmin/patient-profile/:id",
        roles: [USER_ROLES.superadmin],
    },

    ADMIN_ROOT: {
        path: "admin",
        roles: [USER_ROLES.admin],
    },
    ADMIN_DOCTORS_LIST: {
        path: "admin/doctors-list",
        roles: [USER_ROLES.admin],
    },
    ADMIN_DOCTOR_PROFILE: {
        path: "admin/doctor-profile",
        roles: [USER_ROLES.superadmin, USER_ROLES.admin],
    },
    ADMIN_DOCTOR_PROFILE_ITEM: {
        path: "admin/doctor-profile/:id",
        roles: [USER_ROLES.superadmin, USER_ROLES.admin],
    },
    ADMIN_PATIENTS_LIST: {
        path: "admin/patients-list",
        roles: [USER_ROLES.superadmin, USER_ROLES.admin],
    },
    ADMIN_PATIENT_PROFILE: {
        path: "admin/patient-profile",
        roles: [USER_ROLES.superadmin, USER_ROLES.admin],
    },
    ADMIN_PATIENT_PROFILE_ITEM: {
        path: "admin/patient-profile/:id",
        roles: [USER_ROLES.superadmin, USER_ROLES.admin],
    },

    DOCTOR_ROOT: {
        path: "doctor",
        roles: [USER_ROLES.doctor],
    },
    DOCTOR_USERS_LIST: {
        path: "doctor/users-list",
        roles: [USER_ROLES.doctor],
    },
    DOCTOR_USERS_SURVEY_RESULTS: {
        path: "doctor/survey-results",
        roles: [USER_ROLES.doctor],
    },
    DOCTOR_USERS_SURVEY_RESULTS_ITEM: {
        path: "doctor/survey-results/:id",
        roles: [USER_ROLES.doctor],
    },
    DOCTOR_RESOURCES: {
        path: "doctor/resources",
        roles: [USER_ROLES.doctor],
    },
    DOCTOR_RESOURCES_TONE: {
        path: "doctor/resources/t1",
        roles: [USER_ROLES.doctor],
    },
    DOCTOR_RESOURCES_TTWO: {
        path: "doctor/resources/t2",
        roles: [USER_ROLES.doctor],
    },
    DOCTOR_RESOURCES_PRE: {
        path: "doctor/resources/pre",
        roles: [USER_ROLES.doctor],
    },
    DOCTOR_RESOURCES_HCP: {
        path: "doctor/resources/hcp",
        roles: [USER_ROLES.doctor],
    },
    DOCTOR_RESOURCES_CHILDREN_AND_PARENTS: {
        path: "doctor/resources/children-and-parents",
        roles: [USER_ROLES.doctor],
    },
    DOCTOR_RESEARCH: {
        path: "doctor/research",
        roles: [USER_ROLES.doctor],
    },
    DOCTOR_HELP: {
        path: "doctor/help",
        roles: [USER_ROLES.doctor],
    },

    PATIENT_ROOT: {
        path: "patient",
        roles: [USER_ROLES.patient],
    },
    PATIENT_AUTO_LOGIN: {
        path: "patient/:token",
        roles: [USER_ROLES.patient],
    },
    PATIENT_HOME: {
        path: "patient/home",
        roles: [USER_ROLES.patient],
    },
    PATIENT_RESULTS: {
        path: "patient/results",
        roles: [USER_ROLES.patient],
    },
    PATIENT_RESOURCES: {
        path: "patient/resources",
        roles: [USER_ROLES.patient],
    },
    PATIENT_RESULTS_ITEM: {
        path: "patient/results/:id",
        roles: [USER_ROLES.patient],
    },
    PATIENT_NOTES: {
        path: "patient/notes",
        roles: [USER_ROLES.patient],
    },

    ONE_TIME_ROOT: {
        path: "one-time",
        roles: [USER_ROLES.patient],
    },
    ONE_TIME_SURVEY: {
        path: "one-time/survey",
        roles: [USER_ROLES.patient],
    },
    NEW_PATIENT_SURVEYS: {
        path: "survey",
        roles: [USER_ROLES.patient],
    },
};
