import React, { Fragment } from 'react';
import ChangePassword from 'сomponents/ChangePassword/ChangePassword';

function ChangePasswordView() {
  return (
    <Fragment>
      <ChangePassword />
    </Fragment>
  );
}

export default ChangePasswordView;
