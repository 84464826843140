import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "element-react";

import { USER_ROLES } from "constants/userRoles";

import * as adminsActions from "store/admins/actions";
import * as doctorsActions from "store/doctors/actions";
import * as patientsActions from "store/patients/actions";
import * as surveysActions from "store/surveys/actions";

import notificationsService from "services/notifications/NotificationsService";

import AddUserDialog from "сomponents/AddUser/AddUserDialog/AddUserDialog";

import customComponentsStyles from "styles/custom-components.module.scss";

AddUser.propTypes = {
    userTypeToAdd: PropTypes.string.isRequired,
    presetedClinicId: PropTypes.string,
};

function AddUser({ userTypeToAdd, presetedClinicId }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(surveysActions.getSurveysList.start());
    }, []);

    const [isDialogVisible, setIsDialogVisible] = useState(false);

    const onUserSubmit = (userData) => {
        if (userTypeToAdd === USER_ROLES.admin) {
            dispatch(adminsActions.createAdmin.start(userData));
            return;
        }

        if (userTypeToAdd === USER_ROLES.doctor) {
            dispatch(doctorsActions.createDoctor.start(userData));
            return;
        }

        if (userTypeToAdd === USER_ROLES.patient) {
            dispatch(patientsActions.createPatient.start(userData));
            return;
        }

        notificationsService.throwNotification(
            "error",
            t("Auth:AddUser.notification.wrongUserType.title"),
            t("Auth:AddUser.notification.wrongUserType.message")
        );
    };

    let buttonLabel;

    switch (userTypeToAdd) {
        case USER_ROLES.admin:
            buttonLabel = t("Auth:AddUser.button.addNewAdmin");
            break;
        case USER_ROLES.doctor:
            buttonLabel = t("Auth:AddUser.button.addNewDoctor");
            break;
        case USER_ROLES.patient:
            buttonLabel = t("Auth:AddUser.button.addNewPatient");
            break;
        default:
            console.log("Wrong user type");
    }

    return (
        <div>
            <Button
                className={customComponentsStyles.buttonCustom}
                onClick={() => {
                    setIsDialogVisible(true);
                }}
            >
                {buttonLabel}
            </Button>
            <AddUserDialog
                isVisible={isDialogVisible}
                setIsVisible={setIsDialogVisible}
                onSubmit={onUserSubmit}
                userTypeToAdd={userTypeToAdd}
                presetedClinicId={presetedClinicId}
            />
        </div>
    );
}

export default AddUser;
