import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import * as surveysActions from 'store/surveys/actions';

import NewSurvey from 'сomponents/Surveys/NewSurvey/NewSurvey';

import styles from './SurveysHeader.module.scss';

function SurveysHeader () {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const selectedPatient = useSelector(state => state.patients.selectedPatient);

  const onSurveyTypeSelect = (surveyTypeId) => {
    dispatch(surveysActions.createSurveyInstance.start({
      patientEmail: selectedPatient,
      surveyId: surveyTypeId,
    }));
  };

  return (
    <div className={styles.header}>
      <h1 className={styles.heading}>
        {t('Surveys:SurveysHeader.heading.surveyResults')}
      </h1>
      <div className={styles.menuHolder}>
        <NewSurvey
          onSurveyTypeSelect={onSurveyTypeSelect}
          triggerButtonCaption={t('Surveys:NewSurvey.button.newSurvey')}
        />
      </div>
    </div>
  );
}

export default SurveysHeader;
