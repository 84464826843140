import React from 'react';
import PropTypes from 'prop-types';

import Header from 'сomponents/Header/Header';

import styles from './ContentWithHeaderLayout.module.scss';

ContentWithHeaderLayout.propTypes = {
  headerTitle: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  padding: PropTypes.bool.isRequired, // TODO: refactor
  bg: PropTypes.bool,
};

function ContentWithHeaderLayout ({ headerTitle, children, padding = false, bg = false }) {
  return (
    <div className={styles.rootWrapper}>
      <div className={styles.headerHolder}>
        <Header headerTitle={headerTitle} />
      </div>
      <div className={`${styles.contentHolder} ${padding ? styles.contentHolderPadding : ''} ${bg ? styles.contentHolderBg : ''}`}
      >
        {children}
      </div>
    </div>
  );
}

export default ContentWithHeaderLayout;
