import React from "react";
import { Dropdown } from "element-react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { ROUTES } from "constants/routes";

import NavigationService from "services/navigation/NavigationService";

import * as authActions from "store/auth/actions";

import LanguageSelector from "сomponents/LanguageSelector/LanguageSelector";
import FontSizeSelector from "сomponents/FontSizeSelector/FontSizeSelector";

import styles from "./AccountMenu.module.scss";

const MENU_COMMANDS = {
    SIGN_OUT: "signOut",
    CHANGE_PASSWORD: "changePassword",
};

function AccountMenu() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const userEmail = useSelector((state) => state.auth?.userAccountData?.email) || "";
    const userFirstName = useSelector((state) => state.auth?.userAccountData?.firstName) || "";
    const userLastName = useSelector((state) => state.auth?.userAccountData?.lastName) || "";
    const isRestrictedUser = useSelector(
        (state) => state.auth?.userAccountData?.isRestrictedUser
    );

    const handleCommand = (command) => {
        if (command === MENU_COMMANDS.SIGN_OUT) {
            dispatch(authActions.signOut.start());
        }
        if (command === MENU_COMMANDS.CHANGE_PASSWORD) {
            NavigationService.navigateToPath(ROUTES.CHANGE_PASSWORD.path);
        }
    };

    return (
        <Dropdown
            onCommand={handleCommand}
            menu={
                <Dropdown.Menu>
                    <Dropdown.Item disabled>{userEmail}</Dropdown.Item>
                    <div className={styles.menuCustomContentHolder}>
                        <div className={styles.menuCustomItemHolder}>
                            <LanguageSelector />
                        </div>
                        <div className={styles.menuCustomItemHolder}>
                            <FontSizeSelector />
                        </div>
                    </div>
                    {/* TODO: Add caption */}
                    {!isRestrictedUser && (
                        <Dropdown.Item divided command={MENU_COMMANDS.CHANGE_PASSWORD}>
                            {t("Auth:ChangePassword.form.button.changePassword")}
                        </Dropdown.Item>
                    )}
                    <Dropdown.Item divided command={MENU_COMMANDS.SIGN_OUT}>
                        {t("Account:AccountMenu.button.signOut")}
                    </Dropdown.Item>
                </Dropdown.Menu>
            }
            trigger="click"
        >
            <div className={styles.dropdownContent}>
                {!isRestrictedUser && <div className={styles.userPic}></div>}

                <span className={styles.dropdownLink}>
                    {`${userFirstName} ${userLastName}`}
                    <i className="el-icon-caret-bottom el-icon--right"></i>
                </span>
            </div>
        </Dropdown>
    );
}

export default AccountMenu;
