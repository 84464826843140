import { handleActions } from "redux-actions";

import LocalStorageService from "services/localStorage/LocalStorageService";
import * as actions from "./actions";

export const initialState = {
    isLoadingGetExchangeCode: false,
    isLoadingSignIn: false,
    isLoadingSignOut: false,
    isLoadingGetAccountData: false,
    isLoadingAcceptLicense: false,
    isAuthorized: !!LocalStorageService.getItem("apiKey"),
    accountType: "",
    loading: false,
    signInError: false,
    userAuthData: {},
    userAccountData: {},
    isPrivacyPolicySigned: false, // TODO: remove
    authenticatedUserData: {
        // TODO: should replace userAccountData
        isLoading: false,
        data: {},
    },
};

export const authReducer = handleActions(
    {
        [actions.getExchangeCode.start]: (state) => ({
            ...state,
            isLoadingGetExchangeCode: true,
        }),
        [actions.getExchangeCode.success]: (state) => ({
            ...state,
            isLoadingGetExchangeCode: false,
        }),
        [actions.getExchangeCode.failure]: (state) => ({
            ...state,
            isLoadingGetExchangeCode: false,
        }),

        [actions.signIn.start]: (state) => ({
            ...state,
            isLoadingSignIn: true,
        }),
        [actions.signIn.success]: (state, action) => ({
            ...state,
            isLoadingSignIn: false,
            signInError: false,
            isAuthorized: action.payload.isAuthorized,
            accountType: action.payload.accountType,
            userAuthData: {
                ...state.userAuthData,
                isAuthorized: action.payload.isAuthorized,
                email: action.payload.email,
            },
        }),
        [actions.signIn.failure]: (state, action) => ({
            ...state,
            isLoadingSignIn: false,
            isAuthorized: action.payload.isAuthorized,
            signInError: true,
        }),

        [actions.autoLogin.start]: (state) => ({
            ...state,
            isLoadingSignIn: true,
        }),
        [actions.autoLogin.success]: (state, action) => ({
            ...state,
            isLoadingSignIn: false,
            signInError: false,
            isAuthorized: action.payload.isAuthorized,
            userAuthData: {
                ...state.userAuthData,
                isAuthorized: action.payload.isAuthorized,
            },
        }),
        [actions.autoLogin.failure]: (state, action) => ({
            ...state,
            isLoadingSignIn: false,
            isAuthorized: action.payload.isAuthorized,
            signInError: true,
        }),

        [actions.loginUserRestrictedSurveyBound.start]: (state) => ({
            ...state,
            isLoadingSignIn: true,
        }),
        [actions.loginUserRestrictedSurveyBound.success]: (state, action) => {
            return {
                ...state,
                isLoadingSignIn: false,
                signInError: false,
                isAuthorized: true,
                userAuthData: {
                    ...state.userAuthData,
                    isAuthorized: true,
                    email: action.payload.email,
                    status: "ACTIVE",
                },
                userAccountData: {
                    ...state.userAccountData,
                    isAuthorized: true,
                    email: action.payload.email,
                    status: "ACTIVE",
                    isRestrictedUser: true,
                },
                authenticatedUserData: {
                    data: {
                        data: {
                            ...state.authenticatedUserData.data.data,
                            isAuthorized: true,
                            email: action.payload.email,
                            status: "ACTIVE",
                        },
                    },
                },
            };
        },
        [actions.loginUserRestrictedSurveyBound.failure]: (state, action) => ({
            ...state,
            isLoadingSignIn: false,
            isAuthorized: action.payload.isAuthorized,
            signInError: true,
        }),

        [actions.signOut.start]: (state) => ({
            ...state,
            isLoadingSignOut: true,
        }),
        [actions.signOut.success]: (state, action) => ({
            ...state,
            isLoadingSignOut: false,
            isAuthorized: action.payload.isAuthorized,
            accountType: "",
            active: false,
            userAuthData: {},
            userAccountData: {},
        }),
        [actions.signOut.error]: (state) => ({
            // TODO: clarify this case
            ...state,
            isLoadingSignOut: false,
        }),

        [actions.selectUserRole]: (state, { payload }) => ({
            ...state,
            accountType: payload.userRole,
        }),

        [actions.getAccountData.start]: (state) => ({
            ...state,
            isLoadingGetAccountData: true,
            authenticatedUserData: {
                ...state.authenticatedUserData,
                isLoading: true,
            },
        }),
        [actions.getAccountData.success]: (state, { payload }) => ({
            ...state,
            isLoadingGetAccountData: false,
            userAccountData: {
                ...payload.data,
                role: payload.role,
            },
            authenticatedUserData: {
                ...state.authenticatedUserData,
                data: payload,
                isLoading: false,
            },
        }),
        [actions.getAccountData.error]: (state) => ({
            // TODO: clarify this case
            ...state,
            isLoadingGetAccountData: false,
            authenticatedUserData: {
                ...state.authenticatedUserData,
                isLoading: false,
            },
        }),

        [actions.acceptLicense.start]: (state) => ({
            ...state,
            isLoadingAcceptLicense: true,
        }),
        [actions.acceptLicense.success]: (state) => ({
            ...state,
            isLoadingAcceptLicense: false,
        }),
        [actions.acceptLicense.error]: (state) => ({
            ...state,
            isLoadingAcceptLicense: false,
        }),

        [actions.test.signPrivacyPolicy]: (state) => ({
            // TODO: remove
            ...state,
            isPrivacyPolicySigned: true,
        }),
    },
    initialState
);
