import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "element-react";
import { useTranslation } from "react-i18next";
import MarkdownHandler from "componentsShared/MarkdownHandler/MarkdownHandler";

import * as authActions from "store/auth/actions";

import customComponentsStyles from "styles/custom-components.module.scss";
import styles from "./TermsAndConditionsView.module.scss";

import TermsAndConditionsFileEn from "locales/TermsAndConditions_en-GB.md";
import TermsAndConditionsFileEs from "locales/TermsAndConditions_es-ES.md";

function TermsAndConditionsView() {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const onAccept = () => {
        dispatch(authActions.acceptLicense.start());
    };

    const onDecline = () => {
        dispatch(authActions.signOut.start());
    };

    return (
        <div className={styles.outerContainer}>
            <div className={styles.container}>
                <MarkdownHandler
                    translations={{
                        en: TermsAndConditionsFileEn,
                        es: TermsAndConditionsFileEs,
                    }}
                />
                <div className={styles.buttons}>
                    <Button onClick={onAccept} className={customComponentsStyles.buttonCustom}>
                        {t("Account:TermsAndConditionsView.button.accept")}
                    </Button>
                    <Button
                        onClick={onDecline}
                        className={customComponentsStyles.buttonCustom}
                    >
                        {t("Account:TermsAndConditionsView.button.decline")}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default TermsAndConditionsView;
