import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import logoWhite from "images/icons/logo-white.svg";
import logoBlack from "images/icons/logo-black.svg";

import styles from "./Logo.module.scss";

Logo.propTypes = {
    color: PropTypes.string.isRequired,
};

function Logo({ color }) {
    const { t } = useTranslation();

    return (
        <img
            className={styles.logo}
            src={color === "white" ? logoWhite : logoBlack}
            alt={t("UI:Logo.altText")}
        /> // TODO: implement link behaviour related to selected user role
    );
}

export default Logo;
