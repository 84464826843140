import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'element-react';

import styles from './FullheightWithTilesLayout.module.scss';

FullheightWithTilesLayout.propTypes = {
  tileOneContent: PropTypes.element,
  tileTwoContent: PropTypes.element,
  tileThreeContent: PropTypes.element,
  tileFourContent: PropTypes.element,
  tileFiveContent: PropTypes.element,
  tileSixContent: PropTypes.element,
};

function FullheightWithTilesLayout ({ // TODO: refactor (?)
  tileOneContent,
  tileTwoContent,
  tileThreeContent,
  tileFourContent,
  tileFiveContent,
  tileSixContent,
}) {

  const renderColumn = (content) => {
    if (!content) {
      return null;
    }

    return(
      <div className={styles.col}>
        <Card className={styles.card}>
          <div className={styles.cardInner}>
            { content }
          </div>
        </Card>
      </div>
    );
  };

  return (
    <div className={styles.rootWrapper}>
        {
          tileOneContent || tileTwoContent
          ? <div className={styles.row}>
            { renderColumn(tileOneContent) }
            { renderColumn(tileTwoContent) }
          </div>
          : null
        }
        {
          tileThreeContent || tileFourContent
          ? <div className={styles.row}>
            { renderColumn(tileThreeContent) }
            { renderColumn(tileFourContent) }
          </div>
          : null
        }
        {
          tileFiveContent || tileSixContent
          ? <div className={styles.row}>
            { renderColumn(tileFiveContent) }
            { renderColumn(tileSixContent) }
          </div>
          : null
        }
      </div>
  );
}

export default FullheightWithTilesLayout;
