import React from 'react';

import styles from './SurveyResultsLoader.module.scss';

function SurveyResultsLoader () {
  return (
    <div className={styles.rootHolder}></div>
  );
}

export default SurveyResultsLoader;
