import React, { useState, useRef, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button, Dialog } from 'element-react';

import styles from './AddClinicDialog.module.scss';
import customComponentsStyles from 'styles/custom-components.module.scss';

AddClinicDialog.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  setIsVisible: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const initialFormState = {
  name: '',
};

function reducer(state, action) {
  switch (action.type) {
    case 'setName':
      return {...state, name: action.payload};
    default:
      throw new Error();
  }
}

function AddClinicDialog ({isVisible, setIsVisible, onSubmit}) {
  const { t } = useTranslation();
  const formRef = useRef(null);

  const [state, dispatchToLocalState] = useReducer(reducer, initialFormState);

  const [nameIsValid, setNameIsValid] = useState(false);

  const validationRules = {
    name: [
      {
        required: true,
        message: t('Clinics:AddClinicDialog.dialog.validation.warningMessage.clinicIsEmpty'),
        trigger: 'blur' },
      { 
        validator: (rule, value, callback) => {
          if (value === '') {
            setNameIsValid(false);
            callback(new Error(
              t('Clinics:AddClinicDialog.dialog.validation.errorMessage.clinicIsEmpty')
            ));
          } else {
            setNameIsValid(true);
            callback();
          }
        } 
      }
    ],
  };

  const onNameChange = value => {
    dispatchToLocalState({type: 'setName', payload: value});
  };

  const clearFormFields = () => {
    dispatchToLocalState({type: 'setName', payload: ''});
  };

  const onSave = () => {
    formRef.current.validate((valid) => {
      if (valid) {
        onSubmit({
          name: state.name,
        });

        formRef.current.resetFields();
        clearFormFields(); // it seems that resetFields() can't handle state created by useReducer
      } else {
        console.log('submit error!');
        return false;
      }
    });

    setIsVisible(false);
  };

  const onCancel = () => {
    formRef.current.resetFields();
    clearFormFields(); // it seems that resetFields() can't handle state created by useReducer

    setIsVisible(false);
  };

  return (
    <Dialog
      title={
        <span className={styles.dialogHeading}>
          {t('Clinics:AddClinicDialog.dialog.heading')}
        </span>
      }
      size="tiny"
      visible={ isVisible }
      onCancel={ () => {setIsVisible(false);} }
      lockScroll={ false }
      closeOnClickModal={false}
    >
      <Dialog.Body>
        <div className={styles.subheading}>{t('Clinics:AddClinicDialog.dialog.subheading')}</div>

        <Form ref={formRef} rules={validationRules} model={state}>
          <Form.Item prop="name">
            <Input
              type="text"
              placeholder={t('Clinics:AddClinicDialog.dialog.form.input.name.placeholder')}
              value={state.name}
              onChange={onNameChange}
              autoComplete="off"
            />
          </Form.Item>
        </Form>
      </Dialog.Body>
      <Dialog.Footer className="dialog-footer">
        <Button
          onClick={onSave}
          disabled={!nameIsValid}
          className={customComponentsStyles.buttonCustom}
        >
          {t('Clinics:AddClinicDialog.dialog.button.save')}
        </Button>
        <Button 
          onClick={onCancel}
          className={customComponentsStyles.buttonCustom}
        >{t('Clinics:AddClinicDialog.dialog.button.cancel')}</Button>
      </Dialog.Footer>
    </Dialog>
  );
}

export default AddClinicDialog;
