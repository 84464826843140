import { ROUTES } from "constants/routes";
import { USER_ROLES } from "constants/userRoles";

export const DEFAULT_PATHS_MAP = {
    [USER_ROLES.superadmin]: {
        redirectPath: `${ROUTES.SUPERADMIN_RESULTS.path}`,
    },
    [USER_ROLES.admin]: {
        redirectPath: `${ROUTES.ADMIN_DOCTORS_LIST.path}`,
    },
    [USER_ROLES.doctor]: {
        redirectPath: `${ROUTES.DOCTOR_USERS_LIST.path}`,
    },
    [USER_ROLES.patient]: {
        redirectPath: `${ROUTES.PATIENT_RESULTS.path}`,
    },
};
