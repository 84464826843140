import React, { useState } from 'react';
import { Radio, Input, Card } from 'element-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TEXT_INPUT_MAX_LENGTH } from 'constants/inputs';
import { SURVEY_QUESTION_TYPE } from 'constants/survey';

import styles from './AuxSurveyQuestion.module.scss';
import customComponentsStyles from 'styles/custom-components.module.scss';

AuxSurveyQuestion.propTypes = {
  type: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  possibleAnswers: PropTypes.array.isRequired,
  onResponse: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

function AuxSurveyQuestion ({
  type: currentQuestionType,
  question,
  id: currentQuestionId,
  possibleAnswers,
  onResponse,
  isDisabled,
}) {
  const { t } = useTranslation();

  const [answerText, setAnswerText] = useState(null);
  const [answerRadio, setAnswerRadio] = useState(null);

  const onRadioResponse = (checkedValue) => {
    if (currentQuestionType === SURVEY_QUESTION_TYPE.openEnded && possibleAnswers.length !== 0) { // combined question case
      setAnswerText('');
    }
    setAnswerRadio(checkedValue);
    onResponse(currentQuestionId, currentQuestionType, 'radio', checkedValue);
  };

  const onTextResponse = (checkedValue) => {
    if (currentQuestionType === SURVEY_QUESTION_TYPE.openEnded && possibleAnswers.length !== 0) { // combined question case
      setAnswerRadio('');
    }
    setAnswerText(checkedValue);
    onResponse(currentQuestionId, currentQuestionType, 'text', checkedValue);
  };

  const renderPossibleAnswers = () => {
    return (
      <Radio.Group
        onChange={onRadioResponse}
      >
        {
          possibleAnswers.map((item) => {
            return (
              <Radio
                key={item.id}
                value={item.id}
                className={customComponentsStyles.inputRadioCustom}
                checked={item.id === answerRadio}
                disabled={isDisabled}
              >
                {item.answer}
              </Radio>
            );
          })
        }
      </Radio.Group>
    );
  };

  return (
    <Card className={styles.rootWrapper}>
      <h2 className={styles.heading}>{question}</h2>
      <div className={styles.answersHolder}>
        { renderPossibleAnswers() }
        {currentQuestionType === SURVEY_QUESTION_TYPE.openEnded
          ? <Input
              placeholder={t('Surveys:AuxSurveyQuestion.form.input.common.placeholder')}
              value={answerText}
              onChange={onTextResponse}
              disabled={isDisabled}
              maxLength={TEXT_INPUT_MAX_LENGTH}
            />
          : null}
      </div>
    </Card>
  );
}

export default AuxSurveyQuestion;
