import React, { useReducer, useRef, useState } from "react";
import { Form, Input, Button } from "element-react";
import { useTranslation } from "react-i18next";
import {
    checkIfHasUpperCase,
    checkIfHasLowerCase,
    checkIfHasNumber,
    checkIfHasSpecialCharacter,
} from "helpers/helpers";
import styles from "./ResetPasswordView.module.scss";
import PropTypes from "prop-types";
import customComponentsStyles from "styles/custom-components.module.scss";
import { useDispatch } from "react-redux";
import { api, resetPassword } from "services/api/ApiService";
import * as authActions from "store/auth/actions";
import NotificationsService from "services/notifications/NotificationsService";

const initialFormState = {
    error: "",
    newPassword: "",
    newPasswordConfirm: "",
};

function reducer(state, action) {
    switch (action.type) {
        case "setError":
            return { ...state, error: action.payload };
        case "setNewPassword":
            return { ...state, newPassword: action.payload };
        case "setNewPasswordConfirm":
            return { ...state, newPasswordConfirm: action.payload };
        default:
            throw new Error();
    }
}

ResetPasswordView.propTypes = {
    match: PropTypes.object.isRequired,
};

function ResetPasswordView({ match: { params } }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const formRef = useRef(null);

    const [submitButtonIsEnabled, setSubmitButtonIsEnabled] = useState(false);
    const [state, dispatchToLocalState] = useReducer(reducer, initialFormState);

    const validationRules = {
        newPassword: [
            {
                required: true,
                message: t("Auth:SignIn.form.validation.warningMessage.passwordIsEmpty"),
                trigger: "blur",
            },
            {
                validator: (rule, value, callback) => {
                    if (
                        value.length < 8 ||
                        !checkIfHasNumber(value) ||
                        !checkIfHasUpperCase(value) ||
                        !checkIfHasLowerCase(value) ||
                        !checkIfHasSpecialCharacter(value)
                    ) {
                        setSubmitButtonIsEnabled(false);
                        callback(
                            new Error(
                                t("Auth:SignIn.form.validation.errorMessage.passwordIsWrong")
                            )
                        );
                    } else {
                        setSubmitButtonIsEnabled(true);
                        callback();
                    }
                },
            },
        ],
        newPasswordConfirm: [
            {
                required: true,
                message: t("Auth:SignIn.form.validation.warningMessage.passwordIsEmpty"),
                trigger: "blur",
            },
            {
                validator: (rule, value, callback) => {
                    if (value !== state.newPassword) {
                        setSubmitButtonIsEnabled(false);
                        callback(
                            new Error(
                                t(
                                    "Auth:ForgotPasswordSubmit.form.validation.errorMessage.notEqual"
                                )
                            )
                        );
                    } else {
                        setSubmitButtonIsEnabled(true);
                        callback();
                    }
                },
            },
        ],
    };
    const onNewPasswordChange = (value) => {
        dispatchToLocalState({ type: "setNewPassword", payload: value });
    };

    const onNewPasswordConfirmChange = (value) => {
        dispatchToLocalState({ type: "setNewPasswordConfirm", payload: value });
    };

    const clearFormFields = () => {
        dispatchToLocalState({ type: "setNewPassword", payload: "" });
        dispatchToLocalState({ type: "setNewPasswordConfirm", payload: "" });
    };

    const onSubmit = (e) => {
        e.preventDefault();

        formRef.current.validate(async (valid) => {
            if (valid && params?.token) {
                try {
                    api.deleteToken();
                    const { email } = await resetPassword({
                        token: params.token,
                        newPassword: state.newPassword,
                    });
                    NotificationsService.throwNotification(
                        "success",
                        t("Auth:SignIn.form.resetPassword.success"),
                        ""
                    );

                    dispatch(
                        authActions.signIn.start({
                            userName: email,
                            password: state.newPassword,
                        })
                    );
                } catch (e) {
                    dispatchToLocalState({ type: "setError", payload: e.response.data.error });
                }

                clearFormFields();
            } else {
                console.log("submit error!");
                return false;
            }
        });
    };

    return (
        <>
            <div className={styles.card}>
                <div className={styles.title}>{t("Auth:ResetPassword.title")}</div>
                <Form
                    ref={formRef}
                    rules={validationRules}
                    model={state}
                    className={styles.form}
                >
                    <Form.Item prop="newPassword">
                        <Input
                            type="password"
                            placeholder={t(
                                "Auth:ForgotPasswordSubmit.form.input.newPassword.placeholder"
                            )}
                            value={state.newPassword}
                            onChange={onNewPasswordChange}
                            autoComplete="off"
                        />
                    </Form.Item>
                    <Form.Item prop="newPasswordConfirm">
                        <Input
                            type="password"
                            placeholder={t(
                                "Auth:ForgotPasswordSubmit.form.input.confirmNewPassword.placeholder"
                            )}
                            value={state.newPasswordConfirm}
                            onChange={onNewPasswordConfirmChange}
                            autoComplete="off"
                        />
                    </Form.Item>
                    {state.error.length > 0 && (
                        <div className={styles.error}>{state.error}</div>
                    )}

                    <Button
                        onClick={onSubmit}
                        style={{ width: "100%" }}
                        disabled={
                            !submitButtonIsEnabled ||
                            state.newPassword !== state.newPasswordConfirm
                        }
                        className={customComponentsStyles.buttonCustom}
                    >
                        {t("Auth:ForgotPasswordSubmit.form.button.changePassword")}
                    </Button>
                </Form>
            </div>
        </>
    );
}

export default ResetPasswordView;
