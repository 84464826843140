import Cookies from 'universal-cookie';

import { COOKIES_NAMES } from 'constants/cookies';
import { FONT_SIZES } from 'constants/ui';

import LanguageService from 'services/language/LanguageService';

const cookies = new Cookies();

const options = { path: '/' };

const CookiesService = {
  getCookie(name) {
    return cookies.get(name, options);
  },

  getAllCookies() {
    return cookies.getAll(options);
  },

  isCookiesAllowedByUser() {
    const cookieConsentValue = this.getCookie(COOKIES_NAMES.COOKIE_CONSENT);
    const isAllowedByUser = (cookieConsentValue === 'true'); // value is in the string type, so we can't check it implicitly
    return isAllowedByUser;
  },

  setCookie(name, value) {
    if(this.isCookiesAllowedByUser()) {
      cookies.set(name, value, options);
    }
  },

  removeCookie(name) {
    cookies.remove(name, options);
  },

  removeAllCookies() {
    const cookies = this.getAllCookies();

    if(cookies) {
      for (const cookieName in cookies) {
        this.removeCookie(cookieName);
      }
    }
  },

  setConsentCookie(value) {
    cookies.set(COOKIES_NAMES.COOKIE_CONSENT, value, options); // should be used directly from Cookies instance to avoid isCookiesAllowedByUser check
  },

  setLanguageCookie(languageCode) {
    this.setCookie(COOKIES_NAMES.LANGUAGE, languageCode);
  },

  getUiSizeCookie() {
    return this.getCookie(COOKIES_NAMES.UI_SIZE);
  },

  setUiSizeCookie(value) {
    this.setCookie(COOKIES_NAMES.UI_SIZE, value);
  },

  setInitialValues() {
    this.setLanguageCookie(LanguageService.getCurrentLanguage());
    this.setUiSizeCookie(FONT_SIZES.MEDIUM);
  }
};

export default CookiesService;
