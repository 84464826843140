import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog } from 'element-react';
import { useSelector, useDispatch } from 'react-redux';

import * as uiActions from 'store/ui/actions';

import customComponentsStyles from 'styles/custom-components.module.scss';
import styles from './NotificationModal.module.scss';

function NotificationModal () {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isVisible = useSelector(state => state?.ui?.notificationModal?.isVisible) ?? false;
  const modalHeading = useSelector(state => state?.ui?.notificationModal?.heading) ?? '';
  const modalContent = useSelector(state => state?.ui?.notificationModal?.content) ?? [];

  const onClose = () => {
    dispatch(uiActions.notificationModal.hide());
  };

  const renderContent = () => {
    return(
      <div>
        {
          modalContent.map((element, index) => {
            return(
              <div key={index} className={styles.contentString}>{String(element)}</div>
            );
          })
        }
      </div>
    );
  };

  return (
    <Dialog
      title={
        <span className={styles.dialogHeading}>
          {modalHeading}
        </span>
      }
      size="large"
      visible={ isVisible }
      onCancel={onClose}
      lockScroll={ false }
      closeOnClickModal={false}
    >
      <Dialog.Body>
        {renderContent()}
      </Dialog.Body>
      <Dialog.Footer className="dialog-footer">
        <Button
          onClick={onClose}
          className={customComponentsStyles.buttonCustom}
        >
          Close
          {/* {t('Auth:MassSignUpDialog.dialog.button.cancel')} */}
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
}

export default NotificationModal;
