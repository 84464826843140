import { API, getQueryString } from "./apiTools";

// eslint-disable-next-line no-undef
const DOMAIN = process.env.REACT_APP_DOMAIN;

export const api = new API(DOMAIN);

export const authSignIn = (credentials) => api.post("auth/sign-in", credentials);

export const patientAutoLogin = (credentials) => api.post("auth/jwt", credentials);

export const adminsListGet = (pageSize, pageNumber) =>
    api.get(`admin?size=${pageSize}&page=${pageNumber}`);

export const adminGet = (email) => api.get(`admin/${email}`);

export const adminCreate = (data) => api.post("admin", data);

export const doctorsListGet = (pageSize, pageNumber) =>
    api.get(`doctor?size=${pageSize}&page=${pageNumber}`);

export const doctorGet = (email) => api.get(`doctor/${email}`);

export const doctorCreate = (data) => api.post("doctor", data);

export const clinicsListGet = (pageSize, pageNumber) =>
    api.get(`clinic?size=${pageSize}&page=${pageNumber}`);

export const clinicGet = (clinicId) => api.get(`clinic/${clinicId}`);

export const clinicCreate = (data) => api.post("clinic", data);

export const patientsListGet = (params) => {
    const queryString = getQueryString(params);
    return api.get(`user${queryString}`);
};

export const patientGet = (email) => api.get(`user/${email}`);

export const patientCreate = (data) => api.post("user", data);

export const patientsMassImport = (fileData, doctorEmail) =>
    api.post(`user/bulkSignup`, fileData, {
        params: {
            doctor: doctorEmail,
        },
    });

export const surveysListGet = (getParams = "") => api.get("survey" + getParams);

export const surveyGet = (id) => api.get(`survey/${id}`);

export const surveyInstanceCreate = (data) =>
    api.post("surveyInstance", {
        doctorEmail: data.doctorEmail,
        userEmail: data.patientEmail,
        surveyId: data.surveyId,
    });

export const surveyInstancesListGet = (email, size = 200) =>
    api.get(`surveyInstance?userEmail=${email}&size=${size}&sort=createTimestamp,desc`);

export const surveyInstanceGet = (id) => api.get(`surveyInstance/${id}`);

export const surveyInstanceUpdate = (id, data) => api.put(`surveyInstance/${id}`, data);

export const surveyInstanceRecommendationsUpdate = (id, data) =>
    api.put(`surveyInstance/${id}/recommendations`, data);

export const surveyInstanceConclusionUpdate = (id, data) =>
    api.put(`surveyInstance/${id}/conclusion`, data);

export const surveyInstanceSubmit = (id, stage) =>
    api.post(`surveyInstance/${id}/submit?stage=${stage}`);

export const surveysInstancesExport = () =>
    api.get("surveyInstance/csv", {
        responseType: "blob",
        timeout: 30000,
    });

export const oneTimeSurveyInstanceCreate = (surveyId, firstName, lastName) =>
    api.post(
        `/surveyInstance/singleBurn?surveyId=${surveyId}`
        // , {
        //   firstName: firstName,
        //   lastName: lastName
        // }
    );

export const auxSurveyInstanceCreate = (data) => api.post("demographicInstance", data);

export const noteCreate = (data) => api.post(`note`, data);

export const notesListGet = () => api.get(`note?size=200`);

export const noteGet = (id) => api.get(`note/${id}`);

export const noteUpdate = (id) => api.put(`note/${id}`);

export const noteDelete = (id) => api.delete(`note/${id}`);

export const licenseAcceptDoctor = () => api.put(`doctor/license`);

export const licenseAcceptPatient = () => api.put(`user/license`);

export const privacyPolicyAcceptPatient = () => api.put(`user/privacy`);

export const accountDataGet = () => api.get(`user/this`);

export const setUserLocale = (locale) => api.post(`user/this/locale`, { locale });

export const getDoctorResources = () => api.get(`resources/education`);

export const getDoctorResource = (url) => api.get(url);

export const getPatientResources = () => api.get(`resources/education/recommended`);

export const forgotPassword = (data) => api.post(`auth/password/forgot`, data);
export const resetPassword = (data) => api.post(`auth/password/reset`, data);

export const getQrCodeImage = () =>
    api.get(`doctor/qr-code`, {
        responseType: "blob",
        timeout: 30000,
    });

export const userRestrictedSurveyBound = (data) =>
    api.post(`auth/user-restricted-survey-bound`, data);
