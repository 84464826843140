import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './PHSurveysListIsEmpty.module.scss';

function PHSurveysListIsEmpty () {
  const { t } = useTranslation();

  return (
    <div className={styles.rootWrapper}>
      {t('Surveys:PHSurveysListIsEmpty.placeholderContent')}
    </div>
  );
}

export default PHSurveysListIsEmpty;
