import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dialog, Input } from 'element-react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { datesFormat } from 'constants/datesAndTime';
import { maxNoteTextLength, maxNotePreviewTextLength } from 'constants/notes';

import { truncate } from 'helpers/helpers';

import * as notesActions from 'store/notes/actions';

import Loader from 'componentsShared/Loader/Loader';

import customComponentsStyles from 'styles/custom-components.module.scss';
import styles from 'сomponents/PatientNotesList/PatientNotesList.module.scss';

function PatientNotesList () {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const [notesContent, setNotes] = useState(false);
  const [noteCreateDialigIsVisible, setNoteCreateDialigIsVisible] = useState(false);
  const [noteCreateInputText, setNoteCreateInputText] = useState('');

  const notesListData = useSelector(state => state.notes.notesList.data);
  const notesListDataSequence = useSelector(state => state.notes.notesList.dataSequence);
  const isLoadingGetList = useSelector(state => state.notes.isLoadingGetList);

  useEffect(() => {
    dispatch(notesActions.getNotesList.start());
  }, []);

  // useEffect(() => {
  //   console.log( 'upd' );
  //   console.log( noteCreateInputText );
    
    
  // }, [noteCreateInputText]);

  // const hendleClickNotes = () => {
  //   setNotes(true);
  // };

  // const hendleClickCloseNotes = () => {
  //   setNotes(false);
  // };

  const onDialogSubmit = () => {
    dispatch(notesActions.createNote.start({ content: noteCreateInputText }));
    setNoteCreateDialigIsVisible(false);
    setNoteCreateInputText('');
  };

  const onDialogCancel = () => {
    setNoteCreateDialigIsVisible(false);
    setNoteCreateInputText('');
  };

  const renderNotesList = () => {
    return (
      <ul className={styles.notesList}>
        {
          notesListDataSequence.map((key) => {
            const notesItem = notesListData[key];

            return (
              <li
                className={styles.noteListItem}
                key={notesItem.id}
                // onClick={hendleClickNotes}
              >
                <h2 className={styles.cardNotesTitle}>
                  {moment(notesItem.createTimestamp).format(datesFormat)}
                </h2>
                <div className={styles.cardNotesDescription}>
                  {truncate(notesItem.text, maxNotePreviewTextLength)}
                </div>
              </li>
            );
          })
        }
      </ul>
    );
  };

  // const renderNoteBlock = () => {
  //   return (
  //     <Fragment>
  //       <div className={styles.notesBlockHeader}>
  //         <div className="">08.03.2021</div>
  //         <span className={styles.closeNotes} onClick={hendleClickCloseNotes}>x</span>
  //       </div>
  //       <div className={styles.notesBlockContent}>
  //         <h1>Taking my dog for a walk is fun.</h1>
  //         <div className="">
  //           Lorem ipsum dolor sit amet consectetur adipisicing elit. Beatae amet eum dolorem soluta quasi nulla quae ratione id eius quod dolore nobis cum impedit sit neque, odit, cumque doloremque vel!
  //         </div>
  //       </div>
  //     </Fragment>
  //   );
  // };

  const renderNoteCreateDialog = () => {
    return (
      <Dialog
        title={t('Notes:PatientNotesList.dialog.heading')}
        size="tiny"
        visible={ noteCreateDialigIsVisible }
        onCancel={ () => setNoteCreateDialigIsVisible(false) }
        lockScroll={ false }
        closeOnClickModal={ false }
      >
        <Dialog.Body>
          <Input
            type="textarea"
            autosize={{ minRows: 2, maxRows: 5}}
            placeholder={t('Notes:PatientNotesList.dialog.form.input.note.placeholder')}
            onChange={setNoteCreateInputText}
            value={noteCreateInputText}
            maxLength={maxNoteTextLength}
            minLength={1}
          />
        </Dialog.Body>
        <Dialog.Footer className="dialog-footer">
          <Button 
            onClick={onDialogCancel}
            className={customComponentsStyles.buttonCustom}
          >
            {t('Notes:PatientNotesList.dialog.button.cancel')}
          </Button>
          <Button 
            onClick={ onDialogSubmit }
            className={customComponentsStyles.buttonCustom}
          >
            {t('Notes:PatientNotesList.dialog.button.confirm')}
          </Button>
        </Dialog.Footer>
      </Dialog>
    );
  };

  return (
    <div className={styles.rootWrapper}>
      <div className={styles.header}>
        <Button
          onClick={() => setNoteCreateDialigIsVisible(true)}
          className={customComponentsStyles.buttonCustom}
        >
          {t('Notes:PatientNotesList.button.createNote')}
        </Button>
      </div>
      <div className={styles.notesHolder}>
        <div
          className={styles.notesBlockListHolderFull}
          // className={notesContent ? styles.notesBlockListHolder : styles.notesBlockListHolderFull}
        >
          { renderNotesList() }
        </div>
        {/* <div className={notesContent ? styles.notesBlockActive : styles.notesBlockInactive}>
          { renderNoteBlock() }
        </div> */}
      </div>
      { renderNoteCreateDialog() }
      <Loader isVisible={isLoadingGetList}/>
    </div>
  );
}

export default PatientNotesList;
