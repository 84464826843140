import React from 'react';
import { useTranslation } from 'react-i18next';

import { USER_ROLES } from 'constants/userRoles';

import ContentWithHeaderLayout from 'layouts/pageContentLayouts/ContentWithHeaderLayout/ContentWithHeaderLayout';
import ListTablePageLayout from 'layouts/pageContentLayouts/ListTablePageLayout/ListTablePageLayout';

import AddUser from 'сomponents/AddUser/AddUser';
import ListTableDoctors from 'сomponents/ListTableDoctors/ListTableDoctors';

function SuperadminDoctorsListView() {
  const { t } = useTranslation();

  return (
    <ContentWithHeaderLayout
      padding
      headerTitle={t('Doctors:SuperadminDoctorsListView.header.heading')}
    >
      <ListTablePageLayout
        headerContent={
          <AddUser userTypeToAdd={USER_ROLES.doctor}/>
        }
        mainContent={
          <ListTableDoctors />
        }
      />
    </ContentWithHeaderLayout>
  );
}

export default SuperadminDoctorsListView;
