import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";

import { ROUTES } from "constants/routes";
import { DEFAULT_PATHS_MAP } from "constants/defaultPathsMap";

import UserService from "services/user/UserService";

ProtectedRoute.propTypes = {
    component: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
};

function checkUserStatus(path) {
    const formattedPath = path.substring(1);
    let redirectPath = null;

    if (
        UserService.isUserStatusTermsAndConditions() &&
        formattedPath !== ROUTES.TERMS_AND_CONDITIONS.path
    ) {
        redirectPath = ROUTES.TERMS_AND_CONDITIONS.path;
    } else if (
        UserService.isUserStatusPrivacyPolicy() &&
        formattedPath !== ROUTES.DOCS_PRIVACY_POLICY.path
    ) {
        redirectPath = ROUTES.DOCS_PRIVACY_POLICY.path;
    } else if (
        UserService.isUserStatusDemographics() &&
        formattedPath !== ROUTES.AUX_SURVEY.path &&
        !UserService.isUserStatusTermsAndConditions() &&
        !UserService.isUserStatusPrivacyPolicy()
    ) {
        redirectPath = ROUTES.AUX_SURVEY.path;
    }

    return redirectPath;
}

function ProtectedRoute({ component: Component, path, ...rest }) {
    const isAuthorized = UserService.isAuthorized();
    const currentUserRole = UserService.getCurrentUserRole();

    const route =
        Object.values(ROUTES).find((element) => element.path === path.substring(1)) || {};

    const { roles = [] } = route; // TODO: rename roles to allowedRoles (in routes as well)
    const hasAccessToRoute = roles.includes(currentUserRole);
    const authorized = isAuthorized && hasAccessToRoute;

    const redirectPathForUnauthorizedUsers = ROUTES.ROLE_SELECT.path;

    let { redirectPath } = DEFAULT_PATHS_MAP[currentUserRole] || {};

    if (!authorized) {
        // unauthorized case
        redirectPath = redirectPathForUnauthorizedUsers;
    }

    const userStatusRedirectPath = checkUserStatus(path);

    const pathsExclusions = [
        // ! Paths allowed for unauthorized users and forbidden for authorized users
        `/${ROUTES.ROLE_SELECT.path}`,
        `/${ROUTES.SIGN_IN_SUPERADMIN.path}`,
        `/${ROUTES.SIGN_IN_ADMIN.path}`,
        `/${ROUTES.REGISTRATION_ADMIN.path}`,
        `/${ROUTES.REGISTRATION_DOCTOR.path}`,
        `/${ROUTES.REGISTRATION_PATIENT.path}`,
        `/${ROUTES.FORGOT_PASSWORD.path}`,
        `/${ROUTES.RESET_PASSWORD.path}`,
        `/${ROUTES.CHANGE_PASSWORD.path}`,
    ];

    let shouldBeRedirected = false;

    // if (authorized && pathsExclusions.includes(path)) { // * Forbidden paths for authorized users
    //   shouldBeRedirected = true;
    // }

    // if (!authorized && !pathsExclusions.includes(path)) { // * Forbidden paths for unauthorized users
    //   shouldBeRedirected = true;
    // }

    let redirectTo = false;

    if (!isAuthorized && !pathsExclusions.includes(path)) {
        redirectTo = `${ROUTES.ROLE_SELECT.path}`;
    } else if (isAuthorized && userStatusRedirectPath !== null) {
        // status checks redirects
        redirectTo = `/${userStatusRedirectPath}`;
    } else if (shouldBeRedirected) {
        // automatic redirects case - highest priority
        redirectTo = redirectPath;
    }

    return (
        <Route
            {...rest}
            path={path}
            render={() => {
                if (redirectTo) {
                    return <Redirect to={redirectTo} />;
                } else {
                    return <Component {...rest} />;
                }
            }}
        />
    );
}

export default ProtectedRoute;
