import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Button, Popover, Radio } from "element-react";
import { useTranslation } from "react-i18next";

import * as surveysActions from "store/surveys/actions";

import customComponentsStyles from "styles/custom-components.module.scss";
import styles from "./NewSurvey.module.scss";

NewSurvey.propTypes = {
    onSurveyTypeSelect: PropTypes.func.isRequired,
    triggerButtonCaption: PropTypes.string.isRequired,
    viewType: PropTypes.string,
    selectedSurveyType: PropTypes.number,
};

function NewSurvey({
    onSurveyTypeSelect,
    triggerButtonCaption,
    viewType,
    selectedSurveyType,
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const surveysListData = useSelector((state) => state.surveys.surveysList.data);
    const surveysListDataSequence = useSelector(
        (state) => state.surveys.surveysList.dataSequence
    );

    const [newSurveyPopupIsVisible, setNewSurveyPopupIsVisible] = useState(false);

    useEffect(() => {
        dispatch(surveysActions.getSurveysList.start());
    }, []);

    const onCreateSurvey = (surveyTypeId) => () => {
        onSurveyTypeSelect(surveyTypeId);
        setNewSurveyPopupIsVisible(false);
    };

    const onPopupToggle = () => {
        setNewSurveyPopupIsVisible(true);
    };

    const renderSurveysTypesList = () => {
        return (
            <ul className={styles.popoverList}>
                {surveysListDataSequence.map((key) => {
                    if (!surveysListData[key]?.isAvailable) {
                        return null;
                    }
                    return (
                        <li key={key} className={styles.popoverListItem}>
                            <button
                                className={styles.popoverItemButton}
                                onClick={onCreateSurvey(surveysListData[key].id)}
                            >
                                <span title={`rev. ${surveysListData[key]?.revision}`}>
                                    {surveysListData[key]?.name ||
                                        t("Surveys:NewSurvey.fallback.titleIsMissing")}
                                </span>
                            </button>
                        </li>
                    );
                })}
            </ul>
        );
    };

    const renderSurveysTypesRadioList = () => {
        return (
            <Radio.Group
                className={styles.radioGroup}
                value={selectedSurveyType}
                onChange={onSurveyTypeSelect}
            >
                {surveysListDataSequence.map((key) => {
                    const surveyId = surveysListData[key].id;

                    return (
                        <Radio
                            key={key}
                            value={surveyId}
                            // checked={selectedSurveyType === surveyId}
                            // onChange={onSurveyTypeSelect(surveyId)}
                        >
                            {surveysListData[key]?.name ||
                                t("Surveys:NewSurvey.fallback.titleIsMissing")}
                        </Radio>
                    );
                })}
            </Radio.Group>
        );
    };

    return (
        <Fragment>
            {viewType === "radio" ? (
                renderSurveysTypesRadioList()
            ) : (
                <Popover
                    className={customComponentsStyles.customPopover}
                    placement="bottom-end"
                    width="200"
                    trigger="click"
                    visible={newSurveyPopupIsVisible}
                    content={renderSurveysTypesList()}
                >
                    <Button
                        className={customComponentsStyles.buttonCustom}
                        onClick={onPopupToggle}
                    >
                        {triggerButtonCaption}
                    </Button>
                </Popover>
            )}
        </Fragment>
    );
}

export default NewSurvey;
