import axios from "axios";

import { ROUTES } from "constants/routes";

import LocalStorageService from "services/localStorage/LocalStorageService";
import notificationsService from "services/notifications/NotificationsService";
import NavigationService from "services/navigation/NavigationService";
import LanguageService from "services/language/LanguageService";

export function ejectErrorMessage(error) {
    const status = error?.response?.status ?? "Unknown";

    return error?.response?.data?.message ?? `Error code #${status}`;
}

export function handleRequestError(error) {
    if (error?.request?.status === 401) {
        return;
    }

    notificationsService.throwNotification(
        "error",
        LanguageService.t("UI:Error.RequestError"),
        ejectErrorMessage(error)
    );
}

export const getQueryString = (data = {}, withEmptyParams = false) => {
    const paramsKeys = Object.keys(data);
    let res = "";
    if (paramsKeys.length === 0) {
        return res;
    }

    paramsKeys.forEach((item, index) => {
        if (!withEmptyParams && data[item] === "") {
            return;
        }
        res += `${index === 0 ? "?" : "&"}${item}=${data[item]}`;
        return;
    });

    return res;
};

export class API {
    constructor(domain, options = {}) {
        this.axios = axios.create({
            baseURL: domain,
            ...options,
        });

        const { errorResponseInterceptor } = this;

        this.axios.interceptors.response.use(null, errorResponseInterceptor.bind(this));

        const createMethod = (method) => {
            this[method] = this._createRequest(method);
        };

        ["get", "post", "patch", "put", "delete"].forEach(createMethod);
    }

    _createRequest(method) {
        return async function (url, body, config) {
            const response = await this.axios[method](url, body, config);
            return (response && response.data) || null;
        };
    }

    errorResponseInterceptor(error) {
        const { response = {} } = error;
        const { status } = response;

        // Romove access token from localStorage if user is unauthorized or token is expired
        if (status === 401) {
            this.deleteToken();
            LocalStorageService.clear("apiKey");
            NavigationService.navigateToPath(ROUTES.SIGN_OUT.path);
        }

        return Promise.reject(error);
    }

    saveToken(token) {
        this.axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }

    deleteToken() {
        this.axios.defaults.headers.common["Authorization"] = "";
    }
}
