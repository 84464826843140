export const checkIfHasUpperCase = (string) => {
  return (/[A-Z]/.test(string));
};

export const checkIfHasLowerCase = (string) => {
  return (/[a-z]/.test(string));
};

export const checkIfHasNumber = (string) => {
  return /\d/.test(string);
};

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  
  return re.test(String(email).toLowerCase());
};

export const checkIfHasSpecialCharacter = (string) => {
  // eslint-disable-next-line no-useless-escape
  const re = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return re.test(string);
};

export const truncate = (input, maxValue) => input.length > maxValue ? `${input.substring(0, maxValue)}...` : input;
