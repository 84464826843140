import { handleActions } from "redux-actions";

import * as actions from "./actions";

export const initialState = {
    isLoadingCreateClinic: false,
    isLoadingGetClinic: false,
    isLoadingGetClinicsList: false,
    clinicsList: {
        data: {},
        dataSequence: [],
        meta: {},
    },
    selectedClinicProfile: {},
};

export const clinicsReducer = handleActions(
    {
        [actions.createClinic.start]: (state) => ({
            ...state,
            isLoadingCreateClinic: true,
        }),
        [actions.createClinic.success]: (state) => ({
            ...state,
            isLoadingCreateClinic: false,
        }),
        [actions.createClinic.failure]: (state) => ({
            ...state,
            isLoadingCreateClinic: false,
        }),

        [actions.getClinic.start]: (state) => ({
            ...state,
            isLoadingGetClinic: true,
        }),
        [actions.getClinic.success]: (state, { payload }) => ({
            ...state,
            isLoadingGetClinic: false,
            selectedClinicProfile: {
                ...state.selectedClinicProfile,
                id: payload.id,
                name: payload.name,
                locale: payload.locale,
            },
        }),
        [actions.getClinic.failure]: (state) => ({
            ...state,
            isLoadingGetClinic: false,
        }),

        [actions.getClinicsList.start]: (state) => ({
            ...state,
            isLoadingGetClinicsList: true,
        }),
        [actions.getClinicsList.success]: (state, { payload }) => ({
            ...state,
            isLoadingGetClinicsList: false,
            clinicsList: {
                ...state.clinicsList,
                data: payload.data,
                dataSequence: payload.dataSequence,
                meta: payload.meta,
            },
        }),
        [actions.getClinicsList.failure]: (state) => ({
            ...state,
            isLoadingGetClinicsList: false,
        }),
    },
    initialState
);
