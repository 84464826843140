import { createActions } from 'redux-actions';

export const { createAdmin, getAdmin, getAdminsList } = createActions(
  {
    CREATE_ADMIN: {
      START: ({ clinicId, email, firstName, lastName }) => ({
        clinicId,
        email,
        firstName,
        lastName,
      }),
      SUCCESS: undefined,
      FAILURE: undefined,
    },
    GET_ADMIN: {
      START: ({ email }) => ({ email }),
      SUCCESS: ({
        email,
        firstName,
        lastName,
        clinic,
      }) => ({
        email,
        firstName,
        lastName,
        clinic,
      }),
      FAILURE: undefined,
    },
    GET_ADMINS_LIST: {
      START: ({
        pageSize,
        pageNumber,
      }) => ({
        pageSize,
        pageNumber,
      }),
      SUCCESS: undefined,
      FAILURE: undefined,
    },
  },
  { prefix: 'ADMINS' }
);
