import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import HistoryService from 'services/history/HistoryService';
import UserService from 'services/user/UserService';

import * as surveysActions from 'store/surveys/actions';

import { ROUTES } from 'constants/routes';
import { SURVEY_INSTANCE_STATUSES } from 'constants/surveyInstance';
import { USER_ROLES } from 'constants/userRoles';
import { datesFormat } from 'constants/datesAndTime';

import Loader from 'componentsShared/Loader/Loader';
import SurveysListPlaceholder from 'сomponents/Surveys/SurveysList/SurveysListPlaceholder/SurveysListPlaceholder';

import iconStatusCompleted from 'images/icons/status-completed.svg';
import iconStatusInProgress from 'images/icons/status-in-progress.svg';
import iconStatusNotStarted from 'images/icons/status-not-started.svg';

import styles from './SurveysList.module.scss';

SurveysList.propTypes = {
  location: PropTypes.object.isRequired,
};

function SurveysList ({location}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const accountType = useSelector(state => state.auth.accountType);

  const surveyInstancesListData = useSelector(state => state.surveys.surveyInstancesList.data);
  const surveyInstancesListDataSequence = useSelector(state => state.surveys.surveyInstancesList.dataSequence);

  const isLoadingGetSurveyInstancesList = useSelector(state => state.surveys.isLoadingGetSurveyInstancesList);

  const selectedPatient = useSelector(state => state.patients.selectedPatient);

  const isDoctorSurveyResultsPage = location.pathname.includes(`/${ROUTES.DOCTOR_USERS_SURVEY_RESULTS.path}`);

  useEffect(() => {
    let email = selectedPatient;

    if(UserService.isPatient()) {
      email = UserService.getCurrentUserEmail();
    }
    dispatch(surveysActions.getSurveyInstancesList.start({email}));
  }, []);

  useEffect(() => {
    if (surveyInstancesListDataSequence.length !== 0) {
      if(accountType === USER_ROLES.doctor && isDoctorSurveyResultsPage) {
        HistoryService.push(`/${ROUTES.DOCTOR_USERS_SURVEY_RESULTS.path}/${surveyInstancesListDataSequence[0]}`);
      }

      // if(accountType === USER_ROLES.patient && isDoctorSurveyResultsPage) {
      if(accountType === USER_ROLES.patient) {
        HistoryService.push(`/${ROUTES.PATIENT_RESULTS.path}/${surveyInstancesListDataSequence[0]}`);
      }
    }
  }, [surveyInstancesListDataSequence]);

  const renderSurveysList = () => {
    return (
      <ul className={styles.surveysList} role="menubar">
        {surveyInstancesListDataSequence.map((key, index) => {
          let statusIcon;
          const surveyStatus = get(surveyInstancesListData, `${[key]}.instanceStatus`, null);
          switch (surveyStatus) {
            case SURVEY_INSTANCE_STATUSES.created:
              statusIcon = iconStatusNotStarted;
              break;
            case SURVEY_INSTANCE_STATUSES.inProgress:
              statusIcon = iconStatusInProgress;
              break;
            case SURVEY_INSTANCE_STATUSES.recommendations:
              statusIcon = iconStatusInProgress;
              break;
            case SURVEY_INSTANCE_STATUSES.review:
              statusIcon = iconStatusInProgress;
              break;
            case SURVEY_INSTANCE_STATUSES.completed:
              statusIcon = iconStatusCompleted;
              break;
            default:
              console.log("TODO: Add default icon");
          }

          const instanceId = get(surveyInstancesListData, `${[key]}.instanceId`, null);

          return(
            <li
              className={classNames(styles.surveyItem, {
                [styles.surveyItemActive]: location.pathname.split('/').pop() === instanceId,
              })}
              role="menuitem"
              key={get(surveyInstancesListData, `${[key]}.id`, index)}
              onClick={onSurveyItemClick(instanceId)}
            >
              <div className={styles.surveyDate}>
                {moment(
                  get(surveyInstancesListData, `${[key]}.instanceCreateTimestamp`, '')
                ).format(datesFormat)}
              </div>
              <div className={styles.surveyName}>Survey {get(surveyInstancesListData, `${[key]}.surveyName`, '')}</div>
              <img className={styles.surveyIcon} src={statusIcon} alt="Status icon"/>
            </li>
          );
        })}
      </ul>
    );
  };
  
  const onSurveyItemClick = (instanceId) => () => {
    if(accountType === USER_ROLES.doctor) {
      HistoryService.push(`/${ROUTES.DOCTOR_USERS_SURVEY_RESULTS.path}/${instanceId}`);
    }

    if(accountType === USER_ROLES.patient) {
      HistoryService.push(`/${ROUTES.PATIENT_RESULTS.path}/${instanceId}`);
    }
  };

  return (
    <nav className={styles.surveysNav}>
      {surveyInstancesListDataSequence.length !== 0 && surveyInstancesListData
        ? renderSurveysList()
        : <SurveysListPlaceholder
            message={t('Surveys:SurveysList.placeholder.noSurveys')}
          />}
      <Loader isVisible={isLoadingGetSurveyInstancesList}/>
    </nav>
  );
}

export default withRouter(SurveysList);
