import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Input } from "element-react";
import { useTranslation } from "react-i18next";

import { USER_ROLES } from "constants/userRoles";
import { TEXT_INPUT_MAX_LENGTH } from "constants/inputs";

import customComponentsStyles from "styles/custom-components.module.scss";
import styles from "./Conclusion.module.scss";
import { CARE_PATHWAY_AGREED_FIELD_ID } from "../SurveysResults";

Conclusion.propTypes = {
    setConclusions: PropTypes.func.isRequired,
    conclusions: PropTypes.object.isRequired,
};

function Conclusion({ conclusions, setConclusions }) {
    const { t } = useTranslation();

    const instancePossibleConclusions =
        useSelector((state) => state?.surveys?.surveyInstance?.instancePossibleConclusions) ||
        [];

    const instanceConclusions =
        useSelector((state) => state?.surveys?.surveyInstance?.instanceConclusions) || [];

    const accountType = useSelector((state) => state.auth.accountType);

    const onConclusionUpdate = (id) => (text) => {
        const newConclusions = {
            ...conclusions,
            [id]: {
                id,
                text,
            },
        };

        setConclusions(newConclusions);
    };

    const renderPossibleConclusions = () => {
        return (
            <ul className={styles.conclusionsList}>
                {instancePossibleConclusions.map((item, index) => {
                    const conclusionAnswer = instanceConclusions.find((el) => {
                        return el.conclusionId === item.id;
                    });

                    if (conclusionAnswer) {
                        return (
                            <li key={item?.id || index}>
                                {accountType === USER_ROLES.patient ? (
                                    <Fragment>
                                        <h3 className={styles.patientConclusionQuestion}>
                                            {item?.text ||
                                                t(
                                                    "Surveys:Conclusion.fallback.conclusionError"
                                                )}
                                        </h3>
                                        <div className={styles.patientConclusionAnswer}>
                                            {conclusionAnswer?.text ||
                                                t(
                                                    "Surveys:Conclusion.fallback.conclusionError"
                                                )}
                                        </div>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <h3>{item?.text || ""}</h3>
                                        <Input
                                            className={customComponentsStyles.inputText}
                                            value={
                                                conclusionAnswer?.text ||
                                                t(
                                                    "Surveys:Conclusion.fallback.conclusionError"
                                                )
                                            }
                                            maxLength={TEXT_INPUT_MAX_LENGTH}
                                            disabled
                                            type="textarea"
                                            autosize={{ minRows: 2, maxRows: 4 }}
                                        />
                                    </Fragment>
                                )}
                            </li>
                        );
                    }

                    return (
                        <li key={item?.id || index} className={styles.conclusionsListItem}>
                            <div className={styles.doctorConclusionSection}>
                                <h2 className={styles.doctorConclusionQuestion}>
                                    {item?.text || ""}
                                </h2>
                                <Input
                                    maxLength={TEXT_INPUT_MAX_LENGTH}
                                    className={customComponentsStyles.inputText}
                                    placeholder={item?.text || ""}
                                    onChange={onConclusionUpdate(item?.id || "")}
                                    value={conclusions && conclusions[item.id]?.text}
                                    type="textarea"
                                    autosize={{
                                        minRows:
                                            item.id === CARE_PATHWAY_AGREED_FIELD_ID ? 5 : 1,
                                        maxRows: 5,
                                    }}
                                />
                            </div>
                        </li>
                    );
                })}
            </ul>
        );
    };

    return (
        <Fragment>
            {instancePossibleConclusions.length !== 0 ? renderPossibleConclusions() : null}
        </Fragment>
    );
}

export default Conclusion;
