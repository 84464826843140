import { createActions } from 'redux-actions';

export const {
  createOneTimeSurveyInstance,
  createSurveyInstance,
  getSurveyInstancesList,
  getSurveyInstance,
  getSurveysList,
  getSurvey,
  // setCurrentSurvey,
  setCurrentSurveyQuestionIndex,
  prevSurveyQuestion,
  nextSurveyQuestion,
  // getSurveyResultsList,
  // getSurveyResultItem,
  // setSelectedSurveyResultItem,
  updateSurveyInstance,
  updateSurveyInstanceRecommendations,
  updateSurveyInstanceConclusion,
  submitSurveyInstance,
  exportSurveysInstances,
} = createActions(
  {
    CREATE_ONE_TIME_SURVEY_INSTANCE: {
      START: ({
        surveyId,
        // firstName,
        // lastName,
      }) => ({
        surveyId,
        // firstName,
        // lastName,
      }),
      SUCCESS: undefined,
      FAILURE: undefined,
    },
    CREATE_SURVEY_INSTANCE: {
      START: ({
        patientEmail,
        surveyId,
      }) => ({
        patientEmail,
        surveyId,
      }),
      SUCCESS: undefined,
      FAILURE: undefined,
    },
    GET_SURVEY_INSTANCES_LIST: {
      START: ({ email }) => ({ email }),
      SUCCESS: ({
        data,
        dataSequence,
        pageable,
        sort,
        meta,
      }) => ({
        data,
        dataSequence,
        pageable,
        sort,
        meta,
        }),
      FAILURE: undefined,
    },
    GET_SURVEY_INSTANCE: {
      START: ({ id }) => ({ id }),
      SUCCESS: ({
        instanceCreateTimestamp,
        instanceId,
        instanceLastUpdatedTimestamp,
        instanceStage,
        instanceStatus,
        instanceAnswers,
        instanceScores,
        instanceRecommendations,
        survey,
        user,
        surveyId,
        surveyName,
        surveyQuestions,
        instancePossibleConclusions,
        instanceConclusions,
        instanceMainAreasOfFocus,
      }) => ({
        instanceCreateTimestamp,
        instanceId,
        instanceLastUpdatedTimestamp,
        instanceStage,
        instanceStatus,
        instanceAnswers,
        instanceScores,
        instanceRecommendations,
        survey,
        user,
        surveyId,
        surveyName,
        surveyQuestions,
        instancePossibleConclusions,
        instanceConclusions,
        instanceMainAreasOfFocus,
        }),
      FAILURE: undefined,
    },
    GET_SURVEYS_LIST: {
      START: undefined,
      SUCCESS: ({ data, dataSequence }) => ({ data, dataSequence }),
      FAILURE: undefined,
    },
    GET_SURVEY: {
      START: ({ id }) => ({ id }),
      SUCCESS: ({ data }) => ({ data }),
      FAILURE: undefined,
    },
    // SET_CURRENT_SURVEY: ({ id }) => ({ id }),
    SET_CURRENT_SURVEY_QUESTION_INDEX: ({ index }) => ({ index }),
    // PREV_SURVEY_QUESTION: undefined,
    // NEXT_SURVEY_QUESTION: ({ data }) => ({ data }),
    // GET_SURVEY_RESULTS_LIST: {
    //   START: undefined,
    //   SUCCESS: ({ data, dataSequence }) => ({ data, dataSequence }),
    //   FAILURE: undefined,
    // },
    // GET_SURVEY_RESULT_ITEM: {
    //   START: ({ id }) => ({ id }),
    //   SUCCESS: ({ data }) => ({ data }),
    //   FAILURE: undefined,
    // },
    // SET_SELECTED_SURVEY_RESULT_ITEM: ({ id }) => ({ id }),
    UPDATE_SURVEY_INSTANCE: {
      START: ({ id, data }) => ({ id, data }),
      SUCCESS: ({ message }) => ({ message }),
      FAILURE: undefined,
    },
    UPDATE_SURVEY_INSTANCE_RECOMMENDATIONS: {
      START: ({ id, data }) => ({ id, data }),
      SUCCESS: undefined,
      FAILURE: undefined,
    },
    UPDATE_SURVEY_INSTANCE_CONCLUSION: {
      START: ({ id, data }) => ({ id, data }),
      SUCCESS: undefined,
      FAILURE: undefined,
    },
    SUBMIT_SURVEY_INSTANCE: {
      START: ({ id, stage, surveyType }) => ({ id, stage, surveyType }),
      SUCCESS: undefined,
      FAILURE: undefined,
    },
    EXPORT_SURVEYS_INSTANCES: {
      START: undefined,
      SUCCESS: undefined,
      FAILURE: undefined,
    },
  },
  { prefix: 'SURVEYS' }
);
