import React, { useState, useEffect, useRef, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Form, Input } from "element-react";
import { useTranslation } from "react-i18next";

import { USER_ROLES } from "constants/userRoles";

import * as clinicsActions from "store/clinics/actions";

import AddUser from "сomponents/AddUser/AddUser";

import styles from "./ProfileClinic.module.scss";

ProfileClinic.propTypes = {
    clinicId: PropTypes.string.isRequired,
};

const initialFormState = {
    id: "",
    name: "",
    locale: "",
};

function reducer(state, action) {
    switch (action.type) {
        case "setId":
            return { ...state, id: action.payload };
        case "setName":
            return { ...state, name: action.payload };
        case "setLocale":
            return { ...state, locale: action.payload };
        default:
            throw new Error();
    }
}

function ProfileClinic({ clinicId }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const formRef = useRef(null);

    const selectedClinicProfile =
        useSelector((state) => state?.clinics?.selectedClinicProfile) ?? {};

    const [state, dispatchToLocalState] = useReducer(reducer, initialFormState);

    const [idIsValid, setIdIsValid] = useState(false);
    const [nameIsValid, setNameIsValid] = useState(false);

    useEffect(() => {
        dispatchToLocalState({ type: "setId", payload: selectedClinicProfile?.id ?? "" });
        dispatchToLocalState({ type: "setName", payload: selectedClinicProfile?.name ?? "" });
        dispatchToLocalState({
            type: "setLocale",
            payload: selectedClinicProfile?.locale ?? "",
        });
    }, [selectedClinicProfile]);

    const validationRules = {
        id: [
            {
                required: true,
                message: t("Clinics:ProfileClinic.form.validation.warningMessage.idIsEmpty"),
                trigger: "blur",
            },
            {
                validator: (rule, value, callback) => {
                    if (value === "") {
                        setIdIsValid(false);
                        callback(
                            new Error(
                                t(
                                    "Clinics:ProfileClinic.form.validation.errorMessage.idIsEmpty"
                                )
                            )
                        );
                    } else {
                        setIdIsValid(true);
                        callback();
                    }
                },
            },
        ],
        name: [
            {
                required: true,
                message: t("Clinics:ProfileClinic.form.validation.warningMessage.nameIsEmpty"),
                trigger: "blur",
            },
            {
                validator: (rule, value, callback) => {
                    if (value === "") {
                        setNameIsValid(false);
                        callback(
                            new Error(
                                t(
                                    "Clinics:ProfileClinic.form.validation.errorMessage.nameIsEmpty"
                                )
                            )
                        );
                    } else {
                        setNameIsValid(true);
                        callback();
                    }
                },
            },
        ],
    };

    const onIdChange = (value) => {
        dispatchToLocalState({ type: "setId", payload: value });
    };

    const onNameChange = (value) => {
        dispatchToLocalState({ type: "setName", payload: value });
    };

    const onLocaleChange = (value) => {
        dispatchToLocalState({ type: "setLocale", payload: value });
    };

    const clearFormFields = () => {
        dispatchToLocalState({ type: "setId", payload: "" });
        dispatchToLocalState({ type: "setName", payload: "" });
        dispatchToLocalState({ type: "setLocale", payload: "" });
    };

    useEffect(() => {
        dispatch(clinicsActions.getClinic.start({ clinicId }));

        return () => {
            clearFormFields();
        };
    }, []);

    return (
        <div className={styles.profile}>
            <div className={styles.profileName}>{t("Clinics:ProfileClinic.role")}</div>
            <div className={styles.profileForm}>
                <Form ref={formRef} rules={validationRules} model={state}>
                    <Form.Item
                        prop="id"
                        label={t("Clinics:ProfileClinic.form.input.id.label")}
                    >
                        <Input
                            type="text"
                            placeholder={t("Clinics:ProfileClinic.form.input.id.placeholder")}
                            value={state.id}
                            onChange={onIdChange}
                            autoComplete="off"
                            disabled
                        />
                    </Form.Item>
                    <Form.Item
                        prop="name"
                        label={t("Clinics:ProfileClinic.form.input.name.label")}
                    >
                        <Input
                            type="text"
                            placeholder={t(
                                "Clinics:ProfileClinic.form.input.name.placeholder"
                            )}
                            value={state.name}
                            onChange={onNameChange}
                            autoComplete="off"
                            disabled
                        />
                    </Form.Item>
                    <Form.Item
                        prop="name"
                        label={t("Clinics:ListTableClinics.table.column.label.locale")}
                    >
                        <Input
                            type="text"
                            placeholder={t(
                                "Clinics:ListTableClinics.table.column.label.locale"
                            )}
                            value={state.locale}
                            onChange={onLocaleChange}
                            autoComplete="off"
                            disabled
                        />
                    </Form.Item>
                </Form>
            </div>
            <div>
                <AddUser
                    userTypeToAdd={USER_ROLES.admin}
                    presetedClinicId={selectedClinicProfile?.id ?? ""}
                />
            </div>
        </div>
    );
}

export default ProfileClinic;
