import React, { useState, useEffect, useRef, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Input, Select, Button } from 'element-react';
import { useTranslation } from 'react-i18next';

import { validateEmail } from 'helpers/helpers';
// import avatar from 'images/avatarBig.png';

import * as patientsActions from 'store/patients/actions';

// import customComponentsStyles from 'styles/custom-components.module.scss';
import styles from './ProfilePatient.module.scss';

ProfilePatient.propTypes = {
  email: PropTypes.string.isRequired,
};

const initialFormState = {
  email: '',
  firstName: '',
  lastName: '',
};

function reducer(state, action) {
  switch (action.type) {
    case 'setEmail':
      return {...state, email: action.payload};
    case 'setFirstName':
      return {...state, firstName: action.payload};
    case 'setLastName':
      return {...state, lastName: action.payload};
    default:
      throw new Error();
  }
}

function ProfilePatient ({email}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const formRef = useRef(null);

  const selectedPatientProfile = useSelector( state => state?.patients?.selectedPatientProfile ) || {};
  
  const [state, dispatchToLocalState] = useReducer(reducer, initialFormState);

  const [emailIsValid, setEmailIsValid] = useState(false);
  const [firstNameIsValid, setFirstNameIsValid] = useState(false);
  const [lastNameIsValid, setLastNameIsValid] = useState(false);

  useEffect(() => {
    dispatch(patientsActions.getPatient.start({email}));
  }, []);

  useEffect(() => {
    dispatchToLocalState({type: 'setEmail', payload: selectedPatientProfile.email || ''});
    dispatchToLocalState({type: 'setFirstName', payload: selectedPatientProfile.firstName || ''});
    dispatchToLocalState({type: 'setLastName', payload: selectedPatientProfile.lastName || ''});
  }, [selectedPatientProfile]);

  const validationRules = {
    email: [
      {
        required: true,
        message: t('Patients:ProfilePatient.form.validation.warningMessage.emailIsEmpty'),
        trigger: 'blur'
      },
      { 
        validator: (rule, value, callback) => {
          if (value === '') {
            setEmailIsValid(false);
            callback(new Error(t('Patients:ProfilePatient.form.validation.errorMessage.emailIsEmpty')));
          } 
          if (!validateEmail(value)) {
            setEmailIsValid(false);
            callback(new Error(t('Patients:ProfilePatient.form.validation.errorMessage.emailIsNotValid')));
          } else {
            setEmailIsValid(true);
            callback();
          }
        } 
      }
    ],
    firstName: [
      {
        required: true,
        message: t('Patients:ProfilePatient.form.validation.warningMessage.firstNameIsEmpty'),
        trigger: 'blur'
      },
      { 
        validator: (rule, value, callback) => {
          if (value === '') {
            setFirstNameIsValid(false);
            callback(new Error(t('Patients:ProfilePatient.form.validation.errorMessage.firstNameIsEmpty')));
          } else {
            setFirstNameIsValid(true);
            callback();
          }
        } 
      }
    ],
    lastName: [
      {
        required: true,
        message: t('Patients:ProfilePatient.form.validation.warningMessage.lastNameIsEmpty'),
        trigger: 'blur'
      },
      { 
        validator: (rule, value, callback) => {
          if (value === '') {
            setLastNameIsValid(false);
            callback(new Error(t('Patients:ProfilePatient.form.validation.errorMessage.lastNameIsEmpty')));
          } else {
            setLastNameIsValid(true);
            callback();
          }
        } 
      }
    ],
  };

  const onEmailChange = value => {
    dispatchToLocalState({type: 'setEmail', payload: value});
  };

  const onFirstNameChange = value => {
    dispatchToLocalState({type: 'setFirstName', payload: value});
  };

  const onLastNameChange = value => {
    dispatchToLocalState({type: 'setLastName', payload: value});
  };

  const clearFormFields = () => {
    dispatchToLocalState({type: 'setEmail', payload: ''});
    dispatchToLocalState({type: 'setFirstName', payload: ''});
    dispatchToLocalState({type: 'setLastName', payload: ''});
  };

  // const onSave = () => {
  //   formRef.current.validate((valid) => {
  //     if (valid) {
  //       // onSubmit({
  //       //   email: state.email,
  //       //   firstName: state.firstName,
  //       //   lastName: state.lastName,
  //       // });

  //       formRef.current.resetFields();
  //       clearFormFields(); // it seems that resetFields() can't handle state created by useReducer
  //     } else {
  //       console.log('submit error!');
  //       return false;
  //     }
  //   });
  // };

  // const onCancel = () => {
  //   formRef.current.resetFields();
  //   clearFormFields(); // it seems that resetFields() can't handle state created by useReducer
  // };

  return (
    <div className={styles.profile}>
      {/* <img src={avatar} alt="avatar"/> */}
      
      <div className={styles.profileName}>
        {`${selectedPatientProfile?.firstName || ''} ${selectedPatientProfile?.lastName || ''}`}
      </div>
      <div className={styles.profileCategory}>
        {t('Patients:ProfilePatient.role')}
      </div>
      <div className={styles.profileForm}>
        <Form ref={formRef} rules={validationRules} model={state}>
          <Form.Item
            prop="email"
            label={t('Patients:ProfilePatient.form.input.email.label')}
          >
            <Input
              type="text"
              placeholder={t('Patients:ProfilePatient.form.input.email.placeholder')}
              value={state.email}
              onChange={onEmailChange}
              autoComplete="off"
              disabled
            />
          </Form.Item>
          <Form.Item
            prop="firstName"
            label={t('Patients:ProfilePatient.form.input.firstName.label')}
          >
            <Input
              type="text"
              placeholder={t('Patients:ProfilePatient.form.input.firstName.placeholder')}
              value={state.firstName}
              onChange={onFirstNameChange}
              autoComplete="off"
              disabled
            />
          </Form.Item>
          <Form.Item
            prop="lastName"
            label={t('Patients:ProfilePatient.form.input.lastName.label')}
          >
            <Input
              type="text"
              placeholder={t('Patients:ProfilePatient.form.input.lastName.placeholder')}
              value={state.lastName}
              onChange={onLastNameChange}
              autoComplete="off"
              disabled
            />
          </Form.Item>
        </Form>
      </div>

      {/* <div className={styles.profileButtons}>
        <div>
          <Button
            onClick={onSave}
            disabled={!(
              emailIsValid
              && firstNameIsValid
              && lastNameIsValid
            )}
            className={customComponentsStyles.buttonCustom}
          >
            Save changes
          </Button>
          <Button
            onClick={onCancel}
            // disabled={!submitButtonIsEnabled}
            className={customComponentsStyles.buttonCustom}
          >
            Cancel
          </Button>
        </div>
        <div className="">
          <Button
            className=""
          >
            Delete doctor
          </Button>
        </div>
      </div> */}
    </div>
  );
}

export default ProfilePatient;

