import React from "react";
import { useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { USER_ROLES } from "constants/userRoles";

import { superadminItems, adminItems, doctorItems, patientItems } from "./SidebarNavItems";

import styles from "./SidebarNav.module.scss";

function SidebarNav() {
    const { t } = useTranslation();
    const location = useLocation();

    const role = useSelector((state) => state.auth.accountType);
    const isRestrictedUser = useSelector(
        (state) => state.auth?.userAccountData?.isRestrictedUser
    );

    const { pathname } = location;

    const navItemsMap = {
        [USER_ROLES.superadmin]: superadminItems,
        [USER_ROLES.admin]: adminItems,
        [USER_ROLES.doctor]: doctorItems,
        [USER_ROLES.patient]: patientItems,
    };

    const navItems = navItemsMap[role] || [];

    return (
        <div className={styles.sidebarNav}>
            <div className={styles.list}>
                {!isRestrictedUser &&
                    navItems.map((item) => {
                        if (item.id === "separator") {
                            return <div key={item.id} className={styles.separator}></div>;
                        } else if (item.isTargetBlank) {
                            return (
                                <a
                                    key={item.id}
                                    href={item.path}
                                    target="_blank"
                                    rel="noreferrer"
                                    className={styles.menuItem}
                                    title={t(item.captionID)}
                                >
                                    <img
                                        className={styles.icon}
                                        src={item.icon}
                                        alt={t("UI:SidebarNav.menuIcon.altText")}
                                    />
                                </a>
                            );
                        } else {
                            return (
                                <Link
                                    key={item.id}
                                    to={`/${item.path}`}
                                    className={
                                        pathname.includes(item.path)
                                            ? styles.menuItemActive
                                            : styles.menuItem
                                    }
                                    title={t(item.captionID)}
                                >
                                    <img
                                        className={styles.icon}
                                        src={item.icon}
                                        alt={t("UI:SidebarNav.menuIcon.altText")}
                                    />
                                </Link>
                            );
                        }
                    })}
            </div>
        </div>
    );
}

export default SidebarNav;
