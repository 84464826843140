import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ContentWithHeaderLayout from 'layouts/pageContentLayouts/ContentWithHeaderLayout/ContentWithHeaderLayout';
import FullheightWithTilesLayout from 'layouts/pageContentLayouts/FullheightWithTilesLayout/FullheightWithTilesLayout';

import styles from './DoctorResourcesTOneView.module.scss';

function DoctorResourcesTOneView () {
  const { t } = useTranslation();

  const renderTileOneContent = () => {
    return(
      <Fragment>
        <h2>{t('Resources:DoctorResourcesTOneView.resourcesGroup.selfManagement.heading')}</h2>
      </Fragment>
    );
  };

  const renderTileTwoContent = () => {
    return(
      <Fragment>
        <h2>{t('Resources:DoctorResourcesTOneView.resourcesGroup.psychologicalBurden.heading')}</h2>
      </Fragment>
    );
  };

  const renderTileThreeContent = () => {
    return(
      <Fragment>
        <h2>{t('Resources:DoctorResourcesTOneView.resourcesGroup.therapy.heading')}</h2>
      </Fragment>
    );
  };

  const renderTileFourContent = () => {
    return(
      <Fragment>
        <h2>{t('Resources:DoctorResourcesTOneView.resourcesGroup.socialSupport.heading')}</h2>
      </Fragment>
    );
  };


  return (
    <ContentWithHeaderLayout
      padding
      bg
      headerTitle={t('Resources:DoctorResourcesTOneView.header.heading')}
    >
      <FullheightWithTilesLayout 
        tileOneContent={renderTileOneContent()}
        tileTwoContent={renderTileTwoContent()}
        tileThreeContent={renderTileThreeContent()}
        tileFourContent={renderTileFourContent()}
      />
    </ContentWithHeaderLayout>
  );
}

export default DoctorResourcesTOneView;
