import React, { Fragment } from 'react';
import { Button } from 'element-react';
import { useTranslation } from 'react-i18next';

import { surveysInstancesExport } from 'services/api/ApiService';

import DownloadUnit from 'componentsShared/DownloadUnit/DownloadUnit';

import customComponentsStyles from 'styles/custom-components.module.scss';

function SurveysExport () {
  const { t } = useTranslation();

  const renderExportForm = (onClickDownload) => {
    return (
      <Button className={customComponentsStyles.buttonCustom} onClick={onClickDownload}>
        {t('ImportExport:SurveysExport.button.exportResults')}
      </Button>
    );
  };

  return (
    <Fragment>
      <DownloadUnit
        renderForm={renderExportForm}
        request={surveysInstancesExport}
        params={''}
        filename={'export'}
      />
    </Fragment>
  );
}

export default SurveysExport;
