export const SURVEY_INSTANCE_STATUSES = {
  created: 'CREATED',
  inProgress: 'IN_PROGRESS',
  recommendations: 'RECOMMENDATIONS', // TODO: do we need it?
  review: 'REVIEW',
  completed: 'COMPLETED',
};

export const SURVEY_FIRST_QUESTION_IDS = {
  diabetesTypeOne: 0,
  diabetesTypeTwo: 0,
  diabetesTypePre: 0,
  diabetesTypeOneChild: 0,
  diabetesTypeOneParent: 0,
};

export const SURVEY_INSTANCE_STAGES = {
  firstWave: 'FIRST_WAVE',
  secondWave: 'SECOND_WAVE',
};
