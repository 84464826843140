import { Auth } from "aws-amplify";

import { AMPLIFY_AUTH_PARAMS } from "constants/amplifyAuthParams";
import { USER_ROLES } from "constants/userRoles";

import { store } from "store/store";
import * as authActions from "store/auth/actions";
import { setAmplifyConfig } from "./AWSAmplifyConfig";

export async function AWSSignOut(isGlobalSignOut = false) {
    await Auth.signOut({ global: isGlobalSignOut });
}

const AWSAmplifyService = {
    async signIn(username, password, newPassword) {
        let user;

        if (newPassword?.length > 0) {
            user = await Auth.signIn(username, password);
            Auth.completeNewPassword(user, newPassword)
                .then((user) => {
                    return user;
                })
                .catch((e) => {
                    console.log("new password action error", e);
                });
        } else {
            user = await new Promise((resolve, reject) => {
                setAmplifyConfig(USER_ROLES.doctor);
                Auth.signIn(username, password)
                    .then((user) => {
                        resolve({ ...user, role: USER_ROLES.doctor });
                    })
                    .catch(() => {
                        setAmplifyConfig(USER_ROLES.patient);
                        Auth.signIn(username, password)
                            .then((user) => {
                                resolve({ ...user, role: USER_ROLES.patient });
                            })
                            .catch(() => {
                                setAmplifyConfig(USER_ROLES.admin);
                                Auth.signIn(username, password)
                                    .then((user) => {
                                        resolve({ ...user, role: USER_ROLES.admin });
                                    })
                                    .catch(() => {
                                        setAmplifyConfig(USER_ROLES.superadmin);
                                        Auth.signIn(username, password)
                                            .then((user) => {
                                                resolve({
                                                    ...user,
                                                    role: USER_ROLES.superadmin,
                                                });
                                            })
                                            .catch((e) => {
                                                reject(e);
                                            });
                                    });
                            });
                    });
            });
        }

        return user;
    },

    async forgotPassword(userName) {
        Auth.forgotPassword(userName)
            .then(() => {
                store.dispatch(
                    authActions.forgotPassword.handleServiceSuccessResponse({ userName })
                );
            })
            .catch((error) => {
                store.dispatch(
                    authActions.changePassword.handleServiceErrorResponse({ error })
                );
            });
    },

    async forgotPasswordSubmit(username, code, newPassword) {
        Auth.forgotPasswordSubmit(username, code, newPassword)
            .then(() => {
                store.dispatch(
                    authActions.forgotPasswordSubmit.handleServiceSuccessResponse()
                );
            })
            .catch((error) => {
                store.dispatch(
                    authActions.forgotPasswordSubmit.handleServiceErrorResponse({ error })
                );
            });
    },

    async changePassword(oldPassword, newPassword) {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                return Auth.changePassword(user, oldPassword, newPassword);
            })
            .then(() => {
                store.dispatch(authActions.changePassword.handleServiceSuccessResponse());
            })
            .catch((error) => {
                store.dispatch(
                    authActions.changePassword.handleServiceErrorResponse({ error })
                );
            });
    },

    isAdminUserPool(poolId) {
        const adminAmplifyPoolId = AMPLIFY_AUTH_PARAMS[USER_ROLES.admin].userPoolId;

        return poolId === adminAmplifyPoolId;
    },
    isDoctorUserPool(poolId) {
        const doctorAmplifyPoolId = AMPLIFY_AUTH_PARAMS[USER_ROLES.doctor].userPoolId;

        return poolId === doctorAmplifyPoolId;
    },
    isPatientUserPool(poolId) {
        const patientAmplifyPoolId = AMPLIFY_AUTH_PARAMS[USER_ROLES.patient].userPoolId;

        return poolId === patientAmplifyPoolId;
    },
};

export default AWSAmplifyService;
