import { select, take, cancel } from 'redux-saga/effects';

export function* storeItemChangeWatcherSaga(selector, startActions, endActions, sagaToRun, watcherTask) {
  /*
    one time watcher to run some saga on data update. Should be started before target saga run
    
    selector - isLoading selector which indicates that data in store was updated
    startActions[action] || action - usually, start action. Needs to read the indicator flag on process start
    endActions[action] || action - usually, success and faulure actions. Needs to read the indicator flag on process end
    saga - saga that should be runned after watcher trigger
    watcherTask - task of the watcher itself. We need it to terminate the watcher itself after sagaToRun will be performed
  */
  yield take(startActions);
  let previous = yield select(selector);
  while (true) {
    const endAction = yield take(endActions);
    const next = yield select(selector);
    if (next !== previous) {
      yield* sagaToRun(endAction, previous, next);
      previous = next;
      yield cancel(watcherTask);
    }
  }
}
