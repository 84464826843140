import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Card } from "element-react";
import { get } from "lodash";
import { useTranslation } from "react-i18next";

import NavigationService from "services/navigation/NavigationService";

import AuxSurvey from "сomponents/Surveys/AuxSurvey/AuxSurvey";

import styles from "./AuxSurveyView.module.scss";

function AuxSurveyView() {
    const { t } = useTranslation();

    const userAuxSurveys = useSelector((state) =>
        get(state.auth.userAccountData, "demographics", [])
    );

    useEffect(() => {
        // manual link enter case
        const notCompletedAuxSurveys = userAuxSurveys.filter((item) => {
            return item.status !== "COMPLETED";
        });

        if (notCompletedAuxSurveys.length === 0) {
            NavigationService.navigateToDefaultPath();
        }
    }, []);

    return (
        <div className={styles.outerContainer}>
            <div className={styles.container}>
                <Card className={styles.header}>
                    <h2 className={styles.headerHeading}>
                        {t("Surveys:AuxSurveyView.card.heading")}
                    </h2>
                </Card>
                <AuxSurvey />
            </div>
        </div>
    );
}

export default AuxSurveyView;
