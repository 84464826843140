import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Markdown from "markdown-to-jsx";
import LanguageService from "services/language/LanguageService";

MarkdownHandler.propTypes = {
    translations: PropTypes.object.isRequired,
};

function MarkdownHandler({ translations }) {
    const [markdown, setMarkdown] = useState("");

    const lang = LanguageService.getCurrentLanguage();

    useEffect(() => {
        fetch(translations[lang])
            .then((res) => res.text())
            .then((md) => {
                setMarkdown(md);
            });
    }, []);

    return <Markdown>{markdown}</Markdown>;
}

export default MarkdownHandler;
