import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { FONT_SIZES } from 'constants/ui';

import CookiesService from 'services/cookies/CookiesService';

import styles from './FontSizeSelector.module.scss';

function FontSizeSelector () {
  const { t } = useTranslation();

  const [selected, setSelected] = useState(FONT_SIZES.MEDIUM);

  useEffect(() => {
    const savedFontSizeSetting = CookiesService.getUiSizeCookie();

    setSelected(savedFontSizeSetting);
    changeFontSize(savedFontSizeSetting);
  }, []);

  const changeFontSize = (fontSize) => {
    document.documentElement.style.fontSize = fontSize;
    setSelected(fontSize);
    CookiesService.setUiSizeCookie(fontSize);
  };

  return (
    <Fragment>
      <h4 className={styles.heading}>
        {t('UI:FontSizeSelector.heading.fontSize')}
      </h4>
      <ul className={styles.list}>
        <li
          className={classNames(styles.listItem, {
            [styles.selected]: selected === FONT_SIZES.MEDIUM,
          })}
          onClick={() => {changeFontSize(FONT_SIZES.MEDIUM);}}
        >
          {t('UI:FontSizeSelector.option.medium')}
        </li>
        <li
          className={classNames(styles.listItem, {
            [styles.selected]: selected === FONT_SIZES.LARGE,
          })}
          onClick={() => {changeFontSize(FONT_SIZES.LARGE);}}
        >
          {t('UI:FontSizeSelector.option.large')}
        </li>
        <li
          className={classNames(styles.listItem, {
            [styles.selected]: selected === FONT_SIZES.EXTRA_LARGE,
          })}
          onClick={() => {changeFontSize(FONT_SIZES.EXTRA_LARGE);}}
        >
          {t('UI:FontSizeSelector.option.extraLarge')}
        </li>
      </ul>
    </Fragment>
  );
}

export default FontSizeSelector;
