import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './Loader.module.scss';

Loader.propTypes = {
  isVisible: PropTypes.bool.isRequired,
};

function Loader ({isVisible}) {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.loaderOverlay, { [styles.hidden]: !isVisible })}>
      <div className={styles.loader}>
        {
          t('UI:Loader.loading')
        }
      </div>
    </div>
  );
}

export default Loader;
