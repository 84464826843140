import React from 'react';
import { useTranslation } from 'react-i18next';

import { USER_ROLES } from 'constants/userRoles';

import ContentWithHeaderLayout from 'layouts/pageContentLayouts/ContentWithHeaderLayout/ContentWithHeaderLayout';
import ListTablePageLayout from 'layouts/pageContentLayouts/ListTablePageLayout/ListTablePageLayout';

import AddUser from 'сomponents/AddUser/AddUser';
import ListTableAdmins from 'сomponents/ListTableAdmins/ListTableAdmins';

function SuperadminAdminsListView() {
  const { t } = useTranslation();

  return (
    <ContentWithHeaderLayout
      padding
      headerTitle={t('Admins:SuperadminAdminsListView.header.heading')}
    >
      <ListTablePageLayout
        headerContent={
          <AddUser userTypeToAdd={USER_ROLES.admin}/>
        }
        mainContent={
          <ListTableAdmins />
        }
      />
    </ContentWithHeaderLayout>
  );
}

export default SuperadminAdminsListView;
