import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Card, Radio, Button, Form, Input, DatePicker } from "element-react";
import { useTranslation } from "react-i18next";
import customComponentsStyles from "styles/custom-components.module.scss";
import { surveysListGet, api, userRestrictedSurveyBound } from "services/api/ApiService";
import SidebarLeftLayout from "layouts/topLevelLayouts/SidebarLeftLayout/SidebarLeftLayout";
import ContentWithHeaderLayout from "layouts/pageContentLayouts/ContentWithHeaderLayout/ContentWithHeaderLayout";
import styles from "./NewPatientSurveys.module.scss";
import { validateEmail } from "helpers/helpers";
import Loader from "componentsShared/Loader/Loader";
import { useDispatch } from "react-redux";
import { loginUserRestrictedSurveyBound, signOut } from "store/auth/actions";
import NotificationsService from "services/notifications/NotificationsService";

NewPatientSurveys.propTypes = {
    location: PropTypes.shape({
        search: PropTypes.string.isRequired,
    }).isRequired,
};

function NewPatientSurveys({ location }) {
    const { t } = useTranslation();
    const { search } = location;
    const formRef = useRef(null);
    const dispatch = useDispatch();

    const [isLoading, setLoading] = useState(false);
    const [surveys, setSurveys] = useState([]);

    const [surveyId, setSurveyId] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState(null);

    const validationRules = {
        surveyId: [
            {
                required: true,
                message: t("Patients:newPatientSurveys.title"),
                trigger: "blur",
            },
            {
                validator: (_, value, callback) => {
                    if (value === "") {
                        callback(new Error(t("Patients:newPatientSurveys.title")));
                    } else {
                        callback();
                    }
                },
            },
        ],
        firstName: [
            {
                required: true,
                message: t(
                    "Auth:AddUserDialog.dialog.validation.warningMessage.firstNameIsEmpty"
                ),
                trigger: "blur",
            },
            {
                validator: (_, value, callback) => {
                    if (value === "") {
                        callback(
                            new Error(
                                t(
                                    "Auth:AddUserDialog.dialog.validation.errorMessage.firstNameIsEmpty"
                                )
                            )
                        );
                    } else {
                        callback();
                    }
                },
            },
        ],
        lastName: [
            {
                required: true,
                message: t(
                    "Auth:AddUserDialog.dialog.validation.warningMessage.lastNameIsEmpty"
                ),
                trigger: "blur",
            },
            {
                validator: (_, value, callback) => {
                    if (value === "") {
                        callback(
                            new Error(
                                t(
                                    "Auth:AddUserDialog.dialog.validation.errorMessage.lastNameIsEmpty"
                                )
                            )
                        );
                    } else {
                        callback();
                    }
                },
            },
        ],
        email: [
            {
                required: true,
                message: t("Auth:AddUserDialog.dialog.validation.warningMessage.emailIsEmpty"),
                trigger: "blur",
            },
            {
                validator: (_, value, callback) => {
                    if (value === "") {
                        callback(
                            new Error(
                                t(
                                    "Auth:AddUserDialog.dialog.validation.errorMessage.emailIsEmpty"
                                )
                            )
                        );
                    }
                    if (!validateEmail(value)) {
                        callback(
                            new Error(
                                t(
                                    "Auth:AddUserDialog.dialog.validation.errorMessage.emailIsNotValid"
                                )
                            )
                        );
                    } else {
                        callback();
                    }
                },
            },
        ],
        dateOfBirth: [
            {
                required: false,
            },
        ],
    };

    const formState = { surveyId, firstName, lastName, email, dateOfBirth };

    const handleSubmit = () => {
        formRef.current.validate(async (valid) => {
            if (valid) {
                try {
                    const { jwt } = await userRestrictedSurveyBound({
                        ...formState,
                        dateOfBirth: formState.dateOfBirth?.getTime(),
                        doctorRefToken: search.replace("?docRefToken=", ""),
                    });

                    dispatch(loginUserRestrictedSurveyBound.start({ token: jwt, email }));
                } catch (error) {
                    NotificationsService.throwNotification(
                        "error",
                        t("UI:Error"),
                        String(error?.response?.data?.error) ?? t("UI:UnknownError")
                    );
                }
            } else {
                console.log("submit error!");
                return false;
            }
        });
    };

    useEffect(() => {
        if (search !== "") {
            setLoading(true);
            dispatch(signOut.start({ signOutWithoutRedirect: true }));
            api.deleteToken();
            surveysListGet(search.replace("?docRefToken=", "?doctorRefToken="))
                .then((response) => {
                    setSurveys(response.content.filter(({ isAvailable }) => isAvailable));
                })
                .catch((error) => {
                    NotificationsService.throwNotification(
                        "error",
                        t("UI:Error"),
                        String(error?.message) ?? t("UI:UnknownError")
                    );
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, []);

    return (
        <SidebarLeftLayout>
            <ContentWithHeaderLayout
                headerTitle={t("Surveys:SurveysResults.section.survey.subheading")}
            >
                <Card className={styles.container}>
                    <h2>{t("Patients:newPatientSurveys.title")}</h2>

                    <Form
                        ref={formRef}
                        rules={validationRules}
                        model={formState}
                        className={customComponentsStyles.form}
                    >
                        <Form.Item prop="surveyId">
                            <Radio.Group
                                value={surveyId}
                                onChange={setSurveyId}
                                className={styles.list}
                            >
                                {surveys.map(({ id, name }) => {
                                    return (
                                        <Radio key={id} value={id}>
                                            {name}
                                        </Radio>
                                    );
                                })}
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item prop="firstName">
                            <Input
                                className={customComponentsStyles.inputText}
                                type="text"
                                placeholder={t(
                                    "Auth:AddUserDialog.dialog.form.input.firstName.placeholder"
                                )}
                                value={firstName}
                                onChange={setFirstName}
                                autoComplete="off"
                            />
                        </Form.Item>
                        <Form.Item prop="lastName">
                            <Input
                                className={customComponentsStyles.inputText}
                                type="text"
                                placeholder={t(
                                    "Auth:AddUserDialog.dialog.form.input.lastName.placeholder"
                                )}
                                value={lastName}
                                onChange={setLastName}
                                autoComplete="off"
                            />
                        </Form.Item>
                        <Form.Item prop="email">
                            <Input
                                className={customComponentsStyles.inputText}
                                type="email"
                                placeholder={t(
                                    "Auth:AddUserDialog.dialog.form.input.email.placeholder"
                                )}
                                value={email}
                                onChange={setEmail}
                                autoComplete="off"
                            />
                        </Form.Item>
                        <Form.Item prop="dateOfBirth">
                            <DatePicker
                                value={dateOfBirth}
                                onChange={setDateOfBirth}
                                placeholder={t(
                                    "Patients:FiltersPatientsForm.groupHeading.dateOfBirth"
                                )}
                                disabledDate={(time) => time.getTime() > Date.now()}
                                className={styles["date-picker"]}
                            />
                        </Form.Item>

                        <Button
                            type="submit"
                            onClick={handleSubmit}
                            disabled={
                                surveyId === "" ||
                                firstName === "" ||
                                lastName === "" ||
                                email === "" ||
                                !validateEmail(email)
                            }
                            className={customComponentsStyles.buttonCustom}
                        >
                            {t("Surveys:AuxSurvey.button.stepOne.submit")}
                        </Button>
                    </Form>
                    {isLoading && <Loader isVisible />}
                </Card>
            </ContentWithHeaderLayout>
        </SidebarLeftLayout>
    );
}

export default NewPatientSurveys;
