import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import classNames from 'classnames';
import { Card, Button } from 'element-react';
import { useTranslation } from 'react-i18next';

import SurveyService from 'services/survey/SurveyService';

import { SURVEY_INSTANCE_STATUSES } from 'constants/surveyInstance';

import * as surveysActions from 'store/surveys/actions';

import SurveyQuestion from 'сomponents/Surveys/SurveyQuestionnaire/SurveyQuestion/SurveyQuestion';
import SurveySummary from 'сomponents/Surveys/SurveyQuestionnaire/SurveySummary/SurveySummary';
import Loader from 'componentsShared/Loader/Loader';

import styles from './SurveyQuestionnaire.module.scss';
import customComponentsStyles from 'styles/custom-components.module.scss';

SurveyQuestionnaire.propTypes = {
  surveyInstanceId: PropTypes.string.isRequired,
};

function SurveyQuestionnaire ({surveyInstanceId}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const surveyInstance = useSelector(state => state.surveys.surveyInstance);
  const surveyInstanceStatus = get(surveyInstance, 'instanceStatus', '');
  const surveyInstanceStage = get(surveyInstance, 'instanceStage', '');
  const surveyInstanceQuestionsData = useSelector(
    (state) => get(state.surveys.surveyInstance, 'surveyQuestions.data', {})
  );
  const surveyInstanceQuestionsDataSequence = useSelector(
    (state) => get(state.surveys.surveyInstance, 'surveyQuestions.dataSequence', [])
  );
  const currentSurveyQuestionIndex = useSelector(state => state.surveys.currentSurveyQuestionIndex);
  const isLoadingGetSurveyInstance = useSelector(state => state.surveys.isLoadingGetSurveyInstance);

  const [startDialogWasApproved, setStartDialogWasApproved] = useState(false);

  useEffect(() => {
    if (surveyInstanceId !== '') {
      dispatch(surveysActions.getSurveyInstance.start({id: surveyInstanceId}));
      dispatch(surveysActions.setCurrentSurveyQuestionIndex({index: 0})); // TODO: calculate first not answered
    }
  }, [surveyInstanceId]);

  useEffect(() => {
    if(surveyInstanceId) {
      dispatch(surveysActions.getSurveyInstance.start({id: surveyInstanceId}));
    }
  }, []);

  useEffect(() => {
    setStartDialogWasApproved(false);
  }, [surveyInstanceId]);

  useEffect(() => { // select first question on init
    dispatch(surveysActions.setCurrentSurveyQuestionIndex({index: 0}));
  }, []);

  useEffect(() => { // start fom first question on second wave
    dispatch(surveysActions.setCurrentSurveyQuestionIndex({index: 0}));
  }, [surveyInstanceStage]);

  const onClickPrev = () => {
    dispatch(surveysActions.setCurrentSurveyQuestionIndex({index: currentSurveyQuestionIndex - 1}));
  };

  const onClickNext = () => {
    dispatch(surveysActions.setCurrentSurveyQuestionIndex({index: currentSurveyQuestionIndex + 1}));
  };

  const renderSurveyQuestions = () => {
    return (
      surveyInstanceQuestionsDataSequence.map((key, index) => {
        return (
          <div
            key={get(surveyInstanceQuestionsData, `${[key]}.id`, index)}
            className={classNames(styles.questionHolder, {
              [styles.questionHolderSelected]: currentSurveyQuestionIndex === index,
            })}
          >
            <SurveyQuestion
              id={get(surveyInstanceQuestionsData, `${[key]}.id`, null)}
              surveyInstanceId={surveyInstanceId}  // TODO: remove
              questionData={get(surveyInstanceQuestionsData, `${[key]}`, null)}
              onClickPrev={onClickPrev}
              onClickNext={onClickNext}
              prevIsDisabled={index === 0}
              nextIsDisabled={false}
            />
          </div>
        );
      })
    );
  };

  const onStartDialogClose = () => {
    setStartDialogWasApproved(true);
  };

  const renderSurveyStartDialog = () => {
    return(
      <Card className={styles.startDialog}>
        <div className={styles.startDialogInner}>
          <p className={styles.startDialogDescription}>
            {t('Surveys:SurveyQuestionnaire.startDescriptionFirst')}
          </p>
          <p className={styles.startDialogDescription}>
            {t('Surveys:SurveyQuestionnaire.startDescriptionSecond')}
          </p>
          <Button
            onClick={onStartDialogClose}
            className={`${customComponentsStyles.buttonCustom} ${styles.startDialogConfirmButton}`}
          >
            {t('Surveys:SurveyQuestionnaire.button.ok')}
          </Button>
        </div>
      </Card>
    );
  };

  return (
    <div className={styles.rootWrapper}>
      {
        (currentSurveyQuestionIndex > surveyInstanceQuestionsDataSequence.length - 1)
        && SurveyService.isMedicalStatusCreated()
          ? <SurveySummary
            surveyInstanceId={surveyInstanceId}
          />
          : <div>
            {
              !startDialogWasApproved && SurveyService.isMedicalFirstWaveStage()
                ? renderSurveyStartDialog()
                : Object.keys(surveyInstance).length !== 0 && surveyInstanceQuestionsDataSequence.length !== 0 && (surveyInstanceStatus === SURVEY_INSTANCE_STATUSES.created || surveyInstanceStatus === SURVEY_INSTANCE_STATUSES.inProgress) // TODO: refactor
                  ? renderSurveyQuestions()
                  : null
            }
          </div>
      }
      <Loader isVisible={isLoadingGetSurveyInstance}/>
    </div>
  );
}

export default SurveyQuestionnaire;
