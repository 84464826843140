import React, { Fragment } from 'react';
import ForgotPassword from 'сomponents/ForgotPassword/ForgotPassword';

function ForgotPasswordView() {
  return (
    <Fragment>
      <ForgotPassword />
    </Fragment>
  );
}

export default ForgotPasswordView;
