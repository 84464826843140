import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './PHSurveyIsNotCompleted.module.scss';

function PHSurveyIsNotCompleted () {
  const { t } = useTranslation();

  return (
    <div className={styles.rootWrapper}>
      {t('Surveys:PHSurveyIsNotCompleted.placeholderContent')}
    </div>
  );
}

export default PHSurveyIsNotCompleted;
