import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "element-react";
import { useTranslation } from "react-i18next";

import * as authActions from "store/auth/actions";
import * as patientsActions from "store/patients/actions";

import UserService from "services/user/UserService";
import MarkdownHandler from "componentsShared/MarkdownHandler/MarkdownHandler";

import customComponentsStyles from "styles/custom-components.module.scss";
import styles from "./PrivacyPolicyPage.module.scss";

import PrivacyPolicyFileEn from "locales/PrivacyPolicy_en-GB.md";
import PrivacyPolicyFileEs from "locales/PrivacyPolicy_es-ES.md";

function PrivacyPolicyPage() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onAccept = () => {
        dispatch(patientsActions.acceptPrivacyPolicy.start());
    };

    const onDecline = () => {
        dispatch(authActions.signOut.start());
    };

    const renderSignControls = () => {
        return (
            <div className={styles.signControlsHolder}>
                <Button onClick={onAccept} className={customComponentsStyles.buttonCustom}>
                    {t("Account:PrivacyPolicyPage.button.accept")}
                </Button>
                <Button onClick={onDecline} className={customComponentsStyles.buttonCustom}>
                    {t("Account:PrivacyPolicyPage.button.decline")}
                </Button>
            </div>
        );
    };

    return (
        <div className={styles.rootWrapper}>
            <MarkdownHandler
                translations={{
                    en: PrivacyPolicyFileEn,
                    es: PrivacyPolicyFileEs,
                }}
            />
            {UserService.isAuthorized() && UserService.isUserStatusPrivacyPolicy()
                ? renderSignControls()
                : null}
        </div>
    );
}

export default PrivacyPolicyPage;
