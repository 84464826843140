import React, { useState, useReducer, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Slider, { SliderTooltip } from 'rc-slider';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Card, DatePicker, InputNumber, Checkbox } from 'element-react';

import { DIABETES_TYPES } from 'constants/diabetesTypes';

import * as patientsActions from 'store/patients/actions';

import styles from './FiltersPatientsForm.module.scss';

import 'rc-slider/assets/index.css';

const { Range, Handle } = Slider;

FiltersPatientsForm.propTypes = {
  onToggle: PropTypes.func.isRequired,
  onNumberOfSelectedFiltersChange: PropTypes.func,
};

const MIN_AGE = 0;
const MAX_AGE = 150;

const initialFormState = {
  // assignedHCP: '',
  diabetesType: '',
  // chronicConditions: '',
  patientAge: [MIN_AGE, MAX_AGE],
  // dateOfBirth: '',
  showNoAgePatients: true,
};

function reducer(state, action) {
  switch (action.type) {
    // case 'setAssignedHCP':
    //   return {...state, assignedHCP: action.payload};
    case 'setDiabetesType':
      return {...state, diabetesType: action.payload};
    // case 'setChronicConditions':
    //   return {...state, chronicConditions: action.payload};
    case 'setPatientAge':
      return {...state, patientAge: action.payload};
    // case 'setDateOfBirth':
    //   return {...state, dateOfBirth: action.payload};
    case 'setShowNoAgePatients':
      return {...state, showNoAgePatients: action.payload};
    case 'clearAllFilters':
      return initialFormState;
    default:
      throw new Error('Patients list filters error');
  }
}

const handle = props => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <SliderTooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </SliderTooltip>
  );
};

function FiltersPatientsForm ({onToggle, onNumberOfSelectedFiltersChange}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [totalFiltersApplied, setTotalFiltersApplied] = useState(0);
  const [ageSliderRange, setAgeSliderRange] = useState([MIN_AGE, MAX_AGE]);
  const [state, dispatchToLocalState] = useReducer(reducer, initialFormState);

  useEffect(() => {
    let filtersApplied = 0;

    for (const property in state) {
      if(state[property].length !== 0 && property !== 'patientAge' && property !== 'showNoAgePatients') {
        filtersApplied ++;
      }

      if(property === 'patientAge'
          && (state[property][0] !== MIN_AGE || state[property][1] !== MAX_AGE)) { // if age is not default
        filtersApplied ++;
      }

      if(property === 'showNoAgePatients' && state[property] === false) {
        filtersApplied ++;
      }
    }

    onNumberOfSelectedFiltersChange(filtersApplied);
    setTotalFiltersApplied(filtersApplied);

    const params = {
      diabetes: state.diabetesType,
    };

    if(!state.showNoAgePatients) {
      params.ageLow = state.patientAge[0];
      params.ageHigh = state.patientAge[1];
    }
    
    dispatch(patientsActions.setPatientsListFetchParams.filters({params}));
  }, [state]);

  // const onChangeAssignedHCP = (value) => {
  //   let valueToDispatch = value;

  //   if(state.assignedHCP === value) { // group should behave like a radiobutton group
  //     valueToDispatch = '';
  //   }

  //   dispatchToLocalState({type: 'setAssignedHCP', payload: valueToDispatch});
  // };

  const onChangeDiabetesType = (value) => {
    let valueToDispatch = value;

    if(state.diabetesType === value) { // group should behave like a radiobutton group
      valueToDispatch = '';
    }

    dispatchToLocalState({type: 'setDiabetesType', payload: valueToDispatch});
  };

  // const onChangeChronicConditions = (value) => {
  //   let valueToDispatch = value;

  //   if(state.chronicConditions === value) { // group should behave like a radiobutton group
  //     valueToDispatch = '';
  //   }

  //   dispatchToLocalState({type: 'setChronicConditions', payload: valueToDispatch});
  // };

  const onChangeAgeMin = (value) => {
    const maxAge = state.patientAge[1] !== undefined ? state.patientAge[1] : 0;
    dispatchToLocalState({type: 'setPatientAge', payload: [value, maxAge]});
    setAgeSliderRange([value, maxAge]);
  };

  const onChangeAgeMax = (value) => {
    const minAge = state.patientAge[0] !== undefined ? state.patientAge[0] : 0;
    dispatchToLocalState({type: 'setPatientAge', payload: [minAge, value]});
    setAgeSliderRange([minAge, value]);
  };

  const onChangeAgeSlider = () => {
    dispatchToLocalState({type: 'setPatientAge', payload: ageSliderRange});
  };

  // const onChangeDateOfBirth = (date) => {
  //   dispatchToLocalState({type: 'setDateOfBirth', payload: date});
  // };

  const onChangeShowNoAgePatients = (value) => {
    dispatchToLocalState({type: 'setShowNoAgePatients', payload: value});
  };

  const onClearAllFilters = () => {
    setAgeSliderRange([MIN_AGE, MAX_AGE]);
    setTotalFiltersApplied(0);
    dispatchToLocalState({type: 'clearAllFilters'});
  };

  const renderFilterItem = (label, value, state, onClick) => {
    return(
      <button
        onClick={() => {
          onClick(value);
        }}
        className={classNames(styles.filterToggleItem, {
          [styles.filterToggleItemSelected]: state === value,
        })}
      >
        {label}
      </button>
    );
  };

  const renderAgePicker = () => {
    return(
      <Fragment>
        <div className={styles.minMaxWrapper}>
          <div className={styles.minMaxHolder}>
            <InputNumber
              size="small"
              defaultValue={MIN_AGE}
              min={MIN_AGE}
              max={MAX_AGE}
              onChange={onChangeAgeMin}
              value={state.patientAge.length !== 0 ? state.patientAge[0] : 0}
              disabled={state.showNoAgePatients}
            ></InputNumber>
          </div>
          <div className={styles.minMaxHolder}>-</div>
          <div className={styles.minMaxHolder}>
            <InputNumber
              size="small"
              defaultValue={MAX_AGE}
              min={MIN_AGE}
              max={MAX_AGE}
              onChange={onChangeAgeMax}
              value={state.patientAge.length !== 0 ? state.patientAge[1] : 0}
              disabled={state.showNoAgePatients}
            ></InputNumber>
          </div>
        </div>
        <div className={styles.rangeHolder}>
          <Range
            disabled={state.showNoAgePatients}
            defaultValue={[MIN_AGE, MAX_AGE]}
            value={ageSliderRange}
            min={MIN_AGE}
            max={MAX_AGE}
            onChange={setAgeSliderRange}
            onAfterChange={onChangeAgeSlider}
            handle={handle}
            trackStyle={[{ backgroundColor: '#409EFF' }]}
            handleStyle={[
              {
                backgroundColor: '#FFFFFF',
                borderColor: '#409EFF',
                width: '20px',
                height: '20px',
                marginTop: '-8px',
              },
              {
                backgroundColor: '#FFFFFF',
                borderColor: '#409EFF',
                width: '20px',
                height: '20px',
                marginTop: '-8px',
              }
            ]}
            railStyle={{ backgroundColor: '#E4E7ED' }}
          />
        </div>
        <Checkbox checked={state.showNoAgePatients} onChange={onChangeShowNoAgePatients}>Show no-age patients</Checkbox>
      </Fragment>
    );
  };

  // const renderBirthDatePicker = () => {
  //   return(
  //     <DatePicker
  //       value={state.dateOfBirth}
  //       placeholder={t('Patients:FiltersPatientsForm.datePicker.placeholder')}
  //       onChange={onChangeDateOfBirth}
  //     />
  //   );
  // };

  return (
    <Card
      className={styles.card}
      bodyStyle={{
        position: 'relative',
        padding: '16px'
      }}
    >
      <button
        className={styles.closeButton}
        onClick={onToggle}
      >
        {t('Patients:FiltersPatientsForm.button.closeFilters')}
      </button>
      <h3 className={styles.mainHeading}>Filters</h3>
      <div className={styles.statusRow}>
        <div
          className={classNames(styles.filtersApplied, {
            [styles.filtersAppliedHidden]: totalFiltersApplied === 0,
          })}
        >
          {totalFiltersApplied} {totalFiltersApplied === 1 
            ? t('Patients:FiltersPatientsForm.label.filterApplied.one')
            : t('Patients:FiltersPatientsForm.label.filterApplied.many')
          }
        </div>
        <button
          className={classNames(styles.clearFilters, {
            [styles.clearFiltersHidden]: totalFiltersApplied === 0,
          })}
          onClick={onClearAllFilters}
        >
          {t('Patients:FiltersPatientsForm.button.clearFilters')}
        </button>
      </div>
      {/* <div className={styles.filtersGroupHolder}>
        <h4 className={styles.filtersGroupHeading}>
          {t('Patients:FiltersPatientsForm.groupHeading.assignedHCP')}
        </h4>
        <div className={styles.filtersGroupContent}>
          {renderFilterItem(
            t('Patients:FiltersPatientsForm.filtersGroup.assignedHCP.any'),
            'any',
            state.assignedHCP, onChangeAssignedHCP
          )}
          {renderFilterItem(
            t('Patients:FiltersPatientsForm.filtersGroup.assignedHCP.assigned'),
            'assigned',
            state.assignedHCP, onChangeAssignedHCP
          )}
          {renderFilterItem(
            t('Patients:FiltersPatientsForm.filtersGroup.assignedHCP.notAssigned'),
            'notAssigned',
            state.assignedHCP, onChangeAssignedHCP
          )}
        </div>
      </div> */}
      <div className={styles.filtersGroupHolder}>
        <h4 className={styles.filtersGroupHeading}>
          {t('Patients:FiltersPatientsForm.groupHeading.diabetesType')}
        </h4>
        <div className={styles.filtersGroupContent}>
          <div className={styles.filterToggleItemHolder}>
            {/* {renderFilterItem(
              t('Patients:FiltersPatientsForm.filtersGroup.diabetesType.any'),
              'any',
              state.diabetesType, onChangeDiabetesType
            )} */}
            {renderFilterItem(
              t('Patients:FiltersPatientsForm.filtersGroup.diabetesType.typeOne'),
              DIABETES_TYPES.typeOne,
              state.diabetesType, onChangeDiabetesType
            )}
            {renderFilterItem(
              t('Patients:FiltersPatientsForm.filtersGroup.diabetesType.typeTwo'),
              DIABETES_TYPES.typeTwo,
              state.diabetesType, onChangeDiabetesType
            )}

            {renderFilterItem(
              t('Patients:FiltersPatientsForm.filtersGroup.diabetesType.typePre'),
              DIABETES_TYPES.pre,
              state.diabetesType, onChangeDiabetesType
            )}
            {renderFilterItem(
              t('Patients:FiltersPatientsForm.filtersGroup.diabetesType.typeOneParent'),
              DIABETES_TYPES.typeOneParent,
              state.diabetesType, onChangeDiabetesType
            )}
            {renderFilterItem(
              t('Patients:FiltersPatientsForm.filtersGroup.diabetesType.typeOneChild'),
              DIABETES_TYPES.typeOneChild,
              state.diabetesType, onChangeDiabetesType
            )}
            {/* {renderFilterItem(
              t('Patients:FiltersPatientsForm.filtersGroup.diabetesType.none'),
              DIABETES_TYPES.none,
              state.diabetesType, onChangeDiabetesType
            )} */}
          </div>
        </div>
      </div>
      {/* <div className={styles.filtersGroupHolder}>
        <h4 className={styles.filtersGroupHeading}>
          {t('Patients:FiltersPatientsForm.groupHeading.chronicConditions')}
        </h4>
        <div className={styles.filtersGroupContent}>
          {renderFilterItem(
            t('Patients:FiltersPatientsForm.filtersGroup.chronicConditions.any'),
            'any',
            state.chronicConditions, onChangeChronicConditions
          )}
          {renderFilterItem(
            t('Patients:FiltersPatientsForm.filtersGroup.chronicConditions.present'),
            'present',
            state.chronicConditions, onChangeChronicConditions
          )}
          {renderFilterItem(
            t('Patients:FiltersPatientsForm.filtersGroup.chronicConditions.notPresent'),
            'notPresent',
            state.chronicConditions, onChangeChronicConditions
          )}
        </div>
      </div> */}
      <div className={styles.filtersGroupHolder}>
        <h4 className={styles.filtersGroupHeading}>
          {t('Patients:FiltersPatientsForm.groupHeading.patientAge')}
        </h4>
        <div className={styles.filtersGroupContent}>
          {renderAgePicker()}
        </div>
      </div>
      {/* <div className={styles.filtersGroupHolder}>
        <h4 className={styles.filtersGroupHeading}>
          {t('Patients:FiltersPatientsForm.groupHeading.dateOfBirth')}
        </h4>
        <div className={styles.filtersGroupContent}>
          {renderBirthDatePicker()}
        </div>
      </div> */}
    </Card>
  );
}

export default FiltersPatientsForm;
