import React from "react";
import { all, put, takeEvery, call, select } from "redux-saga/effects";
import { get } from "lodash";

import { handleRequestError } from "services/api/apiTools";
import NotificationsService from "services/notifications/NotificationsService";
import SurveyService from "services/survey/SurveyService";

import { SURVEY_TYPES } from "constants/survey";

import * as actions from "./actions";
import * as ApiService from "services/api/ApiService";
import LanguageSelector from "сomponents/LanguageSelector/LanguageSelector";
import LanguageService from "services/language/LanguageService";

export function* createSurveyInstanceSaga(action) {
    const { patientEmail, surveyId } = action.payload;

    const doctorEmail = yield select((state) => state.auth.userAuthData.email);

    let data;

    try {
        data = yield call(ApiService.surveyInstanceCreate, {
            doctorEmail,
            patientEmail,
            surveyId,
        });
    } catch (errors) {
        handleRequestError(errors);
        yield put(actions.createSurveyInstance.failure());
        return;
    }

    yield put(actions.createSurveyInstance.success());
    yield put(actions.getSurveyInstancesList.start({ email: patientEmail }));
    NotificationsService.throwNotification(
        "success",
        LanguageService.t("UI:Success"),
        <>
            <a
                href={`/patient/results/${get(data, `id`, "")}`}
                target="_blank"
                rel="noreferrer"
            >
                {LanguageService.t("UI:PatientSurvey")}
            </a>{" "}
            {LanguageService.t("UI:hasBeenCreated")}
        </>,
        {
            isReactNode: true,
            duration: 0,
        }
    );
}

export function* getSurveyInstancesListSaga(action) {
    const { email } = action.payload;

    let data;

    try {
        data = yield call(ApiService.surveyInstancesListGet, encodeURIComponent(email));
    } catch (errors) {
        handleRequestError(errors);
        yield put(actions.getSurveyInstancesList.failure());
        return;
    }

    const processedData = {};
    const processedDataSequence = [];

    data.content.forEach((item) => {
        const {
            createTimestamp: instanceCreateTimestamp,
            id: instanceId,
            lastUpdatedTimestamp: instanceLastUpdatedTimestamp,
            status: instanceStatus,
            survey: { id: surveyId, name: surveyName },
        } = item;

        processedDataSequence.push(instanceId);

        processedData[instanceId] = {
            instanceCreateTimestamp,
            instanceId,
            instanceLastUpdatedTimestamp,
            instanceStatus,
            surveyId,
            surveyName,
        };
    });

    const meta = {
        empty: data.empty,
        first: data.first,
        last: data.last,
        number: data.number,
        numberOfElements: data.numberOfElements,
        size: data.size,
        totalElements: data.totalElements,
        totalPages: data.totalPages,
    };

    yield put(
        actions.getSurveyInstancesList.success({
            data: processedData,
            dataSequence: processedDataSequence,
            pageable: data.pageable,
            sort: data.sort,
            meta,
        })
    );
}

export function* getSurveyInstanceSaga(action) {
    const { id } = action?.payload ?? action; // read from action payload, or directly from input data in a case of direct saga call

    let data;

    try {
        data = yield call(ApiService.surveyInstanceGet, encodeURIComponent(id));
    } catch (errors) {
        handleRequestError(errors);
        yield put(actions.getSurveyInstance.failure());
        return;
    }

    const processedInstanceScoresData = {};
    const processedInstanceScoresDataSequence = [];

    data.scores.forEach((item) => {
        const {
            category,
            id,
            scorePct = 0, // TODO: refactor
            scoreValue,
            scoredAnswers,
        } = item;

        processedInstanceScoresDataSequence.push(category);

        processedInstanceScoresData[category] = {
            category,
            id,
            scorePct,
            scoreValue,
            scoredAnswers,
        };
    });

    const processedSurveyQuestionsData = {};
    const processedSurveyQuestionsDataSequence = [];

    data.survey.questions.forEach((item) => {
        const { category, id, possibleAnswers, question, stage, type } = item;

        processedSurveyQuestionsDataSequence.push(id);

        processedSurveyQuestionsData[id] = {
            category,
            id,
            possibleAnswers,
            question,
            stage,
            type,
        };
    });

    const processedSurveyRecommendationsData = {};
    const processedSurveyRecommendationsDataSequence = [];

    data.recommendations.forEach((item) => {
        const { id, possibleAnswers, question, answers } = item;

        processedSurveyRecommendationsDataSequence.push(id);

        processedSurveyRecommendationsData[id] = {
            id,
            possibleAnswers,
            question,
            answers,
        };
    });

    yield put(
        actions.getSurveyInstance.success({
            instanceCreateTimestamp: data.createTimestamp,
            instanceId: data.id,
            instanceLastUpdatedTimestamp: data.lastUpdatedTimestamp,
            instanceStage: data.stage,
            instanceStatus: data.status,
            // instanceAnswers: { // by question.id
            //   data: processedInstanceAnswersData,
            //   dataSequence: processedInstanceAnswersDataSequence,
            // },
            instanceAnswers: data.answers,
            instanceScores: {
                // by id
                data: processedInstanceScoresData,
                dataSequence: processedInstanceScoresDataSequence,
            },
            instanceRecommendations: {
                data: processedSurveyRecommendationsData,
                dataSequence: processedSurveyRecommendationsDataSequence,
            },
            survey: data.survey,
            user: data.user,
            surveyId: data.survey.id,
            surveyName: data.survey.name,
            surveyQuestions: {
                // by id
                data: processedSurveyQuestionsData,
                dataSequence: processedSurveyQuestionsDataSequence,
            },
            instancePossibleConclusions: data.possibleConclusions,
            instanceConclusions: data.conclusions,
            instanceMainAreasOfFocus: data.mainAreasOfFocus,
        })
    );
}

export function* getSurveysListSaga() {
    let data;

    try {
        data = yield call(ApiService.surveysListGet);
    } catch (errors) {
        handleRequestError(errors);
        yield put(actions.getSurveysList.failure());
        return;
    }

    const processedData = {};
    const processedDataSequence = [];

    data.content
        .sort((a, b) => a.revision - b.revision)
        .toReversed()
        .sort((a, b) => a.name.localeCompare(b.name))
        .forEach((item) => {
            const { diabetesType, id, name, surveyType, revision, isAvailable } = item;

            processedDataSequence.push(id);

            processedData[id] = {
                diabetesType,
                id,
                name,
                surveyType,
                revision,
                isAvailable,
            };
        });

    const meta = {
        empty: data.empty,
        first: data.first,
        last: data.last,
        number: data.number,
        numberOfElements: data.numberOfElements,
        size: data.size,
        totalElements: data.totalElements,
        totalPages: data.totalPages,
    };

    yield put(
        actions.getSurveysList.success({
            data: processedData,
            dataSequence: processedDataSequence,
            pageable: data.pageable,
            sort: data.sort,
            meta,
        })
    );
}

export function* getSurveySaga(action) {
    const { id } = action.payload;

    try {
        yield call(ApiService.surveyGet, id);
    } catch (errors) {
        handleRequestError(errors);
        yield put(actions.getSurvey.failure());
        return;
    }

    yield put(actions.getSurvey.success());
}

export function* updateSurveyInstanceSaga(action) {
    const { id: surveyInstanceId, data: surveyData } = action?.payload ?? action; // read from action payload, or directly from input data in a case of direct saga call

    let data;

    try {
        data = yield call(ApiService.surveyInstanceUpdate, surveyInstanceId, surveyData);
    } catch (errors) {
        // handleRequestError(errors);  DEMO
        yield put(actions.updateSurveyInstance.failure());
        return;
    }

    if (data.refresh) {
        yield call(getSurveyInstanceSaga, { id: surveyInstanceId });
    }

    yield put(
        actions.updateSurveyInstance.success({
            message: data.message,
        })
    );

    // yield put(actions.getSurveyInstancesList.start({email: doctorEmail})); // TODO: implement status update in some other way
}

export function* updateSurveyInstanceRecommendationsSaga(action) {
    const { id: surveyInstanceId, data: surveyData } = action.payload;

    try {
        yield call(
            ApiService.surveyInstanceRecommendationsUpdate,
            surveyInstanceId,
            surveyData
        );
    } catch (errors) {
        handleRequestError(errors);
        yield put(actions.updateSurveyInstanceRecommendations.failure());
        return;
    }

    yield put(actions.updateSurveyInstanceRecommendations.success());

    yield put(
        // refresh to get actual status
        actions.getSurveyInstance.start({ id: surveyInstanceId })
    );
}

export function* updateSurveyInstanceConclusionSaga(action) {
    const { id: surveyInstanceId, data: conclusionData } = action.payload;

    try {
        yield call(
            ApiService.surveyInstanceConclusionUpdate,
            surveyInstanceId,
            conclusionData
        );
    } catch (errors) {
        handleRequestError(errors);
        yield put(actions.updateSurveyInstanceConclusion.failure());
        return;
    }

    yield put(actions.updateSurveyInstanceConclusion.success());

    yield put(
        // refresh to get actual status
        actions.getSurveyInstance.start({ id: surveyInstanceId })
    );
}

export function* submitSurveyInstanceSaga(action) {
    const {
        id: surveyInstanceId,
        stage: surveyInstanceStage,
        surveyType,
    } = action?.payload ?? action; // read from action payload, or directly from input data in a case of direct saga call

    try {
        yield call(ApiService.surveyInstanceSubmit, surveyInstanceId, surveyInstanceStage);
    } catch (errors) {
        handleRequestError(errors);
        yield put(actions.submitSurveyInstance.failure());
        return;
    }

    if (surveyType === SURVEY_TYPES.regular) {
        yield put(actions.getSurveyInstance.start({ id: surveyInstanceId }));
    }

    if (surveyType === SURVEY_TYPES.regular && SurveyService.isMedicalSecondWaveStage()) {
        const userEmail = yield select((state) => state?.surveys?.surveyInstance?.user?.email);
        yield put(actions.getSurveyInstancesList.start({ email: userEmail }));
    }

    yield put(actions.submitSurveyInstance.success());
}

export function* exportSurveysInstancesSaga() {
    try {
        yield call(ApiService.surveysInstancesExport);
    } catch (errors) {
        handleRequestError(errors);
        yield put(actions.exportSurveysInstances.failure());
        return;
    }

    yield put(actions.exportSurveysInstances.success());
}

export function* createOneTimeSurveyInstanceSaga(action) {
    const {
        surveyId,
        // firstName,
        // lastName,
    } = action.payload;

    let responseData;

    try {
        responseData = yield call(
            ApiService.oneTimeSurveyInstanceCreate,
            surveyId
            // firstName,
            // lastName
        );
    } catch (errors) {
        handleRequestError(errors);
        yield put(actions.createOneTimeSurveyInstance.failure());
        return;
    }

    let modalHeading = "Successfully!";
    let modalContent = [];

    if (responseData?.userLogin) {
        modalContent.push(`Login: ${responseData.userLogin}`);
    } else {
        modalHeading = "Warning:";
    }

    if (responseData?.userPassword) {
        modalContent.push(`Passowd: ${responseData.userPassword}`);
    } else {
        modalHeading = "Warning:";
    }

    NotificationsService.showNotificationModal(modalHeading, modalContent);

    yield put(actions.createOneTimeSurveyInstance.success());
}

export function* surveysSagas() {
    yield all([
        yield takeEvery(actions.createSurveyInstance.start, createSurveyInstanceSaga),

        yield takeEvery(actions.getSurveyInstancesList.start, getSurveyInstancesListSaga),
        yield takeEvery(actions.getSurveyInstance.start, getSurveyInstanceSaga),

        yield takeEvery(actions.getSurveysList.start, getSurveysListSaga),
        yield takeEvery(actions.getSurvey.start, getSurveySaga),

        yield takeEvery(actions.updateSurveyInstance.start, updateSurveyInstanceSaga),
        yield takeEvery(
            actions.updateSurveyInstanceRecommendations.start,
            updateSurveyInstanceRecommendationsSaga
        ),
        yield takeEvery(
            actions.updateSurveyInstanceConclusion.start,
            updateSurveyInstanceConclusionSaga
        ),

        yield takeEvery(actions.submitSurveyInstance.start, submitSurveyInstanceSaga),

        yield takeEvery(actions.exportSurveysInstances.start, exportSurveysInstancesSaga),

        yield takeEvery(
            actions.createOneTimeSurveyInstance.start,
            createOneTimeSurveyInstanceSaga
        ),
    ]);
}
