import React from 'react';
import { useTranslation } from 'react-i18next';

import { USER_ROLES } from 'constants/userRoles';

import ContentWithHeaderLayout from 'layouts/pageContentLayouts/ContentWithHeaderLayout/ContentWithHeaderLayout';
import ListTablePageLayout from 'layouts/pageContentLayouts/ListTablePageLayout/ListTablePageLayout';

import AddUser from 'сomponents/AddUser/AddUser';
import ListTablePatients from 'сomponents/ListTablePatients/ListTablePatients';

function AdminPatientsListView() {
  const { t } = useTranslation();

  return (
    <ContentWithHeaderLayout
      headerTitle={t('Patients:AdminPatientsListView.header.heading')}
    >
      <ListTablePageLayout
        headerContent={
          <AddUser userTypeToAdd={USER_ROLES.patient}/>
        }
        mainContent={
          <ListTablePatients />
        }
      />
    </ContentWithHeaderLayout>
  );
}

export default AdminPatientsListView;
