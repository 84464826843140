import React from "react";
import MarkdownHandler from "componentsShared/MarkdownHandler/MarkdownHandler";

import CookiesPolicyFileEn from "locales/CookiesPolicy_en-GB.md";
import CookiesPolicyFileEs from "locales/CookiesPolicy_es-ES.md";

import styles from "styles/commonModules/Document.module.scss";

function CookiesPolicyPage() {
    return (
        <div className={styles.mainHolder}>
            <MarkdownHandler
                translations={{
                    en: CookiesPolicyFileEn,
                    es: CookiesPolicyFileEs,
                }}
            />
        </div>
    );
}

export default CookiesPolicyPage;
