import { createActions } from 'redux-actions';

export const { 
  notificationModal,
} = createActions(
  {
    NOTIFICATION_MODAL: {
      SHOW: ({ heading, content }) => ({ heading, content }),
      HIDE: undefined,
    },
  },
  { prefix: 'UI' }
);
