import React, {useRef, useReducer, useState, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button } from 'element-react';

import NavigationService from 'services/navigation/NavigationService';

import * as authActions from 'store/auth/actions';

import { 
  checkIfHasUpperCase,
  checkIfHasLowerCase,
  checkIfHasNumber,
  checkIfHasSpecialCharacter
} from 'helpers/helpers';

import customComponentsStyles from 'styles/custom-components.module.scss';
import styles from './ChangePassword.module.scss';

const initialFormState = {
  password: '',
  newPassword: '',
};

function reducer(state, action) {
  switch (action.type) {
    case 'setPassword':
      return {...state, password: action.payload};
    case 'setNewPassword':
      return {...state, newPassword: action.payload};
    default:
      throw new Error();
  }
}

function ChangePassword() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formRef = useRef(null);

  const [submitButtonIsEnabled, setSubmitButtonIsEnabled] = useState(false);
  const [state, dispatchToLocalState] = useReducer(reducer, initialFormState);

  const validationRules = {
    password: [
      {
        required: true,
        message: t('Auth:SignIn.form.validation.warningMessage.passwordIsEmpty'),
        trigger: 'blur'
      },
      { 
        validator: (rule, value, callback) => {
          const errorMessages = [];
          if (value === '') {
            callback(new Error(t('Auth:SignIn.form.validation.errorMessage.passwordIsEmpty')));
          }
          if (value.length < 8) {
            errorMessages.push(t('Auth:SignIn.form.validation.errorMessage.passwordTooShort'));
          }
          if (!checkIfHasNumber(value)) {
            errorMessages.push(t('Auth:SignIn.form.validation.errorMessage.passwordShouldContainNumbers'));
          }
          if (!checkIfHasUpperCase(value)) {
            errorMessages.push(t('Auth:SignIn.form.validation.errorMessage.passwordShouldContainUppercase'));
          }
          if (!checkIfHasLowerCase(value)) {
            errorMessages.push(t('Auth:SignIn.form.validation.errorMessage.passwordShouldContainLowercase'));
          }
          if (!checkIfHasSpecialCharacter(value)) {
            errorMessages.push(t('Auth:SignIn.form.validation.errorMessage.newPasswordShouldContainSpecial'));
          }

          const passwordErrorMessagePrefix = t('Auth:SignIn.form.validation.password.errorMessagePrefix');

          if (errorMessages.length !== 0) {
            setSubmitButtonIsEnabled(false);
            callback(new Error(`${passwordErrorMessagePrefix} ${errorMessages.join(', ')}`));
          } else {
            setSubmitButtonIsEnabled(true);
            callback();
          }
        } 
      }
    ],
    newPassword: [
      {
        required: true,
        message: t('Auth:SignIn.form.validation.warningMessage.newPasswordIsEmpty'),
        trigger: 'blur'
      },
      {
        validator: (rule, value, callback) => {
          const errorMessages = [];
          if (value === '') {
            callback(new Error(t('Auth:SignIn.form.validation.errorMessage.newPasswordIsEmpty')));
          }
          if (value.length < 8) {
            errorMessages.push(t('Auth:SignIn.form.validation.errorMessage.newPasswordTooShort'));
          }
          if (!checkIfHasNumber(value)) {
            errorMessages.push(t('Auth:SignIn.form.validation.errorMessage.newPasswordShouldContainNumbers'));
          }
          if (!checkIfHasUpperCase(value)) {
            errorMessages.push(t('Auth:SignIn.form.validation.errorMessage.newPasswordShouldContainUppercase'));
          }
          if (!checkIfHasLowerCase(value)) {
            errorMessages.push(t('Auth:SignIn.form.validation.errorMessage.newPasswordShouldContainLowercase'));
          }
          if (!checkIfHasSpecialCharacter(value)) {
            errorMessages.push(t('Auth:SignIn.form.validation.errorMessage.newPasswordShouldContainSpecial'));
          }

          const passwordErrorMessagePrefix = t('Auth:SignIn.form.validation.newPassword.errorMessagePrefix');

          if (errorMessages.length !== 0) {
            setSubmitButtonIsEnabled(false);
            callback(new Error(`${passwordErrorMessagePrefix} ${errorMessages.join(', ')}`));
          } else {
            setSubmitButtonIsEnabled(true);
            callback();
          }
        } 
      }
    ],
  };

  const onPasswordChange = value => {
    dispatchToLocalState({type: 'setPassword', payload: value});
  };

  const onNewPasswordChange = value => {
    dispatchToLocalState({type: 'setNewPassword', payload: value});
  };

  const clearFormFields = () => {
    dispatchToLocalState({type: 'setPassword', payload: ''});
    dispatchToLocalState({type: 'setNewPassword', payload: ''});
  };

  const onSubmit = e => {
    e.preventDefault();


    formRef.current.validate((valid) => {
      if (valid) {
        dispatch(authActions.changePassword.start({
          oldPassword: state.password,
          newPassword: state.newPassword,
        }));
        formRef.current.resetFields();
        clearFormFields(); // it seems that resetFields() can't handle state created by useReducer
      } else {
        console.log('submit error!');
        return false;
      }
    });
  };

  const onBack = () => {
    formRef.current.resetFields();
    clearFormFields(); // it seems that resetFields() can't handle state created by useReducer
    NavigationService.navigateToDefaultPath();
  };

  return (
    <Fragment>
      <div className={styles.card}>
        <Form ref={formRef} rules={validationRules} model={state} className={styles.form}>
          <Form.Item prop="password">
            <Input
              type="password"
              placeholder={t('Auth:ChangePassword.form.input.password.placeholder')}
              value={state.password}
              onChange={onPasswordChange}
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item prop="newPassword">
            <Input
              type="password"
              placeholder={t('Auth:ChangePassword.form.input.newPassword.placeholder')}
              value={state.newPassword}
              onChange={onNewPasswordChange}
              autoComplete="off"
            />
          </Form.Item>
          <Button
            onClick={onSubmit}
            style={{ width: '100%' }}
            disabled={!submitButtonIsEnabled}
            className={customComponentsStyles.buttonCustom}
          >
            {t('Auth:ChangePassword.form.button.changePassword')}
          </Button>
        </Form>
      </div>
      <div className={styles.commonControlsHolder}>
        <Button
          onClick={onBack}
          className={customComponentsStyles.buttonCustom}
        >
          {t('Auth:ChangePassword.commonControls.button.backToDashboard')}
        </Button>
      </div>
    </Fragment>
  );
}

export default ChangePassword;
