import { handleActions } from 'redux-actions';

import * as actions from './actions';

export const initialState = {
  notificationModal: {
    isVisible: false,
    heading: '',
    content: [], // strings arr
  }
};

export const uiReducer = handleActions(
  {
    [actions.notificationModal.show]: (state, {payload}) => ({
      ...state,
      notificationModal: {
        ...state.notificationModal,
        isVisible: true,
        heading: payload.heading,
        content: payload.content,
      }
    }),
    [actions.notificationModal.hide]: (state) => ({
      ...state,
      notificationModal: {
        ...state.notificationModal,
        isVisible: false,
        heading: '',
        content: [],
      }
    }),
  },
  initialState
);
