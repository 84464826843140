import Amplify, { Auth } from 'aws-amplify';
import { AMPLIFY_AUTH_PARAMS } from 'constants/amplifyAuthParams';

export const setAmplifyConfig = (userRole) => {
  const userPoolId = AMPLIFY_AUTH_PARAMS[userRole].userPoolId;
  const userPoolWebClientId = AMPLIFY_AUTH_PARAMS[userRole].userPoolWebClientId;

  Amplify.configure({
    Auth: {
      region: 'eu-west-2',
      userPoolId,
      userPoolWebClientId,
      oauth: {
        scope: ['email', 'profile', 'openid'],
      }
    }
  });
};

export const getCurrentAmplifyConfig = () => { // returns selected amplify config
  return Auth.configure();
};
