import { fork } from 'redux-saga/effects';

import { authSagas } from 'store/auth/sagas';
import { surveysSagas } from 'store/surveys/sagas';
import { doctorsSagas } from 'store/doctors/sagas';
import { patientsSagas } from 'store/patients/sagas';
import { auxSurveysSagas } from 'store/auxSurveys/sagas';
import { notesSagas } from 'store/notes/sagas';
import { clinicsSagas } from 'store/clinics/sagas';
import { adminsSagas } from 'store/admins/sagas';

export default function* rootSaga() {
  yield fork(authSagas);
  yield fork(surveysSagas);
  yield fork(doctorsSagas);
  yield fork(patientsSagas);
  yield fork(auxSurveysSagas);
  yield fork(notesSagas);
  yield fork(clinicsSagas);
  yield fork(adminsSagas);
}
