import { handleActions } from 'redux-actions';

import * as actions from './actions';

export const initialState = {
  isLoadingCreate: false,
  isLoadingGetList: false,
  isLoadingGet: false,
  isLoadingUpdate: false,
  isLoadingRemove: false,
  notesList: {
    data: {},
    dataSequence: [],
    pageable: {},
    sort: {},
    meta: {},
  },
  selectedNote: {

  },
};

export const notesReducer = handleActions(
  {
    [actions.createNote.start]: (state) => ({
      ...state,
      isLoadingCreate: true,
    }),
    [actions.createNote.success]: (state) => ({
      ...state,
      isLoadingCreate: false,
    }),
    [actions.createNote.failure]: (state) => ({
      ...state,
      isLoadingCreate: false,
    }),

    [actions.getNotesList.start]: (state) => ({
      ...state,
      isLoadingGetList: true,
    }),
    [actions.getNotesList.success]: (state, { payload }) => ({
      ...state,
      isLoadingGetList: false,
      notesList: {
        ...state.notesList,
        data: payload.data,
        dataSequence: payload.dataSequence,
        pageable: payload.pageable,
        sort: payload.sort,
        meta: payload.meta,
      }
    }),
    [actions.getNotesList.failure]: (state) => ({
      ...state,
      isLoadingGetList: false,
    }),

    [actions.getNote.start]: (state) => ({
      ...state,
      isLoadingGet: true,
    }),
    [actions.getNote.success]: (state) => ({
      ...state,
      isLoadingGet: false,
    }),
    [actions.getNote.failure]: (state) => ({
      ...state,
      isLoadingGet: false,
    }),

    [actions.updateNote.start]: (state) => ({
      ...state,
      isLoadingUpdate: true,
    }),
    [actions.updateNote.success]: (state) => ({
      ...state,
      isLoadingUpdate: false,
    }),
    [actions.updateNote.failure]: (state) => ({
      ...state,
      isLoadingUpdate: false,
    }),

    [actions.removeNote.start]: (state) => ({
      ...state,
      isLoadingRemove: true,
    }),
    [actions.removeNote.success]: (state) => ({
      ...state,
      isLoadingRemove: false,
    }),
    [actions.removeNote.failure]: (state) => ({
      ...state,
      isLoadingRemove: false,
    }),

  },
  initialState
);
