import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import ContentWithHeaderLayout from 'layouts/pageContentLayouts/ContentWithHeaderLayout/ContentWithHeaderLayout';

function DoctorHelpView() {
  const { t } = useTranslation();

  return (
    <ContentWithHeaderLayout
      padding
      bg
      headerTitle={t('Resources:DoctorHelpView.header.heading')}
    >
      <Fragment>
        {t('Resources:DoctorHelpView.helpDescription')} <a href="mailto:info@spotlightconsultations.com">info@spotlightconsultations.com</a>
      </Fragment>
    </ContentWithHeaderLayout>
  );
}

export default DoctorHelpView;
