import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import PatientSurveyResultsList from 'сomponents/PatientSurveyResults/PatientSurveyResultsList/PatientSurveyResultsList';
// import PatientSurveyResultsDetails from 'сomponents/PatientSurveyResults/PatientSurveyResultsDetails/PatientSurveyResultsDetails';
import UserService from 'services/user/UserService';
// import PropTypes from 'prop-types';
import { get } from 'lodash';
// import PatientSurvey from 'сomponents/PatientSurveys/PatientSurvey/PatientSurvey';
// import SurveysList from 'сomponents/Surveys/SurveysList/SurveysList';
import SurveysResults from 'сomponents/Surveys/SurveysResults/SurveysResults';
import SurveyQuestionnaire from 'сomponents/Surveys/SurveyQuestionnaire/SurveyQuestionnaire';
import { SURVEY_INSTANCE_STATUSES } from 'constants/surveyInstance';
import { useSelector } from 'react-redux';
import SurveyResultsLoader from '../../сomponents/Surveys/SurveysResults/SurveyResultsLoader/SurveyResultsLoader';
import { Card } from 'element-react';
import Header from 'сomponents/Header/Header';

// import * as authActions from 'store/auth/actions';
import * as surveysActions from 'store/surveys/actions';

// import Terms from 'сomponents/Terms/Terms';

import styles from './OneTimePatientSurveyPage.module.scss';

function OneTimePatientSurveyPage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const surveyInstancesListDataSequence = useSelector(state => state?.surveys?.surveyInstancesList?.dataSequence) ?? [];

  const surveyStatus = useSelector(
    (state) => get(state.surveys.surveyInstance, 'instanceStatus', null)
  );

  useEffect(() => {
    dispatch(surveysActions.getSurveyInstancesList.start({email: UserService.getCurrentUserEmail()}));
  }, []);

  useEffect(() => {
    if(surveyInstancesListDataSequence[0]) {
      dispatch(surveysActions.getSurveyInstance.start({id: surveyInstancesListDataSequence[0]}));
    }
  }, [surveyInstancesListDataSequence]);

  const renderDetailsSection = () => {
    if(surveyInstancesListDataSequence.length === 0) {
      return ( // TODO: refactor
        <Card className={styles.detailsPlaceholderCard}>
          <SurveyResultsLoader />
        </Card>
      );
    }

    if (surveyStatus === SURVEY_INSTANCE_STATUSES.created || surveyStatus === SURVEY_INSTANCE_STATUSES.inProgress) {
      return <SurveyQuestionnaire surveyInstanceId={surveyInstancesListDataSequence[0]} />;
    }

    if(surveyStatus !== SURVEY_INSTANCE_STATUSES.created && surveyStatus !== SURVEY_INSTANCE_STATUSES.inProgress) {
      return <SurveysResults surveyInstanceId={surveyInstancesListDataSequence[0]} />;
    }

    return <div>{t('Surveys:PatientResultsView.placeholder.surveyError')}</div>;
  };

  return (
    <div className={styles.outerContainer}>
      <Header headerTitle={'Survey'} />
      <div className={styles.container}>
        {renderDetailsSection()}
      </div>
    </div>
  );
}

export default OneTimePatientSurveyPage;
