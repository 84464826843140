import React from 'react';
import PropTypes from 'prop-types';

import styles from './ListTablePageLayout.module.scss';

ListTablePageLayout.propTypes = {
  headerContent: PropTypes.object,
  mainContent: PropTypes.object,
};

function ListTablePageLayout ({ headerContent, mainContent }) {
  return (
    <div className={styles.rootWrapper}>
      <div className={styles.header}>
        {headerContent}
      </div>
      {mainContent}
    </div>
  );
}

export default ListTablePageLayout;
