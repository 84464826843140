import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "styles/commonModules/ResourcesList.module.scss";
import { getPatientResources } from "services/api/ApiService";

function PatientResourcesList() {
    const [resources, setResources] = useState([]);

    useEffect(() => {
        getPatientResources().then((response) => {
            setResources(response);
        });
    }, []);

    return (
        <ul className={styles.resourcesList}>
            {resources.map((link) => {
                return (
                    <li key={link.id} className={styles.resourcesListItem}>
                        <Link
                            to={{
                                pathname: link.url,
                            }}
                            target="_blank"
                        >
                            {link.name}
                        </Link>
                    </li>
                );
            })}
        </ul>
    );
}

export default PatientResourcesList;
