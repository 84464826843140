import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import styles from "./QuestionsWithAnswersByTag.module.scss";

QuestionsWithAnswersByTag.propTypes = {
    tag: PropTypes.string.isRequired,
};

function QuestionsWithAnswersByTag({ tag }) {
    const instanceAnswersFilteredByTag =
        useSelector((state) =>
            state.surveys?.surveyInstance?.instanceAnswers?.filter(({ question }) =>
                question.tags?.includes(tag)
            )
        ) || [];

    return instanceAnswersFilteredByTag.map(({ question, answer }) => {
        return (
            <div key={question.id} className={styles.section}>
                <h2 className={styles.sectionHeading}>{question.question}</h2>
                <p className={styles.sectionDescription}>{answer.answer}</p>
            </div>
        );
    });
}

export default QuestionsWithAnswersByTag;
