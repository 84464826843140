import { createActions } from "redux-actions";

export const { createClinic, getClinic, getClinicsList } = createActions(
    {
        CREATE_CLINIC: {
            START: ({ name }) => ({ name }),
            SUCCESS: undefined,
            FAILURE: undefined,
        },
        GET_CLINIC: {
            START: ({ clinicId }) => ({ clinicId }),
            SUCCESS: ({ id, name, locale }) => ({
                id,
                name,
                locale,
            }),
            FAILURE: undefined,
        },
        GET_CLINICS_LIST: {
            START: ({ pageSize, pageNumber }) => ({
                pageSize,
                pageNumber,
            }),
            SUCCESS: undefined,
            FAILURE: undefined,
        },
    },
    { prefix: "CLINICS" }
);
