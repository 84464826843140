import patient from "images/icons/patient.svg";
import home from "images/icons/home.svg";
import notes from "images/icons/notes.svg";
import results from "images/icons/results.svg";
import doctor from "images/icons/doctor.svg";
import admin from "images/icons/admin.svg";
import clinic from "images/icons/clinic.svg";
import resources from "images/icons/resources.svg";
import help from "images/icons/help.svg";
import exportIcon from "images/icons/export.svg";

import { ROUTES } from "constants/routes";

export const superadminItems = [
    {
        id: "sa1",
        path: ROUTES.SUPERADMIN_RESULTS.path,
        icon: exportIcon,
        captionID: "UI:SidebarNav.menuItem.superadmin.reports.caption",
    },
    {
        id: "sa2",
        path: ROUTES.SUPERADMIN_CLINICS_LIST.path,
        icon: clinic,
        captionID: "UI:SidebarNav.menuItem.superadmin.clinics.caption",
    },
    {
        id: "sa3",
        path: ROUTES.SUPERADMIN_ADMINS_LIST.path,
        icon: admin,
        captionID: "UI:SidebarNav.menuItem.superadmin.admins.caption",
    },
    {
        id: "sa4",
        path: ROUTES.SUPERADMIN_DOCTORS_LIST.path,
        icon: doctor,
        captionID: "UI:SidebarNav.menuItem.superadmin.doctors.caption",
    },
    {
        id: "sa5",
        path: ROUTES.SUPERADMIN_PATIENTS_LIST.path,
        icon: patient,
        captionID: "UI:SidebarNav.menuItem.superadmin.patients.caption",
    },
    { id: "separator" },
];

export const adminItems = [
    {
        id: "a1",
        path: ROUTES.ADMIN_DOCTORS_LIST.path,
        icon: doctor,
        captionID: "UI:SidebarNav.menuItem.admin.doctors.caption",
    },
    {
        id: "a2",
        path: ROUTES.ADMIN_PATIENTS_LIST.path,
        icon: patient,
        captionID: "UI:SidebarNav.menuItem.admin.patients.caption",
    },
    { id: "separator" },
];

export const doctorItems = [
    {
        id: "d1",
        path: ROUTES.DOCTOR_USERS_LIST.path,
        icon: patient,
        captionID: "UI:SidebarNav.menuItem.doctor.patients.caption",
    },
    {
        id: "d2",
        path: ROUTES.DOCTOR_RESOURCES.path,
        icon: resources,
        captionID: "UI:SidebarNav.menuItem.doctor.resources.caption",
    },
    { id: "separator" },
    {
        id: "d7",
        path: "//www.spotlight-aq.com/help",
        icon: help,
        captionID: "UI:SidebarNav.menuItem.doctor.help.caption",
        isTargetBlank: true,
    },
];

export const patientItems = [
    {
        id: "p1",
        path: ROUTES.PATIENT_HOME.path,
        icon: home,
        captionID: "UI:SidebarNav.menuItem.patient.home.caption",
    },
    {
        id: "p2",
        path: ROUTES.PATIENT_NOTES.path,
        icon: notes,
        captionID: "UI:SidebarNav.menuItem.patient.notes.caption",
    },
    {
        id: "p3",
        path: ROUTES.PATIENT_RESULTS.path,
        icon: results,
        captionID: "UI:SidebarNav.menuItem.patient.results.caption",
    },
    { id: "separator" },
];
