import React from 'react';
import PropTypes from 'prop-types';

import styles from './CleanPageLayout.module.scss';

CleanPageLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

function CleanPageLayout({children}) {
  return (
    <div className={styles.mainHolder}>
      <div className={styles.contentHolder}>{children}</div>
    </div>
  );
}

export default CleanPageLayout;
