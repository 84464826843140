import { handleActions } from 'redux-actions';

import * as actions from './actions';

export const initialState = {
  // isLoadingCheckIsMissing: false,
  isLoadingGetAuxSurveyInstance: false,
  isLoadingUpdateAuxSurveyInstance: false,
  auxSurveyInstance: {},
  // missingSurveys: [],
};

export const auxSurveysReducer = handleActions(
  {
    // [actions.checkIsMissing.start]: (state) => ({
    //   ...state,
    //   isLoadingCheckIsMissing: true,
    // }),
    // [actions.checkIsMissing.success]: (state, { payload }) => ({
    //   ...state,
    //   isLoadingCheckIsMissing: false,
    //   missingSurveys: payload.missingSurveys,
    // }),
    // [actions.checkIsMissing.failure]: (state) => ({
    //   ...state,
    //   isLoadingCheckIsMissing: false,
    // }),

    [actions.getAuxSurveyInstance.start]: (state) => ({
      ...state,
      isLoadingGetAuxSurveyInstance: true,
    }),
    [actions.getAuxSurveyInstance.success]: (state, { payload }) => ({
      ...state,
      isLoadingGetAuxSurveyInstance: false,
      auxSurveyInstance: {
        ...state.auxSurveyInstance,
        instanceCreateTimestamp: payload.instanceCreateTimestamp,
        instanceId: payload.instanceId,
        instanceLastUpdatedTimestamp: payload.instanceLastUpdatedTimestamp,
        instanceStage: payload.instanceStage,
        instanceStatus: payload.instanceStatus,
        instanceAnswers: payload.instanceAnswers,
        user: payload.user,
        survey: payload.survey,
      }
    }),
    [actions.getAuxSurveyInstance.failure]: (state) => ({
      ...state,
      isLoadingGetAuxSurveyInstance: false,
    }),

    [actions.updateAuxSurveyInstance.start]: (state) => ({
      ...state,
      isLoadingUpdateAuxSurveyInstance: true,
    }),
    [actions.updateAuxSurveyInstance.success]: (state) => ({
      ...state,
      isLoadingUpdateAuxSurveyInstance: false,
    }),
    [actions.updateAuxSurveyInstance.failure]: (state) => ({
      ...state,
      isLoadingUpdateAuxSurveyInstance: false,
    }),

  },
  initialState
);
