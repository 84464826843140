import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import LanguageService from "services/language/LanguageService";
import CookiesService from "services/cookies/CookiesService";

import { LANGUAGES } from "constants/languages";

import styles from "./LanguageSelector.module.scss";
import { setUserLocale } from "services/api/ApiService";
import { useSelector } from "react-redux";

export const mappedLocales = {
    en: "en-GB",
    es: "es-ES",
};

function LanguageSelector() {
    const { t, i18n } = useTranslation();
    const isAuthorized = useSelector((state) => state.auth.isAuthorized) || false;
    const [isLoading, setLoading] = useState(false);

    const onLanguageChange = (e) => {
        const languageCode = e.target.value;

        const setLanguageLocally = () => {
            document.documentElement.setAttribute("lang", languageCode);
            LanguageService.changeLanguage(languageCode);
            CookiesService.setLanguageCookie(languageCode);
        };

        if (isAuthorized) {
            setLoading(true);
            setUserLocale(mappedLocales[languageCode])
                .then(() => {
                    setLanguageLocally();
                    location.reload();
                })
                .catch((e) => {
                    throw Error(e);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLanguageLocally();
        }
    };

    return (
        <select
            className={styles.languageSelector}
            value={i18n.language}
            onChange={onLanguageChange}
            disabled={isLoading}
        >
            <option value={LANGUAGES.english}>
                {t("UI:LanguageSelector.option.english")}
            </option>
            <option value={LANGUAGES.spanish}>
                {t("UI:LanguageSelector.option.spanish")}
            </option>
        </select>
    );
}

export default LanguageSelector;
