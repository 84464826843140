import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { ROUTES } from 'constants/routes';
import { PAGE_SIZE } from 'constants/pagination';

import * as doctorsActions from 'store/doctors/actions';

import HistoryService from 'services/history/HistoryService';

import ListTable from 'componentsShared/ListTable/ListTable';

function ListTableDoctors () {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const data = useSelector(state => state?.doctors?.data) ?? {};
  const dataSequence = useSelector(state => state?.doctors?.dataSequence) ?? [];
  const totalElements = useSelector(state => state?.doctors?.meta?.totalElements) ?? 0;

  useEffect(() => {
    const doctorsListProcessed = dataSequence.map((key) => {
      const item = data[key];
      return {
        fullName: `${item.firstName} ${item.lastName}`,
        clinic: item.clinic,
        email: item.email,
      };
    });

    setDoctorsList(doctorsListProcessed);
  }, [data, dataSequence]);

  useEffect(() => {
    dispatch(doctorsActions.getDoctorsList.start({pageSize: PAGE_SIZE, pageNumber: 0}));
  }, []);

  const [doctorsList, setDoctorsList] = useState([]);

  const columns = [
    {
      label: t('Doctors:ListTableDoctors.table.column.label.fullName'),
      prop: 'fullName',
      width: 320,
      sortable: false,
    },
    {
      label: t('Doctors:ListTableDoctors.table.column.label.email'),
      prop: 'email',
      sortable: false,
    }
  ];

  function onTableRowClick(rowData) {
    HistoryService.push(`/${ROUTES.ADMIN_DOCTOR_PROFILE.path}/${rowData.email}`);
  }

  const onPaginationChange = (page) => {
    dispatch(doctorsActions.getDoctorsList.start({pageSize: PAGE_SIZE, pageNumber: page - 1}));
  };

  return (
    <ListTable
      columns={columns}
      onTableRowClick={onTableRowClick}
      tableData={doctorsList}
      withPagination={true}
      totalElements={totalElements}
      onPaginationChange={onPaginationChange}
      isWithoutBorders
    />
  );
}

export default ListTableDoctors;
