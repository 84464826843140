import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';

import * as rootActions from './rootActions';

import { authReducer } from 'store/auth/reducer';
import { surveysReducer } from 'store/surveys/reducer';
import { doctorsReducer } from 'store/doctors/reducer';
import { patientsReducer } from 'store/patients/reducer';
import { auxSurveysReducer } from 'store/auxSurveys/reducer';
import { notesReducer } from 'store/notes/reducer';
import { clinicsReducer } from 'store/clinics/reducer';
import { adminsReducer } from 'store/admins/reducer';
import { uiReducer } from 'store/ui/reducer';

import { initialState as authInitialState } from 'store/auth/reducer';
import { initialState as auxSurveysInitialState } from 'store/auxSurveys/reducer';
import { initialState as doctorsInitialState } from 'store/doctors/reducer';
import { initialState as notesInitialState } from 'store/notes/reducer';
import { initialState as patientsInitialState } from 'store/patients/reducer';
import { initialState as surveysInitialState } from 'store/surveys/reducer';
import { initialState as clinicsInitialState } from 'store/clinics/reducer';
import { initialState as adminsInitialState } from 'store/admins/reducer';
import { initialState as uiInitialState } from 'store/ui/reducer';

const appReducer = combineReducers({
  auth: authReducer,
  surveys: surveysReducer,
  doctors: doctorsReducer,
  patients: patientsReducer,
  auxSurveys: auxSurveysReducer,
  notes: notesReducer,
  clinics: clinicsReducer,
  admins: adminsReducer,
  ui: uiReducer,
});

export const rootReducer = handleActions(
  {
    [rootActions.resetState]: () => ({
      state: {
        auth: authInitialState,
        auxSurveys: auxSurveysInitialState,
        doctors: doctorsInitialState,
        notes: notesInitialState,
        patients: patientsInitialState,
        surveys: surveysInitialState,
        clinics: clinicsInitialState,
        admins: adminsInitialState,
        ui: uiInitialState,
      },
    }),
  },
  appReducer
);
