import { all, call, put, takeEvery, takeLatest, select, delay } from "redux-saga/effects";
// import i18n from 'i18next';

import { SURVEY_INSTANCE_STATUSES } from "constants/surveyInstance";

import * as ApiService from "services/api/ApiService";
import NotificationsService from "services/notifications/NotificationsService";
import NavigationService from "services/navigation/NavigationService";
import UserService from "services/user/UserService";

import { getAccountDataSaga } from "store/auth/sagas";
import { createSurveyInstanceSaga } from "store/surveys/sagas";

import { handleRequestError } from "services/api/apiTools";

import * as actions from "./actions";
import LanguageService from "services/language/LanguageService";
import { PATIENT_LIST_PAGE_SIZE } from "constants/pagination";

export function* createPatientSaga(action) {
    const { clinicId, email, firstName, lastName, surveyId } = action.payload;

    try {
        yield call(ApiService.patientCreate, { clinicId, email, firstName, lastName });
        NotificationsService.throwNotification(
            "success",
            LanguageService.t("UI:Success"),
            LanguageService.t("UI:PatientRegistered")
        );
    } catch (errors) {
        if (errors.response.status !== 409) {
            NotificationsService.throwNotification(
                "error",
                LanguageService.t("UI:Failure"),
                LanguageService.t("UI:PatientIsNotRegistered")
            );
            handleRequestError(errors);
            yield put(actions.createPatient.failure());
            return;
        }
    }

    yield call(createSurveyInstanceSaga, { payload: { patientEmail: email, surveyId } });

    yield put(actions.createPatient.success());

    yield put(actions.getPatientsList.start());
}

export function* getPatientsListSaga(action) {
    const patientsListFetchParams = yield select(
        (state) => state?.patients?.patientsListFetchParams
    );
    let patientsListMergedFetchParams = Object.assign(
        {},
        patientsListFetchParams?.pagination,
        patientsListFetchParams?.filters
    );

    if (
        action.type === "PATIENTS/SET_PATIENTS_LIST_FETCH_PARAMS/PAGINATION" ||
        action.type === "PATIENTS/SET_PATIENTS_LIST_FETCH_PARAMS/FILTERS" ||
        action.type === "PATIENTS/SET_PATIENTS_LIST_FETCH_PARAMS/SEARCH"
    ) {
        // TODO: refactor
        patientsListMergedFetchParams = Object.assign(
            patientsListMergedFetchParams,
            action?.payload?.params
        );
    }

    let data;

    try {
        data = yield call(ApiService.patientsListGet, {
            ...patientsListMergedFetchParams,
            size: PATIENT_LIST_PAGE_SIZE, // Always set high page size to load full patient list
        });
    } catch (errors) {
        handleRequestError(errors);
        yield put(actions.getPatientsList.failure());
        return;
    }

    const processedData = {};
    const processedDataSequence = [];

    data.content.forEach((item) => {
        const {
            age,
            birthDate,
            chronicConditions,
            diabetes,
            email,
            firstName,
            gender,
            lastName,
            demographics,
        } = item;

        const newestCompletedDemographic = demographics
            ? demographics.filter(
                  ({ status }) => status === SURVEY_INSTANCE_STATUSES.completed
              )[0] // TODO: sort by date
            : undefined;

        // console.log(newestCompletedDemographic);

        processedDataSequence.push(email);

        processedData[email] = {
            age,
            birthDate,
            chronicConditions,
            diabetes,
            email,
            firstName,
            gender,
            lastName,
            hasSurvey: newestCompletedDemographic,
        };
    });

    const meta = {
        empty: data.empty,
        first: data.first,
        last: data.last,
        number: data.number,
        numberOfElements: data.numberOfElements,
        size: data.size,
        totalElements: data.totalElements,
        totalPages: data.totalPages,
    };

    yield put(
        actions.getPatientsList.success({
            data: processedData,
            dataSequence: processedDataSequence,
            pageable: data.pageable,
            sort: data.sort,
            meta,
        })
    );
}

export function* getPatientSaga(action) {
    // ! should replace getAccountDataSaga in authSagas
    const { email } = action?.payload ?? action; // read from action payload, or directly from input data in a case of direct saga call

    let responseData;

    try {
        responseData = yield call(ApiService.patientGet, email);
    } catch (errors) {
        handleRequestError(errors);
        yield put(actions.getPatient.failure());
        return;
    }

    yield put(actions.getPatient.success(responseData));

    return responseData;
}

export function* importPatientsSaga(action) {
    const { fileData } = action.payload;

    const doctorEmail = UserService.getCurrentUserEmail();

    let responseData;

    try {
        responseData = yield call(ApiService.patientsMassImport, fileData, doctorEmail);
    } catch (errors) {
        handleRequestError(errors);
        yield put(actions.importPatients.failure());
        return;
    }

    let modalHeading = "Mass sign up";
    let modalContent = ["Successfully!"];

    if (responseData?.length !== 0) {
        modalHeading = "Mass sign up: error";
        modalContent = responseData.concat([
            " ",
            "Please, check your csv file and try again, or contact administrator",
        ]);
    }

    NotificationsService.showNotificationModal(modalHeading, modalContent);

    yield put(actions.importPatients.success());
}

export function* acceptPrivacyPolicySaga() {
    try {
        yield call(ApiService.privacyPolicyAcceptPatient);
    } catch (errors) {
        handleRequestError(errors);
        yield put(actions.acceptPrivacyPolicy.failure());
        return;
    }

    const email = yield select((state) => state?.auth?.userAccountData?.email);
    yield call(getAccountDataSaga, { email });

    yield delay(1000); // TODO: change to watcher
    // yield take('AUTH/GET_ACCOUNT_DATA/SUCCESS'); // TODO: move to constants

    yield put(actions.acceptPrivacyPolicy.success());

    NavigationService.navigateToDefaultPath();
}

export function* patientsSagas() {
    yield all([
        yield takeEvery(actions.createPatient.start, createPatientSaga),
        yield takeLatest(
            [
                actions.getPatientsList.start,
                actions.setPatientsListFetchParams.pagination,
                actions.setPatientsListFetchParams.filters,
                actions.setPatientsListFetchParams.search,
            ],
            getPatientsListSaga
        ),
        yield takeEvery(actions.getPatient.start, getPatientSaga),
        yield takeEvery(actions.importPatients.start, importPatientsSaga),
        yield takeEvery(actions.acceptPrivacyPolicy.start, acceptPrivacyPolicySaga),
    ]);
}
