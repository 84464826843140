import { createActions } from 'redux-actions';

export const { getAuxSurveyInstance, updateAuxSurveyInstance } = createActions(
  {
    GET_AUX_SURVEY_INSTANCE: {
      START: ({
        id
      }) => ({
        id,
      }),
      SUCCESS: ({
        instanceCreateTimestamp,
        instanceId,
        instanceLastUpdatedTimestamp,
        instanceStage,
        instanceStatus,
        instanceAnswers,
        user,
        survey,
      }) => ({
        instanceCreateTimestamp,
        instanceId,
        instanceLastUpdatedTimestamp,
        instanceStage,
        instanceStatus,
        instanceAnswers,
        user,
        survey,
      }),
      FAILURE: undefined,
    },
    UPDATE_AUX_SURVEY_INSTANCE: {
      START: ({
        id,
        data
      }) => ({
        id,
        data,
      }),
      SUCCESS: undefined,
      FAILURE: undefined,
    },
  },
  { prefix: 'AUX_SURVEYS' }
);


