import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ContentWithHeaderLayout from 'layouts/pageContentLayouts/ContentWithHeaderLayout/ContentWithHeaderLayout';

import SurveysList from 'сomponents/Surveys/SurveysList/SurveysList';
import SurveysHeader from 'сomponents/Surveys/SurveysHeader/SurveysHeader';
import SurveysResults from 'сomponents/Surveys/SurveysResults/SurveysResults';
import PHSurveysListIsEmpty from '../../сomponents/Surveys/SurveysResults/SurveysResultsPlaceholders/PHSurveysListIsEmpty/PHSurveysListIsEmpty';

import styles from './DoctorSurveyView.module.scss';

DoctorSurveyView.propTypes = {
  computedMatch: PropTypes.object.isRequired,
};

function DoctorSurveyView({computedMatch}) {
  const { t } = useTranslation();

  const surveyInstanceId = computedMatch?.params?.id || '';
  
  const surveyInstancesListDataSequence = useSelector(state => state.surveys.surveyInstancesList.dataSequence);
  const selectedPatientEmail = useSelector(state => state?.patients?.selectedPatient) || '';
  const patientsData = useSelector(state => state?.patients?.data) || {};

  const patientFirstName = patientsData?.[selectedPatientEmail]?.firstName || t('Surveys:DoctorSurveyView.fallback.firstNameIsMissing');
  const patientLastName = patientsData?.[selectedPatientEmail]?.lastName || t('Surveys:DoctorSurveyView.fallback.lastNameIsMissing');

  const renderDetailsSection = () => {
    if(surveyInstancesListDataSequence.length === 0) {
      return <PHSurveysListIsEmpty />;
    }

    return <SurveysResults surveyInstanceId={surveyInstanceId} />;
  };

  return (
    <ContentWithHeaderLayout
      headerTitle={`${patientFirstName} ${patientLastName}`}
    >
      <div className={styles.rootWrapper}>
        <div className={styles.headerHolder}>
          <SurveysHeader />
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.surveyListHolder}>
            <SurveysList />
          </div>
          <div className={styles.surveyDetailsHolder}>
            {renderDetailsSection()}
          </div>
        </div>
      </div>
    </ContentWithHeaderLayout>
  );
}

export default DoctorSurveyView;
