import { handleActions } from 'redux-actions';

import * as actions from './actions';

export const initialState = {
  surveyInstancesList: {
    data: {},
    dataSequence: [],
    pageable: {},
    sort: {},
    meta: {},
  },
  surveyInstance: {
    instanceCreateTimestamp: '',
    instanceId: '',
    instanceLastUpdatedTimestamp: '',
    instanceStage: '',
    instanceStatus: '',
    // instanceAnswers: {
    //   data: {},
    //   dataSequence: [],
    // },
    instanceAnswers: [],
    instanceScores: {
      data: {},
      dataSequence: [],
    },
    instanceRecommendations: {
      data: {},
      dataSequence: [],
    },
    survey: {},
    user: {},
    surveyId: '',
    surveyName: '',
    surveyQuestions: {
      data: {},
      dataSequence: [],
    },
    instancePossibleConclusions: [],
    conclusions: [],
  },
  isLoadingCreateSurveyInstance: false,
  isLoadingGetSurveyInstancesList: false,
  isLoadingGetSurveyInstance: false,
  isLoadingUpdateSurveyInstance: false,
  isLoadingUpdateSurveyInstanceRecommendations: false,
  isLoadingUpdateSurveyInstanceConclusion: false,
  isLoadingSubmitSurveyInstance: false,
  isLoadingGetSurveysList: false,
  isLoadingGetSurvey: false,
  isLoadingExportSurveysInstances: false,
  // selectedSurveyResultItem: null, // TODO: rename
  currentSurveyQuestionIndex: 0,
  surveysList: {
    data: {},
    dataSequence: [],
  },
  survey: {},
};

export const surveysReducer = handleActions(
  {
    [actions.createSurveyInstance.start]: (state) => ({
      ...state,
      isLoadingCreateSurveyInstance: true,
    }),
    [actions.createSurveyInstance.success]: (state) => ({
      ...state,
      isLoadingCreateSurveyInstance: false,
    }),
    [actions.createSurveyInstance.failure]: (state) => ({
      ...state,
      isLoadingCreateSurveyInstance: false,
    }),

    [actions.getSurveyInstancesList.start]: (state) => ({
      ...state,
      isLoadingGetSurveyInstancesList: true,
    }),
    [actions.getSurveyInstancesList.success]: (state, { payload }) => ({
      ...state,
      isLoadingGetSurveyInstancesList: false,
      surveyInstancesList: {
        ...state.surveyInstancesList,
        data: payload.data,
        dataSequence: payload.dataSequence,
        pageable: payload.pageable,
        sort: payload.sort,
        meta: payload.meta,
      },
    }),
    [actions.getSurveyInstancesList.failure]: (state) => ({
      ...state,
      isLoadingGetSurveyInstancesList: false,
    }),

    [actions.getSurveyInstance.start]: (state) => ({
      ...state,
      isLoadingGetSurveyInstance: true,
    }),
    [actions.getSurveyInstance.success]: (state, { payload }) => ({
      ...state,
      isLoadingGetSurveyInstance: false,
      surveyInstance: {
        instanceCreateTimestamp: payload.instanceCreateTimestamp,
        instanceId: payload.instanceId,
        instanceLastUpdatedTimestamp: payload.instanceLastUpdatedTimestamp,
        instanceStage: payload.instanceStage,
        instanceStatus: payload.instanceStatus,
        instanceAnswers: payload.instanceAnswers,
        instanceScores: payload.instanceScores,
        instanceRecommendations: payload.instanceRecommendations,
        survey: payload.survey,
        user: payload.user,
        surveyId: payload.surveyId,
        surveyName: payload.surveyName,
        surveyQuestions: payload.surveyQuestions,
        instancePossibleConclusions: payload.instancePossibleConclusions,
        instanceConclusions: payload.instanceConclusions,
        instanceMainAreasOfFocus: payload.instanceMainAreasOfFocus,
      },
    }),
    [actions.getSurveyInstance.failure]: (state) => ({
      ...state,
      isLoadingGetSurveyInstance: false,
    }),

    [actions.getSurveysList.start]: (state) => ({
      ...state,
      isLoadingGetSurveysList: true,
    }),
    [actions.getSurveysList.success]: (state, { payload }) => ({
      ...state,
      isLoadingGetSurveysList: false,
      surveysList: {
        ...state.surveysList,
        data: payload.data,
        dataSequence: payload.dataSequence,
        pageable: payload.pageable,
        sort: payload.sort,
        meta: payload.meta,
      },
    }),
    [actions.getSurveysList.failure]: (state) => ({
      ...state,
      isLoadingGetSurveysList: false,
    }),

    [actions.getSurvey.start]: (state) => ({
      ...state,
      isLoadingGetSurvey: true,
    }),
    [actions.getSurvey.success]: (state) => ({
      ...state,
      isLoadingGetSurvey: false,
      survey: {
        ...state.survey,
        // instanceCreateTimestamp: payload.instanceCreateTimestamp,
        // instanceId: payload.instanceId,
        // instanceLastUpdatedTimestamp: payload.instanceLastUpdatedTimestamp,
        // instanceStage: payload.instanceStage,
        // instanceStatus: payload.instanceStatus,
        // instanceAnswers: payload.instanceAnswers,
        // instanceScores: payload.instanceScores,
        // instanceRecommendations: payload.instanceRecommendations,
        // surveyId: payload.surveyId,
        // surveyName: payload.surveyName,
        // surveyQuestions: payload.surveyQuestions,
        // instancePossibleConclusions: payload.instancePossibleConclusions,
        // instanceConclusions: payload.instanceConclusions,
      },
    }),
    [actions.getSurvey.failure]: (state) => ({
      ...state,
      isLoadingGetSurvey: false,
    }),

    [actions.setCurrentSurveyQuestionIndex]: (state, { payload }) => ({
      ...state,
      currentSurveyQuestionIndex: payload.index,
    }),

    [actions.updateSurveyInstance.start]: (state) => ({
      ...state,
      isLoadingUpdateSurveyInstance: true,
    }),
    [actions.updateSurveyInstance.success]: (state, { payload }) => ({
      ...state,
      isLoadingUpdateSurveyInstance: false,
    }),
    [actions.updateSurveyInstance.failure]: (state) => ({
      ...state,
      isLoadingUpdateSurveyInstance: false,
    }),

    [actions.updateSurveyInstanceRecommendations.start]: (state) => ({
      ...state,
      isLoadingUpdateSurveyInstanceRecommendations: true,
    }),
    [actions.updateSurveyInstanceRecommendations.success]: (state) => ({
      ...state,
      isLoadingUpdateSurveyInstanceRecommendations: false,
    }),
    [actions.updateSurveyInstanceRecommendations.failure]: (state) => ({
      ...state,
      isLoadingUpdateSurveyInstanceRecommendations: false,
    }),

    [actions.updateSurveyInstanceConclusion.start]: (state) => ({
      ...state,
      isLoadingUpdateSurveyInstanceConclusion: true,
    }),
    [actions.updateSurveyInstanceConclusion.success]: (state) => ({
      ...state,
      isLoadingUpdateSurveyInstanceConclusion: false,
    }),
    [actions.updateSurveyInstanceConclusion.failure]: (state) => ({
      ...state,
      isLoadingUpdateSurveyInstanceConclusion: false,
    }),

    [actions.submitSurveyInstance.start]: (state) => ({
      ...state,
      isLoadingSubmitSurveyInstance: true,
    }),
    [actions.submitSurveyInstance.success]: (state) => ({
      ...state,
      isLoadingSubmitSurveyInstance: false,
    }),
    [actions.submitSurveyInstance.failure]: (state) => ({
      ...state,
      isLoadingSubmitSurveyInstance: false,
    }),

    [actions.exportSurveysInstances.start]: (state) => ({
      ...state,
      isLoadingExportSurveysInstances: true,
    }),
    [actions.exportSurveysInstances.success]: (state) => ({
      ...state,
      isLoadingExportSurveysInstances: false,
    }),
    [actions.exportSurveysInstances.failure]: (state) => ({
      ...state,
      isLoadingExportSurveysInstances: false,
    }),
  },
  initialState
);
