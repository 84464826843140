import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "styles/commonModules/ResourcesList.module.scss";
import { getDoctorResources } from "services/api/ApiService";
import { useTranslation } from "react-i18next";
import Loader from "componentsShared/Loader/Loader";
import { ResourceLink } from "сomponents/ResourceLink/ResourceLink";

const sortByOrder = (a, b) => {
    return a.order - b.order;
};

const Section = ({ name, subGroups }) => {
    if (subGroups.length === 0) {
        return null;
    }

    return (
        <div className={styles["resources-section"]}>
            <h2 className={styles.heading}>{name}</h2>

            {subGroups.sort(sortByOrder).map(({ id, name, children, url, type }) => {
                if (type === "RESOURCE") {
                    return (
                        <li className={styles.resourcesListItem}>
                            <ResourceLink url={url} name={name} />
                        </li>
                    );
                }

                return (
                    <ul key={id} className={styles.resourcesList}>
                        <h4>{name}</h4>
                        {children.sort(sortByOrder).map(({ id, name, url }) => {
                            return (
                                <li key={id} className={styles.resourcesListItem}>
                                    <ResourceLink url={url} name={name} />
                                </li>
                            );
                        })}
                    </ul>
                );
            })}
        </div>
    );
};

function DoctorResourcesList() {
    const [isLoading, setLoading] = useState(true);
    const [resources, setResources] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        setLoading(true);
        getDoctorResources()
            .then((response) => {
                setResources(response);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return (
        <div className={styles["resources-section-wrapper"]}>
            {isLoading && (
                <div className={styles["resources-section-loader"]}>
                    <Loader isVisible />
                </div>
            )}

            {resources.length === 0 && !isLoading && (
                <div className={styles["resources-no-data"]}>
                    {t("Resources:DoctorResourcesList.noData")}
                </div>
            )}

            {resources.sort(sortByOrder).map(({ id, name, children }) => (
                <Section key={id} name={name} subGroups={children} />
            ))}
        </div>
    );
}

Section.propTypes = {
    subGroups: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
};

export default DoctorResourcesList;
