import React from 'react';
import { Provider } from 'react-redux';
import { i18n as i18nElement } from 'element-react';
import locale from 'element-react/src/locale/lang/en';
import { PersistGate } from 'redux-persist/integration/react';

import { store, persistor } from './store/store';

import ServiceLayout from 'layouts/topLevelLayouts/ServiceLayout/ServiceLayout';

import ErrorBoundary from 'componentsShared/ErrorBoundary/ErrorBoundary';

import 'services/language/i18n';

import 'element-theme-default';
import 'styles/styles.scss';

i18nElement.use(locale);

function App() {
  return (
    <div className="app">
      <ErrorBoundary>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ServiceLayout />
          </PersistGate>
        </Provider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
