import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { withFauxDOM } from 'react-faux-dom';

TreeChart.propTypes = {
  connectFauxDOM: PropTypes.any,
  chart: PropTypes.any,
  chartData: PropTypes.object.isRequired,
};

const maxDescriptionSubstringLength = 50;

const splitString = (str) => {
  const words = str.split(' ');
  const substrings = [];

  let currentSubstring = [];
  let substringTotalLengthCounter = 0;

  words.forEach((item) => {
    substringTotalLengthCounter += item.length + 1;
  
    if (substringTotalLengthCounter <= maxDescriptionSubstringLength) {
      currentSubstring.push(item);
    } else {
      substringTotalLengthCounter = 0;
      substrings.push(currentSubstring.join(' '));
      currentSubstring = [];
      currentSubstring.push(item);
    }
  });

  substrings.push(currentSubstring.join(' '));

  return substrings;
};

function TreeChart ({connectFauxDOM, chart = 'loading', chartData}) {
  const linkColor = '#555';
  const corcleBgColor = '#a0d4cd';
  const corcleBorderColor = '#129482';

  const chartXOffset = 100;
  const chartWidthOffsetMultiplier = 0.7;
  const width = 954;

  useEffect(() => {
    const tree = chartData => {
      const root = d3.hierarchy(chartData);
      root.dx = 70; // ! chart height ?
      root.dy = width / (root.height + 1);

      return d3.tree().nodeSize([root.dx, root.dy])(root);
    };

    const root = tree(chartData);

    let x0 = Infinity;
    let x1 = -x0;

    root.each(d => {
      if (d.x > x1) x1 = d.x;
      if (d.x < x0) x0 = d.x;
    });

    const faux = connectFauxDOM('div', 'chart');
    const fauxUnit = d3.select(faux)
      .append('svg')
      .attr("viewBox", [0, 0, width, x1 - x0 + root.dx * 2]);

    const g = fauxUnit.append("g")
      .attr("font-family", "sans-serif")
      .attr("font-size", 10)
      .attr("transform", `translate(${(root.dy / 3) - chartXOffset},${root.dx - x0})`);

    g.append("g") // link
      .attr("fill", "none")
      .attr("stroke", linkColor)
      .attr("stroke-opacity", 0.4)
      .attr("stroke-width", 1.5)
      .selectAll("path")
        .data(root.links())
        .join("path")
          .attr("d", d3.linkHorizontal()
              .x(d => d.children ? d.y * chartWidthOffsetMultiplier : d.y * chartWidthOffsetMultiplier - d.data.value)
              .y(d => d.x));
    
    const chartContentGroupsSelection = g.append("g")
        .attr("stroke-linejoin", "round")
        .attr("stroke-width", 3)
        .selectAll("g")
        .data(root.descendants())
        .join("g")
          .attr("transform", d => `translate(${d.y * chartWidthOffsetMultiplier},${d.x})`);
  
    chartContentGroupsSelection.append("circle")
        .attr("fill", corcleBgColor)
        .attr("r", d => d.data.value) // circle radius value
        .attr("stroke", corcleBorderColor)
        .attr("stroke-width", 2);

    chartContentGroupsSelection.append("text") // TODO: refactor the whole description render method!
        .attr("x", d => {
          if (!d.data.value) {
            return 0;
          }

          if (d.children) {
            return -6 - d.data.value;
          }

          return 6 + d.data.value;
        }) // d.data.value -> circle raduis offset
        .attr("dy", d => {
          const splittedDescription = splitString(d.data.name);

          if (splittedDescription.length === 1 || splittedDescription.length === 3) {
            return "-0.91em";
          }

          if (splittedDescription.length === 2) {
            return "-0.51em";
          }

          return "-0.91em";
        })
        .attr("text-anchor", d => d.children ? "end" : "start")
        .attr("font-size", "1rem")
        .text(d => {
          const splittedDescription = splitString(d.data.name);

          if (splittedDescription.length === 1) {
            return '';
          }

          if (splittedDescription.length === 2 || splittedDescription.length === 3) {
            return splittedDescription[0];
          }

          return d.data.name;
        })

        .clone(true)
        .attr("dy", "0.31em")
        .text(d => {
          const splittedDescription = splitString(d.data.name);

          if (splittedDescription.length === 1) {
            return splittedDescription[0];
          }

          if (splittedDescription.length === 2) {
            return '';
          }

          if (splittedDescription.length === 3) {
            return splittedDescription[1];
          }

          return d.data.name;
        })

        .clone(true)
        .attr("dy", d => {
          const splittedDescription = splitString(d.data.name);

          if (splittedDescription.length === 1 || splittedDescription.length === 3) {
            return "1.61em";
          }

          if (splittedDescription.length === 2) {
            return "0.91em";
          }

          return "1.61em";
        })
        .text(d => {
          const splittedDescription = splitString(d.data.name);

          if (splittedDescription.length === 1) {
            return '';
          }

          if (splittedDescription.length === 2) {
            return splittedDescription[1];
          }

          if (splittedDescription.length === 3) {
            return splittedDescription[2];
          }

          return d.data.name;
        });
  }, [chartData]);

  return (
    <div className="renderedD3">
      {chart}
    </div>
  );
}

export default withFauxDOM(TreeChart);
