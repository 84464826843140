import React, { Fragment } from 'react';
import { Table, Pagination } from 'element-react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Card } from 'element-react';

import { PAGE_SIZE } from 'constants/pagination';

import customComponentsStyles from 'styles/custom-components.module.scss';
import styles from './ListTable.module.scss';

ListTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  onTableRowClick: PropTypes.func.isRequired,
  tableData: PropTypes.array.isRequired,
  withPagination: PropTypes.bool.isRequired,
  totalElements: PropTypes.number,
  onPaginationChange: PropTypes.func,
  isAnimated: PropTypes.bool,
  isWithoutBorders: PropTypes.bool,
};

function ListTable ({
  columns,
  onTableRowClick,
  tableData,
  withPagination,
  totalElements,
  onPaginationChange,
  isAnimated,
  isWithoutBorders,
}) {
  const { t } = useTranslation();

  return (
    <Fragment>
      <div className={styles.tableHolder}>
        <Card
          bodyStyle={{ padding: '0' }}
        >
          <div
            className={classNames(customComponentsStyles.tableCustom, {
              [customComponentsStyles.animatedWidth]: isAnimated,
              [customComponentsStyles.noBorders]: isWithoutBorders,
            })}
          >
            <Table
              style={{ width: '100%' }}
              columns={columns}
              onRowClick={onTableRowClick}
              data={tableData}
              border={false}
              emptyText={t('UI:ListTable.placeholder.emptyText')}
            />
          </div>
        </Card>
      </div>
      {
        withPagination && totalElements && PAGE_SIZE && onPaginationChange
          ? <div className={styles.paginationHolder}>
              <Pagination
                className={customComponentsStyles.pagination}
                layout="prev, pager, next"
                total={totalElements}
                pageSize={PAGE_SIZE}
                onCurrentChange={onPaginationChange}
              />
            </div>
          : null
      }
    </Fragment>
  );
}

export default ListTable;
