import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from 'element-react';

import * as clinicsActions from 'store/clinics/actions';

import AddClinicDialog from 'сomponents/AddClinic/AddClinicDialog/AddClinicDialog';

import customComponentsStyles from 'styles/custom-components.module.scss';

function AddClinic () {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isDialogVisible, setIsDialogVisible] = useState(false);

  const onClinicSubmit = (data) => {
    dispatch(clinicsActions.createClinic.start(data));
  };

  return (
    <Fragment>
      <Button
        className={customComponentsStyles.buttonCustom}
        onClick={() => {setIsDialogVisible(true);}}
      >
        {t('Clinics:AddClinic.dialog.button.addClinic')}
      </Button>
      <AddClinicDialog
        isVisible={isDialogVisible}
        setIsVisible={setIsDialogVisible}
        onSubmit={onClinicSubmit}
      />
    </Fragment>
  );
}

export default AddClinic;
