import { store } from "store/store";

import { USER_ROLES } from "constants/userRoles";
import { USER_STATUSES } from "constants/userStatuses";

// import CookiesService from 'services/cookies/CookiesService';

function getCurrentState() {
    // TODO: move to helpers
    return store.getState();
}

const UserService = {
    getCurrentUserRole() {
        const currentState = getCurrentState();
        return currentState?.auth?.accountType;
    },

    getCurrentUserEmail() {
        const currentState = getCurrentState();
        return currentState?.auth?.authenticatedUserData?.data?.data?.email;
    },

    compareAccountType(accountTypeToCompare) {
        const accountType = this.getCurrentUserRole();
        return accountType && accountType === accountTypeToCompare;
    },

    isSuperadmin() {
        return this.compareAccountType(USER_ROLES.superadmin);
    },

    isAdmin() {
        return this.compareAccountType(USER_ROLES.admin);
    },

    isDoctor() {
        return this.compareAccountType(USER_ROLES.doctor);
    },

    isPatient() {
        return this.compareAccountType(USER_ROLES.patient);
    },

    isOneTimePatient() {
        const currentState = getCurrentState();
        return currentState?.auth?.authenticatedUserData?.data?.isSingleSurveyPatient;
    },

    isAuthorized() {
        const currentState = getCurrentState();
        const isAuthorized = currentState?.auth?.isAuthorized ?? false;

        return isAuthorized;
    },

    getUserStatus() {
        const currentState = getCurrentState();
        return currentState?.auth?.authenticatedUserData?.data?.data?.status;
    },

    isUserStatusInactive() {
        const userStatus = this.getUserStatus();
        return userStatus === USER_STATUSES.inactive;
    },

    isUserStatusTermsAndConditions() {
        const userStatus = this.getUserStatus();
        return userStatus === USER_STATUSES.termsAndConditions;
    },

    isUserStatusPrivacyPolicy() {
        const userStatus = this.getUserStatus();
        return userStatus === USER_STATUSES.privacyPolicy;
    },

    isUserStatusDemographics() {
        const currentState = getCurrentState();
        const userAccountData = currentState?.auth?.userAccountData;

        let notCompletedAuxSurveys = [];

        if (userAccountData?.demographics) {
            notCompletedAuxSurveys = userAccountData?.demographics.filter((item) => {
                return item.status !== "COMPLETED";
            });
        }

        if (userAccountData?.demographics && notCompletedAuxSurveys.length !== 0) {
            return true;
        }

        return false;
    },

    isUserStatusActive() {
        const userStatus = this.getUserStatus();
        return userStatus === USER_STATUSES.active;
    },

    // isTermsAndConditionsSignedByUser() { // TODO: remove this check from sagas and move to protected route
    //   const currentState = getCurrentState();
    //   const userStatus = currentState?.auth?.userAccountData?.status;

    //   if (!userStatus || (userStatus === 'TERMS_AND_CONDITIONS')) { // TODO: move user statuses to constants
    //     return false;
    //   }

    //   return true;
    // },

    // isPrivacyPolicySignedByUser() {
    //   const PrivacyPolicyCookie = CookiesService.getCookie('PrivacyPolicy'); // TODO: remove
    //   return PrivacyPolicyCookie === 'signed';
    // },

    // isDemodraphySurveyPassed() {

    // }
};

export default UserService;
