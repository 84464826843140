import React from 'react';
import PropTypes from 'prop-types';

import styles from './SurveysListPlaceholder.module.scss';

SurveysListPlaceholder.propTypes = {
  message: PropTypes.string.isRequired,
};

function SurveysListPlaceholder ({message}) {
  return (
    <div className={styles.rootWrapper}>{message}</div>
  );
}

export default SurveysListPlaceholder;
