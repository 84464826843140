import { all, call, put, takeEvery } from "redux-saga/effects";
// import i18n from 'i18next';

import { PAGE_SIZE } from "constants/pagination";

import * as actions from "./actions";

import * as ApiService from "services/api/ApiService";
import NotificationsService from "services/notifications/NotificationsService";
import { handleRequestError } from "services/api/apiTools";
import LanguageService from "services/language/LanguageService";

export function* createAdminSaga(action) {
    const { clinicId, email, firstName, lastName } = action.payload;

    try {
        yield call(ApiService.adminCreate, {
            clinicCode: clinicId,
            email,
            firstName,
            lastName,
        });
    } catch (errors) {
        NotificationsService.throwNotification(
            "error",
            LanguageService.t("Admins:Registration.Error"),
            LanguageService.t("UI:AdminNotRegistered")
        );
        handleRequestError(errors);
        yield put(actions.createAdmin.failure());
        return;
    }

    NotificationsService.throwNotification(
        "success",
        LanguageService.t("UI:Success"),
        LanguageService.t("Admins:AdminCreated")
    );

    yield put(actions.createAdmin.success());

    yield put(actions.getAdminsList.start({ pageSize: PAGE_SIZE, pageNumber: 0 }));
}

export function* getAdminsListSaga(action) {
    const { pageSize, pageNumber } = action.payload;

    let data;

    try {
        data = yield call(ApiService.adminsListGet, pageSize, pageNumber);
    } catch (errors) {
        handleRequestError(errors);
        yield put(actions.getAdminsList.failure());
        return;
    }

    const processedData = {};
    const processedDataSequence = [];

    data.content.forEach((item) => {
        const { email, firstName, lastName, clinic } = item;

        processedDataSequence.push(email);

        processedData[email] = {
            email,
            firstName,
            lastName,
            clinic,
        };
    });

    const meta = {
        empty: data.empty,
        first: data.first,
        last: data.last,
        number: data.number,
        numberOfElements: data.numberOfElements,
        size: data.size,
        totalElements: data.totalElements,
        totalPages: data.totalPages,
    };

    yield put(
        actions.getAdminsList.success({
            data: processedData,
            dataSequence: processedDataSequence,
            pageable: data.pageable,
            sort: data.sort,
            meta,
        })
    );
}

export function* getAdminSaga(action) {
    const { email } = action.payload;
    let data;

    try {
        data = yield call(ApiService.adminGet, email);
    } catch (errors) {
        handleRequestError(errors);
        yield put(actions.getAdmin.failure());
        return;
    }

    yield put(
        actions.getAdmin.success({
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            clinic: data.clinic,
        })
    );
}

export function* adminsSagas() {
    yield all([
        yield takeEvery(actions.createAdmin.start, createAdminSaga),
        yield takeEvery(actions.getAdminsList.start, getAdminsListSaga),
        yield takeEvery(actions.getAdmin.start, getAdminSaga),
    ]);
}
