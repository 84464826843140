import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";
import { Button } from "element-react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import * as surveysActions from "store/surveys/actions";

import { SURVEY_INSTANCE_STATUSES } from "constants/surveyInstance";
import { datesFormat } from "constants/datesAndTime";
import { USER_ROLES } from "constants/userRoles";

import iconStatusCompleted from "images/icons/status-completed.svg";
import iconStatusInProgress from "images/icons/status-in-progress.svg";
import iconStatusNotStarted from "images/icons/status-not-started.svg";

import ScoresTreeChart from "сomponents/ScoresTreeChart/ScoresTreeChart";
import Recommendations from "сomponents/Surveys/SurveysResults/Recommendations/Recommendations";
import ImportantIssue from "сomponents/Surveys/SurveysResults/ImportantIssue/ImportantIssue";
import Conclusion from "сomponents/Surveys/SurveysResults/Conclusion/Conclusion";
import Loader from "componentsShared/Loader/Loader";
import PHSurveyIsNotCompleted from "../SurveysResults/SurveysResultsPlaceholders/PHSurveyIsNotCompleted/PHSurveyIsNotCompleted";

import customComponentsStyles from "styles/custom-components.module.scss";
import styles from "./SurveysResults.module.scss";
import QuestionsWithAnswersByTag from "./QuestionsWithAnswersByTag/QuestionsWithAnswersByTag";

export const CARE_PATHWAY_AGREED_FIELD_ID = "ca875a64-7847-11ee-ada1-02a0e9167d55";

SurveysResults.propTypes = {
    surveyInstanceId: PropTypes.string.isRequired,
};

function SurveysResults({ surveyInstanceId }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const accountType = useSelector((state) => state.auth.accountType);

    const surveyStatus = useSelector((state) =>
        get(state.surveys.surveyInstance, "instanceStatus", null)
    );

    const instanceMainAreasOfFocus =
        useSelector((state) => state.surveys?.surveyInstance?.instanceMainAreasOfFocus) || "";

    const isLoadingGetSurveyInstance = useSelector(
        (state) => state.surveys.isLoadingGetSurveyInstance
    );

    const surveyInstanceCreateTimestamp = useSelector((state) =>
        get(state.surveys.surveyInstance, "instanceCreateTimestamp", "")
    );
    const surveyInstanceName = useSelector((state) =>
        get(state.surveys.surveyInstance, "surveyName", "")
    );

    const surveyInstancePHQ2ScoreValue = useSelector((state) =>
        get(state.surveys.surveyInstance, "instanceScores.data.PHQ2.scoreValue")
    );

    const surveyInstanceRecommendationsData = useSelector((state) =>
        get(state.surveys.surveyInstance, "instanceRecommendations.data", {})
    );
    const recommendationLinks = Object.values(surveyInstanceRecommendationsData).reduce(
        (acc, current) => {
            const onlyWithResources = current.possibleAnswers.filter(
                (answer) => answer.resource
            );
            return [
                ...acc,
                ...onlyWithResources.map(({ id, resource: { name, url } }) => ({
                    id,
                    name,
                    url,
                })),
            ];
        },
        []
    );

    const surveyInstanceRecommendationsDataSequence =
        useSelector(
            (state) => state.surveys.surveyInstance.instanceRecommendations.dataSequence
        ) || [];

    const [conclusion, setConclusion] = useState({});
    const [recommendations, setRecommendations] = useState([]);

    const addResourceLink = (uuid) => {
        const foundLink = recommendationLinks.find(({ id }) => id === uuid);

        if (foundLink) {
            const { name, url } = foundLink;

            const existingText = conclusion[CARE_PATHWAY_AGREED_FIELD_ID]?.text || "";

            setConclusion({
                ...conclusion,
                [CARE_PATHWAY_AGREED_FIELD_ID]: {
                    id: CARE_PATHWAY_AGREED_FIELD_ID,
                    text: `${existingText}${
                        existingText.length > 0 ? "\n\n" : ""
                    }${name} (${url})`,
                },
            });
        }
    };

    const removeResourceLink = (uuid) => {
        const foundLink = recommendationLinks.find(({ id }) => id === uuid);

        if (foundLink) {
            const { name, url } = foundLink;
            setConclusion({
                ...conclusion,
                [CARE_PATHWAY_AGREED_FIELD_ID]: {
                    id: CARE_PATHWAY_AGREED_FIELD_ID,
                    text: conclusion[CARE_PATHWAY_AGREED_FIELD_ID]?.text
                        ?.replace(`\n\n${name} (${url})`, "")
                        .replace(`${name} (${url})`, ""),
                },
            });
        }
    };

    useEffect(() => {
        setRecommendations(
            surveyInstanceRecommendationsDataSequence.map((recommendationId) => ({
                id: recommendationId,
                answerIds: [],
            }))
        );
    }, [surveyInstanceRecommendationsDataSequence]);

    const formattedInstanceCreateTime = moment(surveyInstanceCreateTimestamp).format(
        datesFormat
    );

    const isDoctorRole = accountType === USER_ROLES.doctor;

    useEffect(() => {
        if (surveyInstanceId !== "") {
            dispatch(surveysActions.getSurveyInstance.start({ id: surveyInstanceId }));
        }

        return () => {
            setConclusion({});
        };
    }, [surveyInstanceId]);

    const renderStatusIcon = () => {
        let statusIcon;

        switch (surveyStatus) {
            case SURVEY_INSTANCE_STATUSES.created:
                statusIcon = iconStatusNotStarted;
                break;
            case SURVEY_INSTANCE_STATUSES.inProgress:
                statusIcon = iconStatusInProgress;
                break;
            case SURVEY_INSTANCE_STATUSES.review:
                statusIcon = iconStatusInProgress;
                break;
            case SURVEY_INSTANCE_STATUSES.completed:
                statusIcon = iconStatusCompleted;
                break;
            default:
                statusIcon = iconStatusNotStarted;
        }

        return (
            <Fragment>
                {statusIcon ? (
                    <img
                        src={statusIcon}
                        alt={t("Surveys:SurveysResults.statusIcon.altText")}
                    />
                ) : null}
            </Fragment>
        );
    };

    const onConclusionSubmit = () => {
        const userResponseData = [];
        for (let key in conclusion) {
            userResponseData.push({
                id: conclusion[key].id,
                text: conclusion[key].text,
            });
        }

        dispatch(
            surveysActions.updateSurveyInstanceRecommendations.start({
                id: surveyInstanceId,
                data: recommendations,
            })
        );

        dispatch(
            surveysActions.updateSurveyInstanceConclusion.start({
                id: surveyInstanceId,
                data: userResponseData,
            })
        );
    };

    const renderDetails = () => {
        return (
            <div className={styles.detailsWrapper}>
                <div className={styles.dateStatusHolder}>
                    <span className={styles.date}>{formattedInstanceCreateTime}</span>
                    <span className={styles.status}>{renderStatusIcon()}</span>
                </div>
                <div className={styles.section}>
                    <div className={styles.sectionDescription}>
                        <span>{`${t(
                            "Surveys:SurveysResults.section.survey.subheading"
                        )} `}</span>
                        {surveyInstanceName}
                    </div>
                </div>

                <QuestionsWithAnswersByTag tag="display_on_top" />

                <div className={styles.section}>
                    <h2 className={styles.sectionHeading}>
                        {t("Surveys:SurveysResults.section.mainAreas.heading")}
                    </h2>
                    <p className={styles.sectionDescription}>{instanceMainAreasOfFocus}</p>
                </div>

                <div className={styles.section}>
                    <h2 className={styles.sectionHeading}>
                        {t("Surveys:SurveysResults.section.importantIssues.heading")}
                    </h2>
                    <ImportantIssue />
                </div>

                <div className={styles.section}>
                    <h2 className={styles.sectionHeading}>
                        {t("Surveys:SurveysResults.section.PHQ2.heading")}
                    </h2>
                    <p className={styles.sectionDescription}>
                        {t("Surveys:SurveysResults.section.PHQ2.label")}:{" "}
                        {surveyInstancePHQ2ScoreValue !== undefined
                            ? surveyInstancePHQ2ScoreValue
                            : "n/a"}
                    </p>
                    <p className={styles.sectionDescription}>
                        {t("Surveys:SurveysResults.section.PHQ2.hint")}
                    </p>
                </div>

                {isDoctorRole ? (
                    <div className={styles.section}>
                        <h2 className={styles.sectionHeading}>
                            {t("Surveys:SurveysResults.section.wantToConsider.heading")}
                        </h2>

                        <Recommendations
                            isSurveyStatusCompleted={
                                surveyStatus === SURVEY_INSTANCE_STATUSES.completed
                            }
                            recommendations={recommendations}
                            setRecommendations={setRecommendations}
                            addResourceLink={addResourceLink}
                            removeResourceLink={removeResourceLink}
                        />
                    </div>
                ) : null}

                <div className={styles.section}>
                    <ScoresTreeChart />
                </div>

                {isDoctorRole ? (
                    <Fragment>
                        <div className={styles.section}>
                            <Conclusion
                                conclusions={conclusion}
                                setConclusions={setConclusion}
                            />
                        </div>

                        <Button
                            onClick={onConclusionSubmit}
                            disabled={surveyStatus === SURVEY_INSTANCE_STATUSES.completed}
                            className={customComponentsStyles.buttonCustom}
                        >
                            {t("Surveys:SurveysResults.button.agreedCarePlan")}
                        </Button>
                    </Fragment>
                ) : null}
            </div>
        );
    };

    return (
        <div className={styles.rootWrapper}>
            {surveyStatus === SURVEY_INSTANCE_STATUSES.created ||
            surveyStatus === SURVEY_INSTANCE_STATUSES.inProgress ? (
                <PHSurveyIsNotCompleted />
            ) : (
                renderDetails()
            )}

            <Loader isVisible={isLoadingGetSurveyInstance} />
        </div>
    );
}

export default SurveysResults;
