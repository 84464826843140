import React from 'react';
import { Fragment } from 'react/cjs/react.production.min';

import Router from 'router/Router';
import NotificationModal from 'componentsShared/NotificationModal/NotificationModal';

function ServiceLayout() {
  return (
    <Fragment>
      <NotificationModal />
      <Router />
    </Fragment>
  );
}

export default ServiceLayout;
