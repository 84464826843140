import { all, call, put, takeEvery } from "redux-saga/effects";
import i18n from "i18next";

import { PAGE_SIZE } from "constants/pagination";

import * as ApiService from "services/api/ApiService";
import NotificationsService from "services/notifications/NotificationsService";
import { handleRequestError } from "services/api/apiTools";

import * as actions from "./actions";
import LanguageService from "services/language/LanguageService";

export function* createDoctorSaga(action) {
    const { clinicId, email, firstName, lastName } = action.payload;

    try {
        yield call(ApiService.doctorCreate, { clinicId, email, firstName, lastName });
    } catch (errors) {
        // NotificationsService.throwNotification('error', i18n.t('Admin:notification.doctor.registration.failure.heading'), i18n.t('Admin:notification.doctor.registration.failure.body')); //! [t]
        NotificationsService.throwNotification(
            "error",
            LanguageService.t("UI:Failure"),
            LanguageService.t("UI:DoctorIsNotRegistered")
        );
        handleRequestError(errors);
        yield put(actions.createDoctor.failure());
        return;
    }

    NotificationsService.throwNotification(
        "success",
        LanguageService.t("UI:Success"),
        LanguageService.t("UI:DoctorCreated")
    );
    yield put(actions.createDoctor.success());

    yield put(actions.getDoctorsList.start({ pageSize: PAGE_SIZE, pageNumber: 0 }));
}

export function* getDoctorsListSaga(action) {
    const { pageSize, pageNumber } = action.payload;

    let data;

    try {
        data = yield call(ApiService.doctorsListGet, pageSize, pageNumber);
    } catch (errors) {
        handleRequestError(errors);
        yield put(actions.getDoctorsList.failure());
        return;
    }

    const processedData = {};
    const processedDataSequence = [];

    data.content.forEach((item) => {
        const { email, firstName, lastName, status } = item;

        processedDataSequence.push(email);

        processedData[email] = {
            email,
            firstName,
            lastName,
            status,
        };
    });

    const meta = {
        empty: data.empty,
        first: data.first,
        last: data.last,
        number: data.number,
        numberOfElements: data.numberOfElements,
        size: data.size,
        totalElements: data.totalElements,
        totalPages: data.totalPages,
    };

    yield put(
        actions.getDoctorsList.success({
            data: processedData,
            dataSequence: processedDataSequence,
            pageable: data.pageable,
            sort: data.sort,
            meta,
        })
    );
}

export function* getDoctorSaga(action) {
    const { email } = action.payload;
    let data;

    try {
        data = yield call(ApiService.doctorGet, email);
    } catch (errors) {
        handleRequestError(errors);
        yield put(actions.getDoctor.failure());
        return;
    }

    yield put(
        actions.getDoctor.success({
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            status: data.status,
            demographics: data.demographics,
        })
    );
}

export function* doctorsSagas() {
    yield all([
        yield takeEvery(actions.createDoctor.start, createDoctorSaga),
        yield takeEvery(actions.getDoctorsList.start, getDoctorsListSaga),
        yield takeEvery(actions.getDoctor.start, getDoctorSaga),
    ]);
}
