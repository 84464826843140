import { all, put, takeEvery, call } from "redux-saga/effects";

import { handleRequestError } from "services/api/apiTools";

import { SURVEY_TYPES } from "constants/survey";

import * as actions from "./actions";
import * as ApiService from "services/api/ApiService";
import SurveyService from "services/survey/SurveyService";

import { getAuthenticatedUserDataAndRedirect } from "store/auth/sagas";
import { submitSurveyInstanceSaga } from "store/surveys/sagas";

export function* getAuxSurveyInstanceSaga(action) {
    const { id } = action.payload;

    let data;

    try {
        data = yield call(ApiService.surveyInstanceGet, encodeURIComponent(id));
    } catch (errors) {
        handleRequestError(errors);
        yield put(actions.getAuxSurveyInstance.failure());
        return;
    }

    yield put(
        actions.getAuxSurveyInstance.success({
            instanceCreateTimestamp: data.createTimestamp,
            instanceId: data.id,
            instanceLastUpdatedTimestamp: data.lastUpdatedTimestamp,
            instanceStage: data.stage,
            instanceStatus: data.status,
            instanceAnswers: data.answers,
            user: data.user,
            survey: data.survey,
        })
    );
}

export function* updateAuxSurveyInstanceSaga(action) {
    const { id: auxSurveyId, data: auxSurveyData } = action.payload;

    try {
        yield call(ApiService.surveyInstanceUpdate, auxSurveyId, auxSurveyData);
    } catch (errors) {
        handleRequestError(errors);
        yield put(actions.updateAuxSurveyInstance.failure());
        return;
    }

    yield put(actions.updateAuxSurveyInstance.success());

    const currentDemographicInstanceId = SurveyService.getCurrentDemographicInstanceId();
    const currentDemographicInstanceStage = SurveyService.getCurrentDemographicInstanceStage();
    yield call(submitSurveyInstanceSaga, {
        id: currentDemographicInstanceId,
        stage: currentDemographicInstanceStage,
        surveyType: SURVEY_TYPES.demographicsPatient,
    });

    yield call(getAuthenticatedUserDataAndRedirect);
}

export function* auxSurveysSagas() {
    yield all([
        yield takeEvery(actions.getAuxSurveyInstance.start, getAuxSurveyInstanceSaga),
        yield takeEvery(actions.updateAuxSurveyInstance.start, updateAuxSurveyInstanceSaga),
    ]);
}
