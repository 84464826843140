import { Notification } from "element-react";

import { store } from "store/store";
import * as uiActions from "store/ui/actions";

const notificationsService = {
    // TODO: rename to CamelCase
    throwNotification(type = "info", title = "", message, customOptions = {}) {
        // TODO: check case with no title
        const actionTypesMap = {
            info: "info",
            success: "success",
            warning: "warning",
            error: "error",
        };

        const actionType = actionTypesMap[type];

        if (!actionType) return;

        let messageTitle = title;
        let messageContent = message;

        if (typeof title === "object") {
            messageTitle = JSON.stringify(title);
        }
        if (typeof message === "object" && !customOptions.isReactNode) {
            messageContent = JSON.stringify(message);
        }

        const options = {
            type: actionType,
            title: String(messageTitle),
            message: customOptions.isReactNode ? messageContent : String(messageContent),
        };

        const mergedOptions = Object.assign({}, options, customOptions);

        return Notification(mergedOptions); //TODO: fix it
    },

    showNotificationModal(heading, content = []) {
        store.dispatch(uiActions.notificationModal.show({ heading, content }));
    },
};

export default notificationsService;
