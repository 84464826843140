import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Radio, Input, Button, Card } from "element-react";

import SurveyService from "services/survey/SurveyService";

import * as surveysActions from "store/surveys/actions";

import styles from "./SurveySummary.module.scss";
import customComponentsStyles from "styles/custom-components.module.scss";

SurveySummary.propTypes = {
    surveyInstanceId: PropTypes.string.isRequired,
};

function SurveySummary({ surveyInstanceId }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const surveyInstanceAnswers =
        useSelector((state) => state?.surveys?.surveyInstance?.instanceAnswers) ?? [];
    const surveyType =
        useSelector((state) => state?.surveys?.surveyInstance?.survey?.surveyType) ?? "";

    useEffect(() => {
        dispatch(surveysActions.getSurveyInstance.start({ id: surveyInstanceId }));
        setTimeout(() => {
            onSurveySubmit();
        }, 800);
    }, []);

    const onSurveySubmit = () => {
        dispatch(
            surveysActions.submitSurveyInstance.start({
                id: surveyInstanceId,
                stage: SurveyService.getCurrentMedicalInstanceStage(),
                surveyType: surveyType,
            })
        );
    };

    const onCancel = () => {
        dispatch(surveysActions.setCurrentSurveyQuestionIndex({ index: 0 }));
    };

    const renderAnswersList = () => {
        return surveyInstanceAnswers.map((element, index) => {
            return (
                <div key={index} className={styles.questionHolder}>
                    <div className={styles.questionNumber}>{index + 1}</div>
                    <div className={styles.questionContent}>
                        <div className={styles.questionQuestion}>
                            {element?.question?.question ?? ""}
                        </div>
                        <div className={styles.questionAnswer}>
                            {element?.answer?.answer ?? ""}
                        </div>
                    </div>
                </div>
            );
        });
    };

    return (
        <Card className={styles.rootWrapper}>
            <div className={styles.rootInner}>
                {/* <h2 className={styles.pageHeading}>Survey Summary</h2>
                <div className={styles.questionsList}>{renderAnswersList()}</div>
                <Button
                    className={`${customComponentsStyles.buttonCustom}`}
                    onClick={onCancel}
                >
                    {t("Surveys:SurveySummary.button.cancel")}
                </Button>
                <Button
                    className={`${customComponentsStyles.buttonCustom}`}
                    onClick={onSurveySubmit}
                >
                    {t("Surveys:SurveySummary.button.submit")}
                </Button> */}
            </div>
        </Card>
    );
}

export default SurveySummary;
