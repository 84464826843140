import React from 'react';
import PropTypes from 'prop-types';

import AccountMenu from 'сomponents/Header/AccountMenu/AccountMenu';

import styles from './Header.module.scss';

Header.propTypes = {
  headerTitle: PropTypes.string.isRequired,
};

function Header ({headerTitle}) {
  return (
    <header className={styles.header}>
      <div className={styles.headingHolder}>
        <h1>{headerTitle}</h1>
      </div>
      <div className={styles.accountMenuHolder}>
        <AccountMenu />
      </div>
    </header>
  );
}

export default Header;
