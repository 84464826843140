import { handleActions } from "redux-actions";

import { FIRST_PAGE, PAGE_SIZE } from "constants/pagination";

import * as actions from "./actions";

export const initialState = {
    isLoadingCreatePatient: false,
    isLoadingGetPatientsList: false,
    isLoadingGetPatient: false,
    data: {},
    dataSequence: [],
    selectedPatient: null,
    selectedPatientProfile: {},
    patientsListFetchParams: {
        pagination: {
            size: PAGE_SIZE,
            page: FIRST_PAGE,
        },
        filters: {},
        nameStartsWith: "",
    },
    massSignUp: {
        isLoading: false,
    },
};

export const patientsReducer = handleActions(
    {
        [actions.createPatient.start]: (state) => ({
            ...state,
            isLoadingCreatePatient: true,
        }),
        [actions.createPatient.success]: (state) => ({
            ...state,
            isLoadingCreatePatient: false,
        }),
        [actions.createPatient.failure]: (state) => ({
            ...state,
            isLoadingCreatePatient: false,
        }),

        [actions.getPatientsList.start]: (state) => ({
            ...state,
            isLoadingGetPatientsList: true,
        }),
        [actions.getPatientsList.success]: (state, { payload }) => ({
            ...state,
            isLoadingGetPatientsList: false,
            data: payload.data,
            dataSequence: payload.dataSequence,
            pageable: payload.pageable,
            sort: payload.sort,
            meta: payload.meta,
        }),
        [actions.getPatientsList.failure]: (state) => ({
            ...state,
            isLoadingGetPatientsList: false,
        }),

        [actions.getPatient.start]: (state) => ({
            ...state,
            isLoadingGetPatient: true,
        }),
        [actions.getPatient.success]: (state, { payload }) => ({
            ...state,
            isLoadingGetPatient: false,
            selectedPatientProfile: {
                ...state.selectedDoctorProfile,
                email: payload.email,
                firstName: payload.firstName,
                lastName: payload.lastName,
                status: payload.status,
                demographics: payload.demographics,
            },
        }),
        [actions.getPatient.failure]: (state) => ({
            ...state,
            isLoadingGetPatient: false,
        }),

        [actions.setSelectedPatient]: (state, { payload }) => ({
            ...state,
            selectedPatient: payload.email,
        }),

        [actions.setPatientsListFetchParams.pagination]: (state, { payload }) => ({
            ...state,
            patientsListFetchParams: {
                ...state.patientsListFetchParams,
                pagination: payload.params,
            },
        }),
        [actions.setPatientsListFetchParams.filters]: (state, { payload }) => ({
            ...state,
            patientsListFetchParams: {
                ...state.patientsListFetchParams,
                filters: payload.params,
                pagination: {
                    size: PAGE_SIZE,
                    page: FIRST_PAGE,
                },
            },
        }),
        [actions.setPatientsListFetchParams.search]: (state, { payload }) => ({
            ...state,
            patientsListFetchParams: {
                ...state.patientsListFetchParams,
                nameStartsWith: payload.params,
                pagination: {
                    size: PAGE_SIZE,
                    page: FIRST_PAGE,
                },
            },
        }),
        [actions.importPatients.start]: (state) => ({
            ...state,
            massSignUp: {
                ...state.massSignUp,
                isLoading: true,
            },
        }),
        [actions.importPatients.success]: (state) => ({
            ...state,
            massSignUp: {
                ...state.massSignUp,
                isLoading: false,
            },
        }),
        [actions.importPatients.failure]: (state) => ({
            ...state,
            massSignUp: {
                ...state.massSignUp,
                isLoading: false,
            },
        }),
        [actions.resetPatientList]: () => initialState,
    },
    initialState
);
