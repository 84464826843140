import React from 'react';
import { useTranslation } from 'react-i18next';

import ContentWithHeaderLayout from 'layouts/pageContentLayouts/ContentWithHeaderLayout/ContentWithHeaderLayout';
import DoctorResourcesList from 'сomponents/DoctorResourcesList/DoctorResourcesList';

function DoctorResourcesView() {
  const { t } = useTranslation();

  return (
    <ContentWithHeaderLayout
      padding
      bg
      headerTitle={t('Resources:DoctorResourcesView.header.heading')}
    >
      <DoctorResourcesList />
    </ContentWithHeaderLayout>
  );
}

export default DoctorResourcesView;
