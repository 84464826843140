import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { get } from "lodash";

import TreeChart from "componentsShared/TreeChart/TreeChart";
import { useTranslation } from "react-i18next";

function ScoresTreeChart() {
    const { t } = useTranslation();

    const surveyInstanceScoresData = useSelector((state) =>
        get(state.surveys.surveyInstance, "instanceScores.data", {})
    );

    const surveyInstanceScoresDataSequence = useSelector((state) =>
        get(state.surveys.surveyInstance, "instanceScores.dataSequence", [])
    );

    const [chartData, setChartData] = useState(null);

    const circleMaxRadius = 30;

    useEffect(() => {
        const chartDataProcessed = {
            name: "",
            children: [],
        };

        surveyInstanceScoresDataSequence
            .filter((type) => type !== "PHQ2")
            .forEach((key) => {
                const levelTwoItems = [];
                surveyInstanceScoresData[key].scoredAnswers.forEach((item) => {
                    levelTwoItems.push({
                        name: item.humanReadable,
                        value: item.scorePct * circleMaxRadius,
                    });
                });
                chartDataProcessed.children.push({
                    name: t("Patients:BubbleGraph." + key),
                    value: surveyInstanceScoresData[key].scorePct * circleMaxRadius,
                    children: levelTwoItems,
                });
            });
        setChartData(chartDataProcessed);
    }, [surveyInstanceScoresData, surveyInstanceScoresDataSequence]);

    return (
        <Fragment>
            {chartData ? (
                <TreeChart
                    chartData={chartData}
                    key={JSON.stringify(chartData)} // IMPORTANT!!
                />
            ) : null}
        </Fragment>
    );
}

export default ScoresTreeChart;
