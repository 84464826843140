import Loader from "componentsShared/Loader/Loader";
import React, { useEffect, useState } from "react";
import { getQrCodeImage } from "services/api/ApiService";
import { useTranslation } from "react-i18next";
import { Button } from "element-react";
import copy from "images/icons/copy.svg";
import copy2 from "images/icons/copy2.svg";
import styles from "./QrCodeImage.module.scss";

function QrCodeImage() {
    const { t } = useTranslation();
    const [isLoading, setLoading] = useState(false);
    const [image, setImage] = useState();
    const [blob, setBlob] = useState();
    const [isCopied, setCopied] = useState(false);

    const fetchQrCode = async () => {
        try {
            setLoading(true);
            const data = await getQrCodeImage();
            setBlob(data);
            setImage(URL.createObjectURL(data));
        } catch (error) {
            console.error("QR code error: ", error);
        } finally {
            setLoading(false);
        }
    };

    const copyToClipboard = () => {
        try {
            navigator.clipboard.write([
                // eslint-disable-next-line no-undef
                new ClipboardItem({
                    "image/png": blob,
                }),
            ]);
            setCopied(true);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchQrCode();

        return () => {
            setCopied(false);
            setBlob(undefined);
            setImage(undefined);
        };
    }, []);

    return (
        <div className={styles["qr-code-image"]}>
            <div>{t("Doctors:createPatient.qrCode.title")}</div>
            {isLoading && <Loader isVisible />}
            {image && (
                <div
                    onClick={() => {
                        copyToClipboard();
                    }}
                    onMouseOut={() => {
                        setCopied(false);
                    }}
                    className={styles["qr-code-content"]}
                >
                    <img src={image} alt="QR code" />

                    <img
                        src={isCopied ? copy2 : copy}
                        alt="Copy to clipboard"
                        className={styles["qr-code-copy-icon"]}
                    />
                </div>
            )}
        </div>
    );
}

export default QrCodeImage;
