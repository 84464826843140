import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import { ROUTES } from "constants/routes";

import NavigationService from "services/navigation/NavigationService";

import Logo from "сomponents/Logo/Logo";
import LanguageSelector from "сomponents/LanguageSelector/LanguageSelector";
import CookieConsentUnit from "сomponents/CookieConsentUnit/CookieConsentUnit";

import styles from "./AuthLayout.module.scss";

AuthLayout.propTypes = {
    children: PropTypes.element.isRequired,
    location: PropTypes.object.isRequired,
};

function AuthLayout({ children, location }) {
    const { t } = useTranslation();

    const locationPathName = location.pathname.substring(1);

    const isChangePasswordPage = locationPathName === ROUTES.CHANGE_PASSWORD.path;

    const isForgotPasswordPage =
        locationPathName === ROUTES.FORGOT_PASSWORD.path ||
        locationPathName === ROUTES.RESET_PASSWORD.path;

    const [forgotPasswordIsVisible, setForgotPasswordIsVisible] = useState(true);

    useEffect(() => {
        setForgotPasswordIsVisible(!NavigationService.isForgotPasswordPage());
    }, [location]);

    const renderHeader = () => {
        return (
            <div className={styles.header}>
                <div className={styles.languageSelectorHolder}>
                    <LanguageSelector />
                </div>
                <div className={styles.logoHolder}>
                    <Logo color="white" />
                </div>
                {isChangePasswordPage || isForgotPasswordPage ? null : (
                    <div className={styles.headerHolder}>
                        <h2 className="title">{t("Auth:AuthLayout.header.heading")}</h2>
                        <p className="description">
                            {t("Auth:AuthLayout.header.description")}
                        </p>
                    </div>
                )}
            </div>
        );
    };

    const renderFooter = () => {
        return (
            <div className={styles.footer}>
                {forgotPasswordIsVisible ? (
                    <div className={styles.linkRow}>
                        <Link to={"/forgot-password"}>
                            {t("Auth:AuthLayout.footer.forgotPassword")}
                        </Link>
                    </div>
                ) : null}
                <div className={styles.linkRow}>
                    <Link to={"/docs/terms"} target="_blank">
                        {t("Auth:AuthLayout.footer.terms")}
                    </Link>
                </div>
                <div className={styles.linkRow}>
                    <Link to={"/docs/privacy-policy"} target="_blank">
                        {t("Auth:AuthLayout.footer.policy")}
                    </Link>
                </div>
                <p className={styles.rights}>{t("Auth:AuthLayout.footer.rights")}</p>
                <CookieConsentUnit />
            </div>
        );
    };

    return (
        <div className={styles.mainHolder}>
            {renderHeader()}
            <div className={styles.contentHolder}>{children}</div>
            {isChangePasswordPage || isForgotPasswordPage ? null : renderFooter()}
        </div>
    );
}

export default withRouter(AuthLayout);
