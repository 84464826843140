import { store } from 'store/store';

import { SURVEY_INSTANCE_STAGES, SURVEY_INSTANCE_STATUSES } from 'constants/surveyInstance';

const SurveyService = {
  getCurrentState() {
    return store.getState();
  },

  getCurrentDemographicInstanceStage() {
    const currentState = this.getCurrentState();
    return currentState?.auxSurveys?.auxSurveyInstance?.instanceStage ?? null;
  },

  getCurrentMedicalInstanceStage() {
    const currentState = this.getCurrentState();
    return currentState?.surveys?.surveyInstance?.instanceStage ?? null;
  },

  isMedicalFirstWaveStage() {
    return this.getCurrentMedicalInstanceStage() === SURVEY_INSTANCE_STAGES.firstWave;
  },

  isMedicalSecondWaveStage() {
    return this.getCurrentMedicalInstanceStage() === SURVEY_INSTANCE_STAGES.secondWave;
  },

  getCurrentDemographicInstanceId() {
    const currentState = this.getCurrentState();
    return currentState?.auxSurveys?.auxSurveyInstance?.instanceId ?? null;
  },

  isMedicalStatusCreated() {
    const currentState = this.getCurrentState();
    return currentState?.surveys?.surveyInstance?.instanceStatus === SURVEY_INSTANCE_STATUSES.created;
  },

  isMedicalStatusInProgress() {
    const currentState = this.getCurrentState();
    return currentState?.surveys?.surveyInstance?.instanceStatus === SURVEY_INSTANCE_STATUSES.inProgress;
  },

  // isLastQuestionInTheCurrentWave() {
  //   const currentState = store.getState();
  //   const currentSurveyQuestionIndex = currentState.surveys.currentSurveyQuestionIndex;
  //   const questionsDataSequence = currentState.surveys.surveyInstance.surveyQuestions.dataSequence;
  //   const totalQuestionsInTheCurrentWave = questionsDataSequence.length;

  //   return currentSurveyQuestionIndex === (totalQuestionsInTheCurrentWave - 1);
  // },
};

export default SurveyService;
