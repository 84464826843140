import i18n from "i18next";
import LocalStorageService from "services/localStorage/LocalStorageService";

const LanguageService = {
    changeLanguage(locale) {
        LocalStorageService.setItem("locale", locale); // TODO: check cookie consent value before saving
        i18n.changeLanguage(locale);
    },
    getCurrentLanguage() {
        return i18n.language;
    },
    t(key) {
        return i18n.t(key);
    },
};

export default LanguageService;
