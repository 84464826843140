import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Input } from "element-react";

SearchPatients.propTypes = {
    localSearch: PropTypes.string.isRequired,
    setLocalSearch: PropTypes.func.isRequired,
};

function SearchPatients({ localSearch, setLocalSearch }) {
    const { t } = useTranslation();

    return (
        <Input
            icon="search"
            type="text"
            placeholder={t("Patients:SearchPatients.input.placeholder")}
            value={localSearch}
            onChange={setLocalSearch}
            autoComplete="off"
        />
    );
}

export default SearchPatients;
