import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button, Badge } from 'element-react';

import customComponentsStyles from 'styles/custom-components.module.scss';

FiltersPatientsToggle.propTypes = {
  onToggle: PropTypes.func.isRequired,
  filtersSelected: PropTypes.number,
};

function FiltersPatientsToggle ({onToggle, filtersSelected}) {
  const { t } = useTranslation();

  const renderToggleButton = () => {
    return (
      <Button
        className={customComponentsStyles.buttonCustom}
        onClick={onToggle}
      >
        {t('Patients:FiltersPatientsToggle.button.toggle')}
      </Button>
    );
  };

  return (
    <Fragment>
      {
        filtersSelected && filtersSelected !== 0
          ? <Badge value={filtersSelected}>
              {renderToggleButton()}
            </Badge>
          : <Fragment>{renderToggleButton()}</Fragment>
      }
    </Fragment>
  );
}

export default FiltersPatientsToggle;
