import createSagaMiddleware from "redux-saga";
import { applyMiddleware, compose, createStore } from "redux";
import { createMigrate, persistReducer, persistStore } from "redux-persist";

import storage from "redux-persist/lib/storage";

import notificationsService from "services/notifications/NotificationsService";

import { migrations } from "./migrations";
import { rootReducer } from "./rootReducer";
import rootSaga from "./rootSaga";
import LanguageService from "services/language/LanguageService";

const rootPersistConfig = {
    key: "root",
    storage,
    whitelist: ["auth", "patients"], // TODO: remove patients
    version: 0,
    migrate: createMigrate(migrations, { debug: false }),
};

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware({
    onError: (error) => {
        notificationsService.throwNotification("error", LanguageService.t("UI:Error"), error);
        console.log(error);
    },
});

export const store = createStore(
    persistedReducer,
    {},
    composeEnhancers(applyMiddleware(sagaMiddleware))
);

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);
