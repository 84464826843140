import { createActions } from 'redux-actions';

export const { createNote, getNotesList, getNote, updateNote, removeNote } = createActions(
  {
    CREATE_NOTE: {
      START: ({ content }) => ({ content }),
      SUCCESS: undefined,
      FAILURE: undefined,
    },
    GET_NOTES_LIST: {
      START: undefined,
      SUCCESS: ({
        data,
        dataSequence,
        pageable,
        sort,
        meta,
      }) => ({
        data,
        dataSequence,
        pageable,
        sort,
        meta,
      }),
      FAILURE: undefined,
    },
    GET_NOTE: {
      START: ({ id }) => ({ id }),
      SUCCESS: ({
        data,
      }) => ({
        data,
      }),
      FAILURE: undefined,
    },
    UPDATE_NOTE: {
      START: ({ id }) => ({
        id,
      }),
      SUCCESS: undefined,
      FAILURE: undefined,
    },
    REMOVE_NOTE: {
      START: ({ id }) => ({
        id,
      }),
      SUCCESS: undefined,
      FAILURE: undefined,
    },
  },
  { prefix: 'NOTES' }
);
