import React from "react";
import PropTypes from "prop-types";
import { getDoctorResource } from "services/api/ApiService";
import styles from "styles/commonModules/ResourcesList.module.scss";

export const ResourceLink = ({ url, name }) => {
    return (
        <span
            className={styles.resourcesListLink}
            onClick={() => {
                getDoctorResource(url + "/resolved").then((res) => {
                    if (res.resolvedUrl) {
                        window.open(res.resolvedUrl);
                    }
                });
            }}
        >
            {name}
        </span>
    );
};

ResourceLink.propTypes = {
    url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
};
