import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Radio, Input, Button, Card } from 'element-react';
import { useTranslation } from 'react-i18next';

import { SURVEY_QUESTION_TYPE } from 'constants/survey';
import { TEXT_INPUT_MAX_LENGTH } from 'constants/inputs';

import * as surveysActions from 'store/surveys/actions';

import Loader from 'componentsShared/Loader/Loader';

import customComponentsStyles from 'styles/custom-components.module.scss';
import styles from './SurveyQuestion.module.scss';

SurveyQuestion.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  surveyInstanceId: PropTypes.string, // TODO: remove
  questionData: PropTypes.object.isRequired,
  onClickPrev: PropTypes.func.isRequired,
  onClickNext: PropTypes.func.isRequired,
  prevIsDisabled: PropTypes.bool.isRequired,
  nextIsDisabled: PropTypes.bool.isRequired,
};

function SurveyQuestion ({id: questionId, surveyInstanceId, questionData, onClickPrev, onClickNext, prevIsDisabled, nextIsDisabled}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isLoadingGetSurveyInstance = useSelector(state => state.surveys.isLoadingGetSurveyInstance);
  const isLoadingUpdateSurveyInstance = useSelector(state => state.surveys.isLoadingUpdateSurveyInstance);
  
  const currentQuestionType = get(questionData, 'type', '');
  const possibleAnswers = get(questionData, 'possibleAnswers', []);

  const surveyInstance = useSelector(state => state.surveys.surveyInstance);
  const answersList = get(surveyInstance, 'instanceAnswers', []);

  const [answerOpenEnded, setAnswerOpenEnded] = useState(null);
  const [answerClosedEnded, setAnswerClosedEnded] = useState(null);

  useEffect(() => {
    const selectedAnswer = answersList.find((item) => {
      return (item.question.id === questionId);
    });

    if(currentQuestionType === SURVEY_QUESTION_TYPE.openEnded) { //TODO: refactor / to constants
      setAnswerOpenEnded(get(selectedAnswer, `answer.answer`, null));
     } else if(currentQuestionType === SURVEY_QUESTION_TYPE.closedEnded) { //TODO: refactor / to constants
      setAnswerClosedEnded(get(selectedAnswer, `answer.id`, null));
     }
  }, [answersList]);

  const renderPossibleAnswers = () => {
    return (
      <Radio.Group
        onChange={setAnswerClosedEnded}
      >
        {
          possibleAnswers.map((item) => {
            return (
              <Radio
                key={item.id}
                value={item.id}
                checked={item.id === answerClosedEnded}
                className={customComponentsStyles.inputRadioCustom}
              >
                {item.answer}
              </Radio>
            );
          })
        }
      </Radio.Group>
    );
  };

  const onNextClick = () => {
    let userResponceData = [
      {
        "question": {"id": questionId},
        "answer": {"id": answerClosedEnded}
      },
    ];

     if(currentQuestionType === 'OPEN_ENDED') { //TODO: refactor
      userResponceData = [
        {
          "question": {"id": questionId},
          "answer": {"answer": answerOpenEnded}
        },
      ];
    }

    dispatch(surveysActions.updateSurveyInstance.start({id: surveyInstanceId, data: userResponceData}));
    onClickNext();
  };

  let isNextDisabled = nextIsDisabled || (!answerOpenEnded && !answerClosedEnded);

  if (currentQuestionType === SURVEY_QUESTION_TYPE.openEnded) {
    isNextDisabled = false;
  }

  return (
    <Card className={styles.rootWrapper}>
      <h2 className={styles.heading}>{get(questionData, `question`, '')}</h2>
      <div className={styles.answersHolder}>
        { renderPossibleAnswers() }
        {currentQuestionType === SURVEY_QUESTION_TYPE.openEnded
          ? <Input
              placeholder={t('Surveys:SurveyQuestion.input.common.placeholder')}
              value={answerOpenEnded}
              onChange={setAnswerOpenEnded}
              maxLength={TEXT_INPUT_MAX_LENGTH}
            />
          : null}
      </div>
      <div className={styles.footer}>
        <Button
          className={`${customComponentsStyles.buttonCustom} ${styles.footerButton}`}
          onClick={onClickPrev}
          onClickBack
          disabled={prevIsDisabled}
        >
          {t('Surveys:SurveyQuestion.button.back')}
        </Button>
        <Button
          type="primary"
          onClick={onNextClick}
          className={`${customComponentsStyles.buttonCustom} ${styles.footerButton}`}
          disabled={isNextDisabled}
        >
          {t('Surveys:SurveyQuestion.button.next')}
        </Button>
      </div>
      <Loader isVisible={isLoadingGetSurveyInstance || isLoadingUpdateSurveyInstance}/>
    </Card>
  );
}

export default SurveyQuestion;
