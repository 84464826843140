import React, { useState, useRef, useReducer, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button } from 'element-react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { 
  checkIfHasUpperCase,
  checkIfHasLowerCase,
  checkIfHasNumber,
  validateEmail,
  checkIfHasSpecialCharacter
} from 'helpers/helpers';

import * as authActions from 'store/auth/actions';

import Loader from 'componentsShared/Loader/Loader';

import customComponentsStyles from 'styles/custom-components.module.scss';
import styles from './SignIn.module.scss';

SignIn.propTypes = {
  location: PropTypes.object.isRequired,
  header: PropTypes.node,
  footer: PropTypes.node,
  firstSignInMode: PropTypes.bool,
};

const initialFormState = {
  login: '',
  password: '',
  newPassword: '',
};

function reducer(state, action) {
  switch (action.type) {
    case 'setLogin':
      return {...state, login: action.payload};
    case 'setPassword':
      return {...state, password: action.payload};
    case 'setNewPassword':
      return {...state, newPassword: action.payload};
    default:
      throw new Error();
  }
}

function SignIn ({header, footer, firstSignInMode = false}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formRef = useRef(null);

  const [submitButtonIsEnabled, setSubmitButtonIsEnabled] = useState(false);
  const [state, dispatchToLocalState] = useReducer(reducer, initialFormState);
  const loading = useSelector(state => state.auth.isLoadingSignIn);

  const validationRules = {
    login: [
      {
        required: true,
        message: t('Auth:SignIn.form.validation.warningMessage.loginIsEmpty'),
        trigger: 'blur'
      },
      { 
        validator: (rule, value, callback) => {
          if (value === '') {
            setSubmitButtonIsEnabled(false);
            callback(new Error(t('Auth:SignIn.form.validation.errorMessage.loginIsEmpty')));
          } 
          if (!validateEmail(value)) {
            setSubmitButtonIsEnabled(false);
            callback(new Error(t('Auth:SignIn.form.validation.errorMessage.loginIsNotValid')));
          } else {
            setSubmitButtonIsEnabled(true);
            callback();
          }
        } 
      }
    ],
    password: [
      {
        required: true,
        message: t('Auth:SignIn.form.validation.warningMessage.passwordIsEmpty'),
        trigger: 'blur'
      },
      { 
        validator: (rule, value, callback) => {
          const errorMessages = [];
          if (value === '') {
            callback(new Error(t('Auth:SignIn.form.validation.errorMessage.passwordIsEmpty')));
          }
          if (value.length < 8) {
            errorMessages.push(t('Auth:SignIn.form.validation.errorMessage.passwordTooShort'));
          }
          if (!checkIfHasNumber(value)) {
            errorMessages.push(t('Auth:SignIn.form.validation.errorMessage.passwordShouldContainNumbers'));
          }
          if (!checkIfHasUpperCase(value)) {
            errorMessages.push(t('Auth:SignIn.form.validation.errorMessage.passwordShouldContainUppercase'));
          }
          if (!checkIfHasLowerCase(value)) {
            errorMessages.push(t('Auth:SignIn.form.validation.errorMessage.passwordShouldContainLowercase'));
          }
          if (!checkIfHasSpecialCharacter(value)) {
            errorMessages.push(t('Auth:SignIn.form.validation.errorMessage.newPasswordShouldContainSpecial'));
          }

          const passwordErrorMessagePrefix = t('Auth:SignIn.form.validation.password.errorMessagePrefix');

          if (errorMessages.length !== 0) {
            setSubmitButtonIsEnabled(false);
            callback(new Error(`${passwordErrorMessagePrefix} ${errorMessages.join(', ')}`));
          } else {
            setSubmitButtonIsEnabled(true);
            callback();
          }
        } 
      }
    ],
    newPassword: [
      {
        required: true,
        message: t('Auth:SignIn.form.validation.warningMessage.newPasswordIsEmpty'),
        trigger: 'blur'
      },
      {
        validator: (rule, value, callback) => {
          const errorMessages = [];
          if (value === '') {
            callback(new Error(t('Auth:SignIn.form.validation.errorMessage.newPasswordIsEmpty')));
          }
          if (value.length < 8) {
            errorMessages.push(t('Auth:SignIn.form.validation.errorMessage.newPasswordTooShort'));
          }
          if (!checkIfHasNumber(value)) {
            errorMessages.push(t('Auth:SignIn.form.validation.errorMessage.newPasswordShouldContainNumbers'));
          }
          if (!checkIfHasUpperCase(value)) {
            errorMessages.push(t('Auth:SignIn.form.validation.errorMessage.newPasswordShouldContainUppercase'));
          }
          if (!checkIfHasLowerCase(value)) {
            errorMessages.push(t('Auth:SignIn.form.validation.errorMessage.newPasswordShouldContainLowercase'));
          }
          if (!checkIfHasSpecialCharacter(value)) {
            errorMessages.push(t('Auth:SignIn.form.validation.errorMessage.newPasswordShouldContainSpecial'));
          }

          const passwordErrorMessagePrefix = t('Auth:SignIn.form.validation.newPassword.errorMessagePrefix');

          if (errorMessages.length !== 0) {
            setSubmitButtonIsEnabled(false);
            callback(new Error(`${passwordErrorMessagePrefix} ${errorMessages.join(', ')}`));
          } else {
            setSubmitButtonIsEnabled(true);
            callback();
          }
        } 
      }
    ],
  };

  const onLoginChange = value => {
    dispatchToLocalState({type: 'setLogin', payload: value});
  };

  const onPasswordChange = value => {
    dispatchToLocalState({type: 'setPassword', payload: value});
  };

  const onNewPasswordChange = value => {
    dispatchToLocalState({type: 'setNewPassword', payload: value});
  };

  const clearFormFields = () => {
    dispatchToLocalState({type: 'setLogin', payload: ''});
    dispatchToLocalState({type: 'setPassword', payload: ''});
    dispatchToLocalState({type: 'setNewPassword', payload: ''});
  };

  const onSignIn = e => {
    e.preventDefault();

    formRef.current.validate((valid) => {
      if (valid) {
        dispatch(authActions.signIn.start({
          userName: state.login,
          password: state.password,
          newPassword: state.newPassword,
        }));
        formRef.current.resetFields();
        clearFormFields(); // it seems that resetFields() can't handle state created by useReducer
      } else {
        console.log('submit error!');
        return false;
      }
    });
  };

  return (
    <Fragment>
      {header}
      <Form ref={formRef} rules={validationRules} model={state} className={styles.form}>
        <Form.Item prop="login">
          <Input
            type="text"
            placeholder={t('Auth:SignIn.form.input.login.placeholder')}
            value={state.login}
            onChange={onLoginChange}
            autoComplete="off"
          />
        </Form.Item>
        <Form.Item prop="password">
          <Input
            type="password"
            placeholder={t('Auth:SignIn.form.input.password.placeholder')}
            value={state.password}
            onChange={onPasswordChange}
            autoComplete="off"
          />
        </Form.Item>
        {
          firstSignInMode
            ? <Form.Item prop="newPassword">
              <Input
                type="password"
                placeholder={t('Auth:SignIn.form.input.newPassword.placeholder')}
                value={state.newPassword}
                onChange={onNewPasswordChange}
                autoComplete="off"
              />
            </Form.Item>
            : null
        }
        <Button
          onClick={onSignIn}
          style={{ width: '100%' }}
          disabled={!submitButtonIsEnabled}
          className={customComponentsStyles.buttonCustom}
        >
          {t('Auth:SignIn.form.button.login')}
        </Button>
      </Form>
      {footer}
      <Loader isVisible={loading}/>
    </Fragment>
  );
}

export default SignIn;
