import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import ContentWithHeaderLayout from 'layouts/pageContentLayouts/ContentWithHeaderLayout/ContentWithHeaderLayout';

import ProfileDoctor from 'сomponents/ProfileDoctor/ProfileDoctor';

ProfileDoctorView.propTypes = {
  computedMatch: PropTypes.object.isRequired,
};

function ProfileDoctorView({computedMatch}) {
  const userId = computedMatch?.params?.id || '';
  const data = useSelector(state => state?.doctors?.data) || {};

  const firstName = data?.[userId]?.firstName || '';
  const lastName = data?.[userId]?.lastName || '';

  return (
    <ContentWithHeaderLayout
      headerTitle={`${firstName} ${lastName}`}
    >
      <ProfileDoctor email={userId}/>
    </ContentWithHeaderLayout>
  );
}

export default ProfileDoctorView;
