import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./ImportantIssue.module.scss";
import { SURVEY_QUESTION_TYPE } from "constants/survey";

function ImportantIssue() {
    const { t } = useTranslation();

    const surveyInstanceAnswers =
        useSelector((state) => state.surveys?.surveyInstance?.instanceAnswers) || [];

    const answerForFirstQuestion = surveyInstanceAnswers.find(
        (item) => item.question.type === SURVEY_QUESTION_TYPE.openEnded
    )?.answer.answer;

    return (
        <p className={styles.description}>
            {answerForFirstQuestion ||
                t("Surveys:ImportantIssue.fallback.noInformationProvided")}
        </p>
    );
}

export default ImportantIssue;
