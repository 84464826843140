import { USER_ROLES } from "constants/userRoles";

export const CHALLENGES_NAMES = {
    newPasswordRequired: "NEW_PASSWORD_REQUIRED",
};

const {
    REACT_APP_AWS_REGION,
    REACT_APP_SUPERADMIN_USER_POOL_ID,
    REACT_APP_SUPERADMIN_USER_WEB_CLIENT_ID,
    REACT_APP_ADMIN_USER_POOL_ID,
    REACT_APP_ADMIN_USER_WEB_CLIENT_ID,
    REACT_APP_DOCTOR_USER_POOL_ID,
    REACT_APP_DOCTOR_USER_WEB_CLIENT_ID,
    REACT_APP_PATIENT_USER_POOL_ID,
    REACT_APP_PATIENT_USER_WEB_CLIENT_ID,
    // eslint-disable-next-line no-undef
} = process.env;

export const AMPLIFY_AUTH_PARAMS = {
    [USER_ROLES.superadmin]: {
        region: REACT_APP_AWS_REGION,
        userPoolId: REACT_APP_SUPERADMIN_USER_POOL_ID,
        userPoolWebClientId: REACT_APP_SUPERADMIN_USER_WEB_CLIENT_ID,
    },
    [USER_ROLES.admin]: {
        region: REACT_APP_AWS_REGION,
        userPoolId: REACT_APP_ADMIN_USER_POOL_ID,
        userPoolWebClientId: REACT_APP_ADMIN_USER_WEB_CLIENT_ID,
    },
    [USER_ROLES.doctor]: {
        region: REACT_APP_AWS_REGION,
        userPoolId: REACT_APP_DOCTOR_USER_POOL_ID,
        userPoolWebClientId: REACT_APP_DOCTOR_USER_WEB_CLIENT_ID,
    },
    [USER_ROLES.patient]: {
        region: REACT_APP_AWS_REGION,
        userPoolId: REACT_APP_PATIENT_USER_POOL_ID,
        userPoolWebClientId: REACT_APP_PATIENT_USER_WEB_CLIENT_ID,
    },
};
