import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import ContentWithHeaderLayout from 'layouts/pageContentLayouts/ContentWithHeaderLayout/ContentWithHeaderLayout';

import ProfileAdmin from 'сomponents/ProfileAdmin/ProfileAdmin';

ProfileAdminView.propTypes = {
  computedMatch: PropTypes.object.isRequired,
};

function ProfileAdminView({computedMatch}) {
  const userId = computedMatch?.params?.id || '';
  const data = useSelector(state => state?.admins?.adminsList?.data) || {};

  const firstName = data?.[userId]?.firstName || '';
  const lastName = data?.[userId]?.lastName || '';

  return (
    <ContentWithHeaderLayout
      headerTitle={`${firstName} ${lastName}`}
    >
      <ProfileAdmin email={userId}/>
    </ContentWithHeaderLayout>
  );
}

export default ProfileAdminView;
