import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import ContentWithHeaderLayout from 'layouts/pageContentLayouts/ContentWithHeaderLayout/ContentWithHeaderLayout';

import ProfileClinic from 'сomponents/ProfileClinic/ProfileClinic';

ProfileClinicView.propTypes = {
  computedMatch: PropTypes.object.isRequired,
};

function ProfileClinicView({computedMatch}) {
  const clinicId = computedMatch?.params?.id || '';
  const data = useSelector(state => state?.clinics?.clinicsList?.data) || {};

  const clinicName = data?.[clinicId]?.name || '';

  return (
    <ContentWithHeaderLayout
      headerTitle={clinicName}
    >
      <ProfileClinic clinicId={clinicId}/>
    </ContentWithHeaderLayout>
  );
}

export default ProfileClinicView;
