import React, { useState, useRef, useReducer, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Form, Input, Button, Dialog, Select, Radio } from "element-react";

import { validateEmail } from "helpers/helpers";

import * as clinicsActions from "store/clinics/actions";

import { USER_ROLES } from "constants/userRoles";

import styles from "./AddUserDialog.module.scss";
import customComponentsStyles from "styles/custom-components.module.scss";
import QrCodeImage from "сomponents/QrCodeImage/QrCodeImage";

AddUserDialog.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    setIsVisible: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    userTypeToAdd: PropTypes.string.isRequired,
    presetedClinicId: PropTypes.string,
};

const initialFormState = {
    email: "",
    firstName: "",
    lastName: "",
    clinicId: "",
    surveyId: "",
};

function reducer(state, action) {
    switch (action.type) {
        case "setEmail":
            return { ...state, email: action.payload };
        case "setFirstName":
            return { ...state, firstName: action.payload };
        case "setLastName":
            return { ...state, lastName: action.payload };
        case "setClinicId":
            return { ...state, clinicId: action.payload };
        case "setSurveyId":
            return { ...state, surveyId: action.payload };
        default:
            throw new Error();
    }
}

function AddUserDialog({
    isVisible,
    setIsVisible,
    onSubmit,
    userTypeToAdd,
    presetedClinicId,
}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const formRef = useRef(null);

    const accountType = useSelector((state) => state.auth.accountType);
    const surveysListData = useSelector((state) => state.surveys.surveysList.data);
    const surveysListDataSequence = useSelector(
        (state) => state.surveys.surveysList.dataSequence
    );

    const clinicsData = useSelector((state) => state?.clinics?.clinicsList?.data) ?? {};
    const clinicsDataSequence =
        useSelector((state) => state?.clinics?.clinicsList?.dataSequence) ?? [];

    const [state, dispatchToLocalState] = useReducer(reducer, initialFormState);

    const [emailIsValid, setEmailIsValid] = useState(false);
    const [firstNameIsValid, setFirstNameIsValid] = useState(false);
    const [lastNameIsValid, setLastNameIsValid] = useState(false);

    const validationRules = {
        email: [
            {
                required: true,
                message: t("Auth:AddUserDialog.dialog.validation.warningMessage.emailIsEmpty"),
                trigger: "blur",
            },
            {
                validator: (rule, value, callback) => {
                    if (value === "") {
                        setEmailIsValid(false);
                        callback(
                            new Error(
                                t(
                                    "Auth:AddUserDialog.dialog.validation.errorMessage.emailIsEmpty"
                                )
                            )
                        );
                    }
                    if (!validateEmail(value)) {
                        setEmailIsValid(false);
                        callback(
                            new Error(
                                t(
                                    "Auth:AddUserDialog.dialog.validation.errorMessage.emailIsNotValid"
                                )
                            )
                        );
                    } else {
                        setEmailIsValid(true);
                        callback();
                    }
                },
            },
        ],
        firstName: [
            {
                required: true,
                message: t(
                    "Auth:AddUserDialog.dialog.validation.warningMessage.firstNameIsEmpty"
                ),
                trigger: "blur",
            },
            {
                validator: (rule, value, callback) => {
                    if (value === "") {
                        setFirstNameIsValid(false);
                        callback(
                            new Error(
                                t(
                                    "Auth:AddUserDialog.dialog.validation.errorMessage.firstNameIsEmpty"
                                )
                            )
                        );
                    } else {
                        setFirstNameIsValid(true);
                        callback();
                    }
                },
            },
        ],
        lastName: [
            {
                required: true,
                message: t(
                    "Auth:AddUserDialog.dialog.validation.warningMessage.lastNameIsEmpty"
                ),
                trigger: "blur",
            },
            {
                validator: (rule, value, callback) => {
                    if (value === "") {
                        setLastNameIsValid(false);
                        callback(
                            new Error(
                                t(
                                    "Auth:AddUserDialog.dialog.validation.errorMessage.lastNameIsEmpty"
                                )
                            )
                        );
                    } else {
                        setLastNameIsValid(true);
                        callback();
                    }
                },
            },
        ],
    };

    useEffect(() => {
        if (accountType === USER_ROLES.superadmin) {
            dispatch(clinicsActions.getClinicsList.start({ pageSize: 500, pageNumber: 0 })); // TODO: change limit 500 to unlimited request
        }
    }, []);

    useEffect(() => {
        if (presetedClinicId) {
            dispatchToLocalState({ type: "setClinicId", payload: presetedClinicId });
        }
    }, [presetedClinicId]);

    useEffect(() => {
        if (surveysListDataSequence.length > 0) {
            dispatchToLocalState({ type: "setSurveyId", payload: surveysListDataSequence[0] });
        }
    }, [surveysListDataSequence]);

    const renderClinicsDropdown = () => {
        return (
            <Form.Item prop="clinicCode">
                <Select
                    className={styles.clinicsSelect}
                    onChange={onClinicChange}
                    value={state.clinicId}
                    disabled={presetedClinicId}
                    placeholder={t("Auth:AddUserDialog.dialog.form.input.clinic.placeholder")}
                >
                    {clinicsDataSequence.map((key) => {
                        return (
                            <Select.Option
                                key={key}
                                label={clinicsData[key].name}
                                value={clinicsData[key].id}
                            />
                        );
                    })}
                </Select>
            </Form.Item>
        );
    };

    const renderDiabetesTypeRadioButtons = () => {
        return (
            <Radio.Group
                value={state.surveyId}
                onChange={onDiabetesTypeChange}
                className={styles.diabetesTypeRadioGroup}
            >
                {surveysListDataSequence.map((key) => {
                    if (!surveysListData[key]?.isAvailable) {
                        return null;
                    }
                    return (
                        <Radio
                            key={key}
                            checked={state.surveyId === surveysListData[key]?.id}
                            value={surveysListData[key]?.id}
                        >
                            <span title={`rev. ${surveysListData[key]?.revision}`}>
                                {surveysListData[key]?.name}
                            </span>
                        </Radio>
                    );
                })}
            </Radio.Group>
        );
    };

    const onEmailChange = (value) => {
        dispatchToLocalState({ type: "setEmail", payload: value });
    };

    const onFirstNameChange = (value) => {
        dispatchToLocalState({ type: "setFirstName", payload: value });
        dispatchToLocalState({ type: "setLastName", payload: value });
    };

    const onLastNameChange = (value) => {
        dispatchToLocalState({ type: "setLastName", payload: value });
    };

    const onClinicChange = (value) => {
        dispatchToLocalState({ type: "setClinicId", payload: value });
    };

    const onDiabetesTypeChange = (value) => {
        dispatchToLocalState({ type: "setSurveyId", payload: value });
    };

    const clearFormFields = () => {
        dispatchToLocalState({ type: "setEmail", payload: "" });
        dispatchToLocalState({ type: "setFirstName", payload: "" });
        dispatchToLocalState({ type: "setLastName", payload: "" });
        dispatchToLocalState({ type: "setClinicId", payload: "" });
        dispatchToLocalState({
            type: "setSurveyId",
            payload: initialFormState.surveyId,
        });
    };

    const onSave = () => {
        formRef.current.validate((valid) => {
            if (valid) {
                const submitData = {
                    clinicId: null,
                    email: state.email,
                    firstName: state.firstName.split(" ")[0],
                    lastName: state.lastName.split(" ")[1] || state.firstName.split(" ")[0],
                    surveyId: state.surveyId,
                };

                if (accountType === USER_ROLES.superadmin) {
                    submitData.clinicId = state.clinicId;
                }

                onSubmit(submitData);

                formRef.current.resetFields();
                clearFormFields(); // it seems that resetFields() can't handle state created by useReducer
            } else {
                console.log("submit error!");
                return false;
            }
        });

        setIsVisible(false);
    };

    const onCancel = () => {
        formRef.current.resetFields();
        clearFormFields(); // it seems that resetFields() can't handle state created by useReducer

        setIsVisible(false);
    };

    let headingCaption;
    let subheadingCaption;

    switch (userTypeToAdd) {
        case USER_ROLES.admin:
            headingCaption = t("Auth:AddUserDialog.dialog.heading.admin");
            subheadingCaption = t("Auth:AddUserDialog.dialog.subheading.admin");
            break;
        case USER_ROLES.doctor:
            headingCaption = t("Auth:AddUserDialog.dialog.heading.doctor");
            subheadingCaption = t("Auth:AddUserDialog.dialog.subheading.doctor");
            break;
        case USER_ROLES.patient:
            headingCaption = t("Auth:AddUserDialog.dialog.heading.patient");
            subheadingCaption = "";
            break;
        default:
            console.log("Wrong user type");
    }

    return (
        <Dialog
            title={<span className={styles.dialogHeading}>{headingCaption}</span>}
            // size="tiny"
            visible={isVisible}
            onCancel={() => {
                setIsVisible(false);
            }}
            lockScroll={false}
            closeOnClickModal={false}
        >
            <Dialog.Body>
                <div className={styles.subheading}>{subheadingCaption}</div>

                <Form
                    ref={formRef}
                    rules={validationRules}
                    model={state}
                    className={customComponentsStyles.form}
                >
                    <Form.Item prop="firstName">
                        <Input
                            className={customComponentsStyles.inputText}
                            type="text"
                            placeholder={t(
                                "Auth:AddUserDialog.dialog.form.input.fullName.placeholder"
                            )}
                            value={state.firstName}
                            onChange={onFirstNameChange}
                            autoComplete="off"
                        />
                    </Form.Item>
                    <Form.Item prop="lastName">
                        <Input
                            className={customComponentsStyles.inputText}
                            type="text"
                            placeholder={t(
                                "Auth:AddUserDialog.dialog.form.input.lastName.placeholder"
                            )}
                            value={state.lastName}
                            onChange={onLastNameChange}
                            autoComplete="off"
                            style={{ display: "none" }}
                        />
                    </Form.Item>
                    <Form.Item prop="email">
                        <Input
                            className={customComponentsStyles.inputText}
                            type="text"
                            placeholder={t(
                                "Auth:AddUserDialog.dialog.form.input.email.placeholder"
                            )}
                            value={state.email}
                            onChange={onEmailChange}
                            autoComplete="off"
                        />
                    </Form.Item>
                    {accountType === USER_ROLES.superadmin ? renderClinicsDropdown() : null}
                    {userTypeToAdd === USER_ROLES.patient ? (
                        <div className={styles.addUserFormWrapper}>
                            <div>{renderDiabetesTypeRadioButtons()}</div>
                            <QrCodeImage />
                        </div>
                    ) : null}
                </Form>
            </Dialog.Body>
            <Dialog.Footer className="dialog-footer">
                <Button
                    onClick={onSave}
                    disabled={!(emailIsValid && firstNameIsValid)}
                    className={customComponentsStyles.buttonCustom}
                >
                    {t("Auth:AddUserDialog.dialog.button.save")}
                </Button>
                <Button onClick={onCancel} className={customComponentsStyles.buttonCustom}>
                    {t("Auth:AddUserDialog.dialog.button.cancel")}
                </Button>
            </Dialog.Footer>
        </Dialog>
    );
}

export default AddUserDialog;
