import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import * as authActions from "store/auth/actions";

import { USER_ROLES } from "constants/userRoles";

import RoleSelectCard from "./RoleSelectCard/RoleSelectCard";

import styles from "./RoleSelect.module.scss";

function RoleSelect() {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(authActions.signOut.start()); // handles the case when user selected the role, but was not authorized and returned to the role-select page
    }, [dispatch]);

    return (
        <div className={styles.rolesCardsWrapper}>
            {/* <div className={styles.cardHolder}>
        <RoleSelectCard
          title={t('Auth:RoleSelect.card.patient.title')}
          userRole={USER_ROLES.patient}
        />
      </div> */}
            <div className={styles.cardHolder}>
                <RoleSelectCard
                    title={t("Auth:RoleSelect.card.doctor.title")}
                    userRole={USER_ROLES.doctor}
                />
            </div>
        </div>
    );
}

export default RoleSelect;
