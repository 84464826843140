import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { handleRequestError } from 'services/api/apiTools';

DownloadUnit.propTypes = {
  renderForm: PropTypes.func.isRequired,
  request: PropTypes.func.isRequired,
  params: PropTypes.string,
  filename: PropTypes.string.isRequired,
};

function DownloadUnit({ renderForm, request, params, filename }) {
  const onClickDownload = async () => {
    let response;
    try {
      response = await request(params);
      const objectUrl = window.URL.createObjectURL(response);
      const anchor = document.createElement('a');

      anchor.href = objectUrl;
      anchor.download = `${filename}.csv`;
      anchor.click();
      window.URL.revokeObjectURL(objectUrl);
    } catch (err) {
      return handleRequestError(err);
    }
  };

  return <Fragment>{renderForm(() => onClickDownload())}</Fragment>;
}

export default DownloadUnit;
