import React, {useEffect, useState, Fragment } from 'react';
import { USER_ROLES } from 'constants/userRoles';
import { ROUTES } from 'constants/routes';
import { useDispatch } from 'react-redux';
import * as authActions from 'store/auth/actions';
import { useTranslation } from 'react-i18next';
// import { Link } from 'react-router-dom';

import SignIn from 'сomponents/SignIn/SignIn';
import PropTypes from 'prop-types';
import styles from './SignInView.module.scss';

SignInView.propTypes = {
  location: PropTypes.object.isRequired,
};

function SignInView({location}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [userRole, setUserRole] = useState('');

  const locationPathName = location.pathname.substring(1);

  const locationUserRolesMap = {
    [ROUTES.SIGN_IN_SUPERADMIN.path]: USER_ROLES.superadmin,
    [ROUTES.SIGN_IN_ADMIN.path]: USER_ROLES.admin,
    // [ROUTES.SIGN_IN_DOCTOR.path]: USER_ROLES.doctor,
    // [ROUTES.SIGN_IN_PATIENT.path]: USER_ROLES.patient,
    [ROUTES.REGISTRATION_ADMIN.path]: USER_ROLES.admin,
    [ROUTES.REGISTRATION_DOCTOR.path]: USER_ROLES.doctor,
    [ROUTES.REGISTRATION_PATIENT.path]: USER_ROLES.patient,
  };

  const locationUserRole = locationUserRolesMap[locationPathName];

  const isRegistrationPage =
    (locationPathName === ROUTES.REGISTRATION_ADMIN.path)
    || (locationPathName === ROUTES.REGISTRATION_DOCTOR.path)
    || (locationPathName === ROUTES.REGISTRATION_PATIENT.path);

  useEffect(() => {
    setUserRole(locationUserRole);
    dispatch(authActions.selectUserRole({userRole: locationUserRole}));
  }, [location]);

  const signInFormHeaderPatient = <Fragment>
    <h1 className={styles.formHeader}>
      {t('Auth:SignInView.patient.header.heading')}
    </h1>
    <p className={styles.formDescription}>
      {t('Auth:SignInView.patient.header.description')}
    </p>
  </Fragment>;
  const signInFormFooterPatient = <div className={styles.formFooter}>
    {/* <Link to={'/restore'}>
      "I can not access"
    </Link> */}
    {/* <div className="auth-link__line">
      <p>"OR"</p>
    </div> */}
    {/* <Link className="auth-link__registration" to={'/registration'}>
      <Button>
      "Create a Spotlight Account"
      </Button>
    </Link> */}
  </div>;

  const signInFormHeaderDoctor = <Fragment>
    <h1 className={styles.formHeader}>
      {t('Auth:SignInView.doctor.header.heading')}
    </h1>
    <p className={styles.formDescription}>
      {t('Auth:SignInView.doctor.header.description')}
    </p>
  </Fragment>;
  const signInFormFooterDoctor = <div className={styles.formFooter}>
    {/* <Link to={'/restore'}>
      "I can not access"
    </Link> */}
  </div>;

  const signInFormHeaderAdmin = <Fragment>
    <h1 className={styles.formHeader}>
      {t('Auth:SignInView.admin.header.heading')}
    </h1>
    <p className={styles.formDescription}>
      {t('Auth:SignInView.admin.header.description')}
    </p>
  </Fragment>;
  const signInFormFooterAdmin = <div className={styles.formFooter}>
    {/* <Link to={'/restore'}>
      "I can not access"
    </Link> */}
  </div>;

  let formHeader = null;
  let formFooter = null;

  if (userRole === USER_ROLES.patient) {
    formHeader = signInFormHeaderPatient;
    formFooter = signInFormFooterPatient;
  }

  if (userRole === USER_ROLES.doctor) {
    formHeader = signInFormHeaderDoctor;
    formFooter = signInFormFooterDoctor;
  }

  if (userRole === USER_ROLES.admin) {
    formHeader = signInFormHeaderAdmin;
    formFooter = signInFormFooterAdmin;
  }

  return (
    <div className={styles.card}>
      <SignIn
        header={formHeader}
        footer={formFooter}
        firstSignInMode={
          // locationUserRole === USER_ROLES.admin
          // || locationUserRole === USER_ROLES.superadmin
          //   ? false
          //   : true

          isRegistrationPage ? true : false
        }
      />
    </div>
  );
}

export default SignInView;
