import { createActions } from "redux-actions";

export const {
    createPatient,
    getPatientsList,
    getPatient,
    setSelectedPatient,
    importPatients,
    acceptPrivacyPolicy,
    setPatientsListFetchParams,
    resetPatientList,
} = createActions(
    {
        CREATE_PATIENT: {
            START: ({ clinicId, email, firstName, lastName, surveyId }) => ({
                clinicId,
                email,
                firstName,
                lastName,
                surveyId,
            }),
            SUCCESS: undefined,
            FAILURE: undefined,
        },
        GET_PATIENTS_LIST: {
            START: undefined,
            SUCCESS: (data) => data,
            FAILURE: undefined,
        },
        GET_PATIENT: {
            START: ({ email }) => ({ email }),
            SUCCESS: ({ email, firstName, lastName, status, demographics }) => ({
                email,
                firstName,
                lastName,
                status,
                demographics,
            }),
            FAILURE: undefined,
        },
        IMPORT_PATIENTS: {
            START: ({ fileData }) => ({ fileData }),
            SUCCESS: undefined,
            FAILURE: undefined,
        },
        SET_SELECTED_PATIENT: ({ email }) => ({ email }), // TODO: implement pick by email on backend
        ACCEPT_PRIVACY_POLICY: {
            START: undefined,
            SUCCESS: undefined,
            FAILURE: undefined,
        },
        SET_PATIENTS_LIST_FETCH_PARAMS: {
            PAGINATION: ({ params }) => ({ params }),
            FILTERS: ({ params }) => ({ params }),
            SEARCH: ({ params }) => ({ params }),
        },
        RESET_PATIENT_LIST: () => {},
    },
    { prefix: "PATIENTS" }
);
