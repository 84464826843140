import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'element-react';

import { ROUTES } from 'constants/routes';

import LocalStorageService from 'services/localStorage/LocalStorageService';

import customComponentsStyles from 'styles/custom-components.module.scss';
import styles from './ErrorBoundary.module.scss';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: '' };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, errorMessage: error.toString() };
  }

  componentDidCatch(error, info) {
    // Customized error handling goes here!
  }

  onClearApplicationData() {
    LocalStorageService.clear();
  }

  onGoToRoleSelect() {
    window.open(`/${ROUTES.ROLE_SELECT.path}`, '_self');
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={styles.outerContainer}>
          <div className={styles.container}>
            <h1 className={styles.heading}>Error!</h1>
            <div className={styles.errorMessage}>{this.state.errorMessage}</div>
            <div>
              <div className={styles.instructionsDescription}><b>An error has occurred. Please, contact administrator</b></div>
              You can try to{' '}
              <Button onClick={this.onClearApplicationData} className={customComponentsStyles.buttonCustom}>clear application data</Button>{' '}
              and try to
              <Button onClick={this.onGoToRoleSelect} className={customComponentsStyles.buttonCustom}>sign in again</Button>
              .
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
