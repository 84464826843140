import React from 'react';
import { useTranslation } from 'react-i18next';

import ContentWithHeaderLayout from 'layouts/pageContentLayouts/ContentWithHeaderLayout/ContentWithHeaderLayout';
import PatientNotesList from 'сomponents/PatientNotesList/PatientNotesList';

function PatientNotesView() {
  const { t } = useTranslation();

  return (
    <ContentWithHeaderLayout
      padding
      headerTitle={t('Notes:PatientNotesView.header.heading')}
    >
      <PatientNotesList />
    </ContentWithHeaderLayout>
  );
}

export default PatientNotesView;
