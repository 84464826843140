import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import { Button } from 'element-react';
import { useTranslation } from 'react-i18next';

import * as auxSurveysActions from 'store/auxSurveys/actions';

import NavigationService from 'services/navigation/NavigationService';

import { SURVEY_QUESTION_TYPE } from 'constants/survey';

import AuxSurveyQuestion from 'сomponents/Surveys/AuxSurvey/AuxSurveyQuestion/AuxSurveyQuestion';

import styles from './AuxSurvey.module.scss';
import customComponentsStyles from 'styles/custom-components.module.scss';

function AuxSurvey () {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const auxSurveyQuestions = useSelector((state) => get(state.auxSurveys.auxSurveyInstance, 'survey.questions', []));
  const auxSurveyId = useSelector((state) => get(state.auxSurveys.auxSurveyInstance, 'instanceId', null));
  const userAuxSurveys = useSelector((state) => get(state.auth.userAccountData, 'demographics', []));

  const [userResponses, setUserResponses] = useState({});
  const [stepTwoIsVisible, setStepTwoIsVisible] = useState(false);
  const [isAllQuestionsAnswered, setIsAllQuestionsAnswered] = useState(false);

  useEffect(() => {
    const notCompletedAuxSurveys = userAuxSurveys.filter((item) => {
      return item.status !== 'COMPLETED';
    });

    if (notCompletedAuxSurveys.length !== 0 ) {
      dispatch(auxSurveysActions.getAuxSurveyInstance.start({id: notCompletedAuxSurveys[0].id}));
    }
  }, [userAuxSurveys]);

  useEffect(() => {
    const userResponsesKeys = Object.keys(userResponses);
    if(userResponsesKeys.length === 0 || userResponsesKeys.length !== auxSurveyQuestions.length) {
      setIsAllQuestionsAnswered(false);
    } else {
      setIsAllQuestionsAnswered(true);
    }
  }, [userResponses]);

  const onResponse = (questionId, currentQuestionType, sourceElement, checkedValue) => {
    const newResponses = {
      ...userResponses,
      [questionId]: {
        question: {id: questionId},
        answer: {[
          (currentQuestionType === SURVEY_QUESTION_TYPE.openEnded && sourceElement === 'radio') || currentQuestionType === SURVEY_QUESTION_TYPE.closedEnded
            ? "id"
            : "answer"
          ]: checkedValue},
      }
    };

    if (currentQuestionType === SURVEY_QUESTION_TYPE.closedEnded && !checkedValue) {  // currentQuestionType === SURVEY_QUESTION_TYPE.openEnded && !checkedValue
       delete newResponses[questionId];           
    }

    setUserResponses(newResponses);
  };

  const renderAuxSurveyQuestions = () => {
    return (
      auxSurveyQuestions.map((item) => {
        return (
          <AuxSurveyQuestion
            key={item.id}
            type={item.type}
            question={item.question}
            id={item.id}
            possibleAnswers={item.possibleAnswers}
            onResponse={onResponse}
            isDisabled={stepTwoIsVisible}
          />
        );
      })
    );
  };

  const renderSubmitStepOne = () => {
    return (
      <div>
        <Button
          onClick={onSubmitStepOne}
          className={customComponentsStyles.buttonCustom}
          disabled={!isAllQuestionsAnswered}
        >
          {t('Surveys:AuxSurvey.button.stepOne.submit')}
        </Button>
      </div>
    );
  };

  const renderSubmitStepTwo = () => { // TODO: add translations
    return (
      <div>
        <div className={styles.submitStepTwoDescription}>
          {t('Surveys:AuxSurvey.label.answersWillBeSent')}
        </div>
        <Button
          onClick={onChangeAnswers}
          className={customComponentsStyles.buttonCustom}
        >
          {t('Surveys:AuxSurvey.button.stepTwo.cancel')}
        </Button>
        
        <Button
          onClick={onSubmitStepTwo}
          className={customComponentsStyles.buttonCustom}
          disabled={!isAllQuestionsAnswered}
        >
          {t('Surveys:AuxSurvey.button.stepTwo.submit')}
        </Button>
      </div>
    );
  };

  const renderAuxSurvey = () => { // TODO: add translations
    return (
      <div className={styles.surveyWrapper}>
        {renderAuxSurveyQuestions()}
        <div className={styles.controlsHolder}>
          {
            stepTwoIsVisible ? renderSubmitStepTwo() : renderSubmitStepOne()
          }
        </div>
      </div>
    );
  };

  const onSubmitStepTwo = () => {
    const data = Object.values(userResponses);
    
    dispatch(auxSurveysActions.updateAuxSurveyInstance.start({id: auxSurveyId, data}));
    setUserResponses({});
    NavigationService.navigateToDefaultPath();
  };

  const onSubmitStepOne = () => {
    setStepTwoIsVisible(true);
  };

  const onChangeAnswers = () => {
    setStepTwoIsVisible(false);
  };

  const renderAuxSurveyPlaceholder = () => {
    return (
      <div>{t('Surveys:AuxSurvey.placeholder.loading')}</div>
    );
  };

  return (
    <div>
      {
        auxSurveyQuestions.length !== 0
          ? renderAuxSurvey()
          : renderAuxSurveyPlaceholder()
      }
    </div>
  );
}

export default AuxSurvey;
